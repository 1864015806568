import React, { Component } from "react";

import { Table, Form, Button } from "semantic-ui-react";
import UserRow from "./UserRow";

import {db} from "../../config/fireInit";
import { ManageUserDocs } from "./ManageUserDocs";

class AllUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
      providerData: [],
      column: "createdAt",
      direction: "descending",
      lastItem: {},
      disableNextBtn: false,
      disablePrevBtn: true,
      firstItem: {},
      activePage: 1,
      itemsPerPage: 250,//5
      displayNameSearch: "",
      emailSearch: ""
    };

    this.unsubscribe = [];
  }

  componentDidMount() {
    this.unsubscribe = db
      .collection("users")
      .orderBy("createdAt","desc")
      .limit(250)
      .onSnapshot(querySnapshot => {
        const userData = querySnapshot.docs.map(doc => doc.data());
        let lastItem = querySnapshot.docs[querySnapshot.docs.length - 1];
        this.setState({ allUsers: userData, lastItem, disableNextBtn: userData.length<5 ? true : false });
      });

      db.collection("providers")
      .orderBy("providerName")
      .onSnapshot((querySnapshot) => {
        const providerData = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ providers: providerData });
        //console.log("providerData", providerData);
      });

    /* LATER - providers managing their own users; there is going to be alot of reduancies.
    else if (user.roles.Provider) {//query only own if Provider
      this.unsubscribe = db
        .collection("user")
        .where("agency", "==", user.agency)
        .limit(6)
        .onSnapshot(querySnapshot => {
          const userData = querySnapshot.docs.map(doc => doc.data());
          this.setState({ allUsers: userData });
        });
    }*/
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleSort = clickedColumn => () => {
    this.unsubscribe();
    const { column, direction } = this.state;
    this.setState({
      disablePrevBtn: true,
      disableNextBtn: false,
      activePage: 1
    });

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: "ascending"
      });
      this.unsubscribe = db
        .collection("users")
        .orderBy(clickedColumn)
        .limit(250)
        .onSnapshot(querySnapshot => {
          const userData = querySnapshot.docs.map(doc => doc.data());
          let lastItem = querySnapshot.docs[querySnapshot.docs.length - 1];
          this.setState({ allUsers: userData, lastItem, disabledNextBtn: userData.length<5 ? true : false });
        });

      return;
    }
    this.unsubscribe = db
      .collection("users")
      .orderBy(clickedColumn, direction === "ascending" ? "desc" : "asc") //opposite because setState is later
      .limit(250)
      .onSnapshot(querySnapshot => {
        const userData = querySnapshot.docs.map(doc => doc.data());
        const lastItem = querySnapshot.docs[querySnapshot.docs.length - 1];
        this.setState({
          allUsers: userData,
          lastItem,
          disabledNextBtn: userData.length<5 ? true : false
        });
      });
    this.setState({
      direction: direction === "ascending" ? "descending" : "ascending"
    });
  }

  handleDisplayNameSearch = e => {
    //const { user } = this.state;
    this.unsubscribe();
    this.setState({
      activePage: 1,
      disablePrevBtn: true,
      displayNameSearch: e.target.value
    });
    this.unsubscribe = db
      .collection("users")
      .where("displayName", "==", e.target.value)
      .orderBy("uid")
      .limit(250)
      .onSnapshot(querySnapshot => {
        const userData = querySnapshot.docs.map(doc => doc.data());
        this.setState({ allUsers: userData, disabledNextBtn: userData.length<5 ? true : false });
      });
  };

  handleEmailSearch = e => {
    //const { user } = this.state;
    this.unsubscribe();
    this.setState({
      activePage: 1,
      disablePrevBtn: true,
      emailSearch: e.target.value
    });
    this.unsubscribe = db
      .collection("users")
      .where("email", "==", e.target.value)
      .orderBy("uid")
      .limit(250)
      .onSnapshot(querySnapshot => {
        const userData = querySnapshot.docs.map(doc => doc.data());
        this.setState({ allUsers: userData, disabledNextBtn: userData.length<5 ? true : false });
      });
  };

  handleReset = () => {
    //const { user } = this.state;
    this.unsubscribe();
    this.setState({
      emailSearch: "",
      displayNameSearch: "",
      disableNextBtn: false,
      disablePrevBtn: true,
      column: null,
      direction: null
    });
    this.unsubscribe = db
      .collection("users")
      .orderBy("uid")
      .limit(250)
      .onSnapshot(querySnapshot => {
        const userData = querySnapshot.docs.map(doc => doc.data());
        let lastItem = querySnapshot.docs[querySnapshot.docs.length - 1];
        this.setState({ allUsers: userData, lastItem, disableNextBtn: userData.length<5 ? true : false });
      });
  };

  handleNextPage = () => {
    this.unsubscribe();
    const { lastItem, activePage, column, direction } = this.state;
    const currentPage = activePage + 1;
    this.setState({
      activePage: currentPage,
      disablePrevBtn: false,
      disableNextBtn: true
    });
    this.unsubscribe = db
      .collection("users")
      .orderBy(column, direction === "ascending" ? "asc" : "desc")
      .startAfter(lastItem)
      .limit(250)
      .onSnapshot(querySnapshot => {
        const userData = querySnapshot.docs.map(doc => doc.data());
        let lastItem = querySnapshot.docs[querySnapshot.docs.length - 1];
        let firstItem = querySnapshot.docs[0];
        this.setState({ allUsers: userData, lastItem, firstItem, disabledNextBtn: userData.length<5 ? true : false });
      });
  };

  handlePrevPage = () => {
    this.unsubscribe();
    const { firstItem, activePage, column, direction } = this.state;
    const currentPage = activePage - 1;
    this.setState({
      activePage: currentPage,
      disableNextBtn: false,
      disablePrevBtn: activePage === 1 ? false : true
    });
    this.unsubscribe = db
      .collection("users")
      .orderBy(column, direction === "ascending" ? "asc" : "desc")
      .endBefore(firstItem)
      .limitToLast(250)
      .onSnapshot(querySnapshot => {
        const userData = querySnapshot.docs.map(doc => doc.data());
        let lastItem = querySnapshot.docs[querySnapshot.docs.length - 1];
        let firstItem = querySnapshot.docs[0];
        this.setState({ allUsers: userData, lastItem, firstItem, disabledNextBtn: userData.length<5 ? true : false });
      });
  };

  handlePaginationChange = (e, { activePage }) => {
    this.unsubscribe();
    //const { user } = this.state;
    this.setState({ activePage });
    //re-query for next page.
    //first, end the initial listener
    //this.unsubscribe();
    //console.log("Active Page: ", activePage)
    //console.log("Items per Page: ", itemsPerPage)
    //console.log("Start at ", activePage * itemsPerPage-itemsPerPage)
    this.unsubscribe = db
      .collection("users")
      .orderBy("uid")
      //.startAt(5) //activePage * itemsPerPage - itemsPerPage
      .limit(250)
      .onSnapshot(querySnapshot => {
        const userData = querySnapshot.docs.map(doc => doc.data());
        console.log("userData", userData);
        this.setState({ allUsers: userData });
      });
  };

  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    const {
      //activePage,
      allUsers,
      providers,
      //disableNextBtn,
      //disablePrevBtn,
      displayNameSearch,
      emailSearch,
      direction,
      column
    } = this.state;
    const { user } = this.props;
    return (
      <div>
        <h1 className='center'>All Registered Users{/*for {user.agency}*/}</h1>
        <ManageUserDocs/>
        <Form onSubmit={this.handleSubmit}>
          <Form.Input
            label='Search by Display Name:'
            value={displayNameSearch}
            name='displayNameSearch'
            type='text'
            autoComplete='off'
            onChange={e => this.handleDisplayNameSearch(e)}
          />
          <Form.Field>
            <label>Search by Email:</label>
            <input
              value={emailSearch}
              name='emailSearch'
              type='text'
              autoComplete='off'
              onChange={e => this.handleEmailSearch(e)}
            />
          </Form.Field>
          <Button basic onClick={this.handleReset}>
            Reset
          </Button>
          <p>
            <b>Note: </b>search inputs must be exact to show results.
          </p>
          <Table sortable celled>
            <Table.Header>
              <Table.Row verticalAlign='top' textAlign='center'>
                <Table.HeaderCell
                  sorted={column === "displayName" ? direction : null}
                  onClick={this.handleSort("displayName")}
                >
                  Display Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "email" ? direction : null}
                  onClick={this.handleSort("email")}
                >
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "createdAt" ? direction : null}
                  onClick={this.handleSort("createdAt")}
                >
                  Registration Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "uid" ? direction : null}
                  onClick={this.handleSort("uid")}
                >
                  Unique ID
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "providerName" ? direction : null}
                  onClick={this.handleSort("providerName")}
                >
                  Provider Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={column === "roles" ? direction : null}
                  onClick={this.handleSort("roles")}
                >
                  Approved Roles
                  <br />
                  (in blue)
                </Table.HeaderCell>
                <Table.HeaderCell>Profile</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {allUsers.map(eachUser => (
                <Table.Row
                  warning={eachUser.uid === user.uid}
                  key={eachUser.uid}
                >
                  <UserRow displayUser={eachUser} user={user} providers={providers}/>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {/**
          <Button icon onClick={this.handlePrevPage} disabled={disablePrevBtn}>
            <Icon name='left chevron' />
            Previous
          </Button>
          Page Number {activePage}
          <Button icon onClick={this.handleNextPage} disabled={disableNextBtn}>
            Next
            <Icon name='right chevron' />
          </Button>
          */}
          {/*<Pagination
            activePage={activePage}
            onPageChange={this.handlePaginationChange}
            totalPages={5}
          />*/}

        </Form>
      </div>
    );
  }
}

export default AllUsers;
