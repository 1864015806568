import React, { Fragment } from 'react'
import { Step, Icon, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const WelcomeUser = () => {
  //<List.Icon name="check" color="blue" size="big" verticalAlign="middle"/>
  return (
    <Fragment>
      <h1>Welcome</h1>
      <h3>Getting Started</h3>
      <p>
        Thank you for using this web app to enter your monthly data. We hope
        that it makes data submissions easier for your agency.
      </p>
      <p>
        Most tools are found under the menu with your provider name at the top
        of every page.
      </p>
      <p>
        Any youth you have previously submitted using an excel form have been
        added here for you by EBA and will appear in the &quot;Monthly Update
        Tool.&quot; Adding that youth again will result in a duplicate entry.
      </p>
      <p>
        If you run into any challenges with this app, you can contact our
        developer by email at{' '}
        <a href='mailto:joelnwalkley@gmail.com'>joelnwalkley@gmail.com</a>
      </p>
      <h3>Changes</h3>
      <Message icon info>
        <Icon name='info circle' />
        <Message.Content>
          <Link to='/changes'>October 29, 2023: Prevent duplicate entries, staff sorted by active status...</Link>.
        </Message.Content>
      </Message>
      <h3>Instructions</h3>
      <Step.Group vertical ordered stackable='tablet' fluid>
        <Step>
          <Icon name='address book' color='blue' />
          <Step.Content>
            <Step.Title>Check Your Current Staff List</Step.Title>
            <Step.Description>
              Use <Link to='/staff'>&quot;Manage Staff&quot;</Link> to view,
              add, edit, and remove staff so they appear correctly on your
              dropdown lists throughout this app.
            </Step.Description>
          </Step.Content>
        </Step>
        <Step>
          <Icon name='address card outline' color='blue' />
          <Step.Content>
            <Step.Title>Enter All New Youth</Step.Title>
            <Step.Description>
              Use <Link to='/youth'>&quot;Add New Youth&quot;</Link> to add
              youth who have enrolled in your program since your last update. If
              you are unsure whether a youth is already enrolled, you can{' '}
              <Link to='/allYouth'>&quot;List All Youth.&quot;</Link>
            </Step.Description>
          </Step.Content>
        </Step>
        <Step>
          <Icon name='tasks' color='blue' />
          <Step.Content>
            <Step.Title>Log Monthly Updates</Step.Title>
            <Step.Description>
              Use The <Link to='/update'>&quot;Monthly Review Tool&quot;</Link>{' '}
              to report changes, model specific measures, and sessions that
              occured in the prior month for all of your active youth.
            </Step.Description>
          </Step.Content>
        </Step>
        <Step>
          <Icon name='check circle' color='blue' />
          <Step.Content>
            <Step.Title>Review Your Entries</Step.Title>
            <Step.Description>
              Within The <Link to='/update'>Monthly Review Tool</Link> you can
              click &quot;Show All Youth&quot; to review your entries and make
              any necessary changes.
              <br />
              You can also use <Link to='/allYouth'>The All Youth List</Link> to
              make further changes for a youth.
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
      {/*
      <List divided relaxed>
        <List.Item>
          <Label as={List.Icon} circular color="blue" size="massive">
            1
          </Label>
          <List.Content>
            <List.Header>Check Your Current Staff List</List.Header>
            <List.Description>
              Use <Link to="/staff">&quot;Manage Staff&quot;</Link> to view,
              add, edit, and remove staff so they appear correctly on your
              dropdown lists throughout this app.
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <Label as={List.Icon} circular color="blue" size="massive">
            2
          </Label>
          <List.Content>
            <List.Header>Enter All New Youth</List.Header>
            <List.Description>
              Use <Link to="/youth">&quot;Add New Youth&quot;</Link> to add
              youth who have enrolled in your program since your last update. If
              you are unsure whether a youth is already enrolled, you can{" "}
              <Link to="/all-youth">&quot;List All Youth.&quot;</Link>
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <Label as={List.Icon} circular color="blue" size="massive">
            3
          </Label>
          <List.Content>
            <List.Header>Log Monthly Updates</List.Header>
            <List.Description>
              Use The <Link to="/youth">&quot;Monthly Review Tool&quot;</Link>{" "}
              to report changes, model specific measures, and sessions that
              occured in the prior month for all of your active youth.
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <Label
            as={List.Icon}
            circular
            color="blue"
            size="massive"
            verticalAlign="top"
          >
            4
          </Label>
          <List.Content>
            <List.Header>Review Your Entries</List.Header>
            <List.Description>
              Within The <Link to="/youth">Monthly Review Tool</Link> you can
              click &quot;Show All Youth&quot; to review your entries and make
              any necessary changes.
              <br />
              You can also use <Link to="/all-youth">All Youth</Link> to make
              further changes for a youth.
            </List.Description>
          </List.Content>
        </List.Item>
      </List>
      */}
    </Fragment>
  )
}

export default WelcomeUser
