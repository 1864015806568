import React from "react";
import { Table, Label } from "semantic-ui-react";

const Profile = (props) => {
  // {Object.keys(props.user?.roles).map((key, index) => (
  //     <p key={index}>{props.user?.roles[key] ? key : ""}</p>
  //     ))}
  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Display Name:</Table.Cell>
          <Table.Cell>{props.user?.displayName}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Email:</Table.Cell>
          <Table.Cell>{props.user?.email}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Provider Name:</Table.Cell>
          <Table.Cell>{props.user?.providerName}</Table.Cell>
        </Table.Row>
        <Table.Row verticalAlign="top">
          <Table.Cell>Roles:</Table.Cell>
          <Table.Cell verticalAlign="top">
            {props.user?.roles?.Provider ? (
              <Label color="blue">Provider</Label>
            ) : (
              <Label>Provider</Label>
            )}
            {props.user?.roles?.EBAdmin && (
              <Label color="blue">EBAdmin</Label>
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default Profile;
