import { ServiceInterface } from "../../models/Service"
import { calcLOS, totalUnits } from "../functions"

export interface Field {
  path: string
  label: string
  format?: (value?: any) => string
  compute?: (service?: ServiceInterface) => string
  serviceValue?: (serviceName?: string, value?: any) => string
}

/* To Add a field to the CSV Report
Insert a new field object to the array below where you want it to appear in the report.
Label is the column header path is the path to the value in the service object in the database

Use only one optional function:
1) Format: If the value needs to be formatted
2) Compute: If the value needs to be computed from other values in the service object
3) ServiceValue: If the value is different for each service
 */
export const makeFieldsMap = (month: string): Field[] => {
    return [
    {
      label: 'Database ID',
      path: 'uid',
    },
    {
      label: 'Monthly Updated',
      path: `months.${month}.log.lastUpdated`,
      format: (lastUpdated?: firebase.firestore.Timestamp) => {
        if (!lastUpdated) return 'not updated'
        return lastUpdated.toDate().toLocaleDateString()
      },
    },
    {
      label: 'Monthly Update By',
      path: `months.${month}.log.lastUpdatedBy`,
    },
    {
      label: 'Project',
      path: 'project',
    },
    {
      label: 'Provider',
      path: 'provider.name',
      format: (name?: string) => {
        if (!name) return 'MISSING PROVIDER NAME'
        return name.replace(/,/g, '')
      }
    },
    {
      label: 'Service',
      path: 'shortName',
    },
    {
      label: 'First Name',
      path: 'youth.firstName',
    },
    {
      label: 'Last Name',
      path: 'youth.lastName',
    },
    {
      label: 'DOB',
      path: 'youth.DOB',
    },
    {
      label: 'Race',
      path: 'youth.race',
    },
    {
      label: 'Sex',
      path: 'youth.bioSex',
    },
    {
      label: 'Gender',
      path: 'youth.gender',
    },
    {
      label: 'eCura ID',
      path: 'youth.stateIDs.dc.eCura',
    },
    {
      label: 'iCams',
      path: 'youth.stateIDs.dc.iCams',
    },
    {
      label: 'Staff',
      path: 'staff.name',
    },
    {
      label: 'Start Date',
      path: 'dates.start',
    },
    {
      label: 'End Date',
      path: 'dates.end',
    },
    {
      label: 'Length of Stay (As of Export)',
      path: 'dates.los',
      compute: (service?: ServiceInterface) => {
        if (!service) return 'ERROR'
        const { start, end } = service.dates
        return calcLOS(start, end).toString()
      }
    },
    {
      label: 'Discharge Type',
      path: 'dischargeType',
    },
    {
        label: 'Discharge Note',
        path: 'dischargeNote',
        compute: (service?: ServiceInterface) => {
            if (!service) {
                return 'ERROR'
            }
            if (!service.dischargeType) {
                return ''
            }
            if (service.dischargeType === 'Admin') {
                return service.dischargeNote
            }
            return 'N/A'
        }
    },
    {
      label: 'Referral Source',
      path: 'referralSource',
    },
    {
      label: 'Referral Source Other',
      path: 'referralSourceCustom',
      format: (value?: string) => {
        if (!value) return 'not entered'
        return value.replace(/,/g, '')
      }
    },
    {
      label: 'Total Number of Sessions that Occurred this Month',
      path: `months.${month}.units`,
      format: (units?: number) => {
        if (!units) return 'not entered'
        return units.toString()
      }
    },
    {
      label: 'Total Number of Sessions for this Youth',
      path: '',
      compute: (service?: ServiceInterface) => {
        if (!service) return 'ERROR'
        return totalUnits(service).toString()
      }
    },
    {
      label: 'CPP Supervision Provided in Current Month',
      path: `months.${month}.model.cppSupervision`,
      serviceValue: cppServiceValue,
    },
    {
      label: 'Completed CPP Fidelity Tools in Current Month',
      path: `months.${month}.model.cppFidelity`,
      serviceValue: cppServiceValue,
    },
    {
      label: 'ECBI Problem Start',
      path: 'modelStatic.ecbiProbStart',
      serviceValue: pcitServiceValue,
    },
    {
      label: 'ECBI Problem Current',
      path: `months.${month}.model.ecbiCurrentProb`,
      serviceValue: pcitServiceValue,
    },
    {
      label: 'ECBI Problem End',
      path: 'modelStatic.ecbiProbEnd',
      serviceValue: pcitServiceValue,
    },
    {
      label: 'ECBI Intensity Start',
      path: 'modelStatic.ecbiIntensityStart',
      serviceValue: pcitServiceValue,
    },
    {
      label: 'ECBI Intensity Current',
      path: `months.${month}.model.ecbiCurrentIntensity`,
      serviceValue: pcitServiceValue,
    },
    {
      label: 'ECBI Intensity End',
      path: 'modelStatic.ecbiIntensityEnd',
      serviceValue: pcitServiceValue,
    },
    {
      label: 'DECA AR INITIAL',
      path: 'modelStatic.decaInitialAR',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA AR MIDPOINT',
      path: 'modelStatic.decaMidpointAR',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA AR DISCHARGE',
      path: 'modelStatic.decaDischargeAR',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA IN INITIAL',
      path: 'modelStatic.decaInitialIN',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA IN MIDPOINT',
      path: 'modelStatic.decaMidpointIN',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA IN DISCHARGE',
      path: 'modelStatic.decaDischargeIN',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA SR INITIAL',
      path: 'modelStatic.decaInitialSR',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA SR MIDPOINT',
      path: 'modelStatic.decaMidpointSR',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA SR DISCHARGE',
      path: 'modelStatic.decaDischargeSR',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA BC INITIAL',
      path: 'modelStatic.decaInitialBC',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA BC MIDPOINT',
      path: 'modelStatic.decaMidpointBC',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA BC DISCHARGE',
      path: 'modelStatic.decaDischargeBC',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA TPF INITIAL',
      path: 'modelStatic.decaInitialTPF',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA TPF MIDPOINT',
      path: 'modelStatic.decaMidpointTPF',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA TPF DISCHARGE',
      path: 'modelStatic.decaDischargeTPF',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA TYPE INITIAL',
      path: 'modelStatic.decaInitialTYPE',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA TYPE MIDPOINT',
      path: 'modelStatic.decaMidpointTYPE',
      serviceValue: decaServiceValue,
    },
    {
      label: 'DECA TYPE DISCHARGE',
      path: 'modelStatic.decaDischargeTYPE',
      serviceValue: decaServiceValue,
    },
  ]
  }

  const serviceSpecific = (filter: string[], serviceName?: string, value?: any) => {
    if (!serviceName) return 'ERROR'
    if (filter.includes(serviceName)) {
      return value ? value: 'not entered'
    }
    return 'N/A'
  }

  const cppServiceValue = (serviceName?: string, value?: any) => {
    return serviceSpecific(['CPP'], serviceName, value)
  }
  
  const pcitServiceValue = (serviceName?: string, value?: any) => {
    return serviceSpecific(['PCIT-T'], serviceName, value)
  }
  
  const decaServiceValue = (serviceName?: string, value?: any) => {
    return serviceSpecific(['PCIT-T', 'CPP'], serviceName, value)
  }