import React from 'react'
import { Form, Grid, Label, Message, Table } from 'semantic-ui-react'
import { decaTimeSelect, decaTypeSelect } from '../../util/selects'
import { DecaInput } from './DecaInput'
import { MAX_DECA_SCORE } from '../../util/validators'

const DECA = ({ service, setService, serviceErrors }) => {

  const {
    decaInitialAR = '',
    decaInitialIN = '',
    decaInitialSR = '',
    decaInitialBC = '',
    decaInitialTPF = '',
    decaMidpointAR = '',
    decaMidpointIN = '',
    decaMidpointSR = '',
    decaMidpointBC = '',
    decaMidpointTPF = '',
    decaDischargeAR = '',
    decaDischargeIN = '',
    decaDischargeSR = '',
    decaDischargeBC = '',
    decaDischargeTPF = '',
    decaInitialType = '',
    decaMidpointType = '',
    decaDischargeType = '',
  } = service.modelStatic

  const decaFields = [
    'decaInitialAR',
    'decaInitialIN',
    'decaInitialSR',
    'decaInitialBC',
    'decaInitialTPF',
    'decaMidpointAR',
    'decaMidpointIN',
    'decaMidpointSR',
    'decaMidpointBC',
    'decaMidpointTPF',
    'decaDischargeAR',
    'decaDischargeIN',
    'decaDischargeSR',
    'decaDischargeBC',
    'decaDischargeTPF',
    'decaInitialType',
    'decaMidpointType',
    'decaDischargeType',
  ]
  const fieldErrors = Object.keys(serviceErrors).some(
    (i) => decaFields.indexOf(i) >= 0
  )

  return (
    <>
      {fieldErrors && (
        <Message negative>
          <p>T-Scores must be between 0 and {MAX_DECA_SCORE}.</p>

          <p>Time Point and Type are required when entering any T-Score.</p>
        </Message>
      )}
      <Grid columns='three'>
        <Grid.Column>
          <h5>Initial</h5>
          <Label color='blue' basic>
            DECA T-Scores
          </Label>
          <Form.Select
            label='Type'
            placeholder='Select...'
            name='decaType'
            options={decaTypeSelect}
            value={decaInitialType}
            onChange={(e, data) => {
              setService({
                ...service,
                modelStatic: {
                  ...service.modelStatic,
                  decaInitialType: data.value, 
                },
              })
            }}
            error={serviceErrors.decaInitialType}
          />
          <DecaInput
            value={decaInitialAR}
            measure='ar'
            label='Attachment/Relationships (A/R)'
            shortLabel='A/R'
            timePoint='initial'
            service={service}
            model={'decaInitialAR'}
            setService={setService}
            serviceError={serviceErrors.decaInitialAR}
          />
          <DecaInput
            value={decaInitialIN}
            measure='in'
            label='Initiative (IN)'
            shortLabel='IN'
            timePoint='initial'
            service={service}
            model={'decaInitialIN'}
            setService={setService}
            serviceError={serviceErrors.decaInitialIN}
          />
          {(decaInitialType === 'Toddler' ||
            decaInitialType === 'Pre-school (PS)') && (
            <DecaInput
              value={decaInitialSR}
              measure='sr'
              label='Self-regulation (SR)'
              shortLabel='SR'
              timePoint='initial'
              service={service}
              model={'decaInitialSR'}
              setService={setService}
              serviceError={serviceErrors.decaInitialSR}
            />
          )}
          <DecaInput
            value={decaInitialTPF}
            measure='tpf'
            label='Total Protective Factors (TPF)'
            shortLabel='TPF'
            timePoint='initial'
            service={service}
            model={'decaInitialTPF'}
            setService={setService}
            serviceError={serviceErrors.decaInitialTPF}
          />
          {decaInitialType === 'Pre-school (PS)' && (
            <DecaInput
              value={decaInitialBC}
              measure='bc'
              label='Behavior Concerns (BC)'
              shortLabel='BC'
              timePoint='initial'
              service={service}
              model={'decaInitialBC'}
              setService={setService}
              serviceError={serviceErrors.decaInitialBC}
            />
          )}
        </Grid.Column>
        <Grid.Column>
          <h5>Midpoint</h5>
          <Label color='blue' basic>
            DECA T-Scores
          </Label>
          <Form.Select
            label='Type'
            placeholder='Select...'
            name='decaType'
            options={decaTypeSelect}
            value={decaMidpointType}
            onChange={(e, data) => {
              setService({
                ...service,
                modelStatic: {
                  ...service.modelStatic,
                  decaMidpointType: data.value, 
                },
              })
            }}
            error={serviceErrors.decaMidpointType}
          />
          <DecaInput
            value={decaMidpointAR}
            measure='ar'
            label='Attachment/Relationships (A/R)'
            shortLabel='A/R'
            timePoint='midpoint'
            service={service}
            model={'decaMidpointAR'}
            setService={setService}
            serviceError={serviceErrors.decaMidpointAR}
          />
          <DecaInput
            value={decaMidpointIN}
            measure='in'
            label='Initiative (IN)'
            shortLabel='IN'
            timePoint='midpoint'
            service={service}
            model={'decaMidpointIN'}
            setService={setService}
            serviceError={serviceErrors.decaMidpointIN}
          />
          {(decaMidpointType === 'Toddler' ||
            decaMidpointType === 'Pre-school (PS)') && (
            <DecaInput
              value={decaMidpointSR}
              measure='sr'
              label='Self-regulation (SR)'
              shortLabel='SR'
              timePoint='midpoint'
              service={service}
              model={'decaMidpointSR'}
              setService={setService}
              serviceError={serviceErrors.decaMidpointSR}
            />
          )}
          <DecaInput
            value={decaMidpointTPF}
            measure='tpf'
            label='Total Protective Factors (TPF)'
            shortLabel='TPF'
            timePoint='midpoint'
            service={service}
            model={'decaMidpointTPF'}
            setService={setService}
            serviceError={serviceErrors.decaMidpointTPF}
          />
          {decaMidpointType === 'Pre-school (PS)' && (
            <DecaInput
              value={decaMidpointBC}
              measure='bc'
              label='Behavior Concerns (BC)'
              shortLabel='BC'
              timePoint='midpoint'
              service={service}
              model={'decaMidpointBC'}
              setService={setService}
              serviceError={serviceErrors.decaMidpointBC}
            />
          )}
        </Grid.Column>
        <Grid.Column>
          <h5>Discharge</h5>
          <Label color='blue' basic>
            DECA T-Scores
          </Label>
          <Form.Select
            label='Type'
            placeholder='Select...'
            name='decaType'
            options={decaTypeSelect}
            value={decaDischargeType}
            onChange={(e, data) => {
              setService({
                ...service,
                modelStatic: {
                  ...service.modelStatic,
                  decaDischargeType: data.value, 
                },
              })
            }}
            error={serviceErrors.decaDischargeType}
          />
          <DecaInput
            value={decaDischargeAR}
            measure='ar'
            label='Attachment/Relationships (A/R)'
            shortLabel='A/R'
            timePoint='discharge'
            service={service}
            model={'decaDischargeAR'}
            setService={setService}
            serviceError={serviceErrors.decaDischargeAR}
          />
          <DecaInput
            value={decaDischargeIN}
            measure='in'
            label='Initiative (IN)'
            shortLabel='IN'
            timePoint='discharge'
            service={service}
            model={'decaDischargeIN'}
            setService={setService}
            serviceError={serviceErrors.decaDischargeIN}
          />
          {(decaDischargeType === 'Toddler' ||
            decaDischargeType === 'Pre-school (PS)') && (
            <DecaInput
              value={decaDischargeSR}
              measure='sr'
              label='Self-regulation (SR)'
              shortLabel='SR'
              timePoint='discharge'
              service={service}
              model={'decaDischargeSR'}
              setService={setService}
              serviceError={serviceErrors.decaDischargeSR}
            />
          )}
          <DecaInput
            value={decaDischargeTPF}
            measure='tpf'
            label='Total Protective Factors (TPF)'
            shortLabel='TPF'
            timePoint='discharge'
            service={service}
            model={'decaDischargeTPF'}
            setService={setService}
            serviceError={serviceErrors.decaDischargeTPF}
          />
          {decaDischargeType === 'Pre-school (PS)' && (
            <DecaInput
              value={decaDischargeBC}
              measure='bc'
              label='Behavior Concerns (BC)'
              shortLabel='BC'
              timePoint='discharge'
              service={service}
              model={'decaDischargeBC'}
              setService={setService}
              serviceError={serviceErrors.decaDischargeBC}
            />
          )}
        </Grid.Column>
      </Grid>
    </>
  )
}

export default DECA
