import React from 'react'
import { List, Label, Segment } from 'semantic-ui-react'

//SAMPLE list
/* <Segment color="blue">
      <h2>Mmm DD, YYYY</h2>
      <Label color="blue" basic>New Features</Label>
      <List divided relaxed="very">
        <List.Item>
            <List.Header>An item</List.Header>
            Description.
        </List.Item>
        <List.Item>
          <List.Header>Item 2</List.Header>
          Description.
        </List.Item>
      </List>
      <Label color="blue" basic>Minor Changes</Label>
      <List divided relaxed="very">
          <List.Item>
              <List.Header>Item</List.Header>
              Descript.
          </List.Item>
      </List>
      <Label color="blue" basic>Bug Fixes</Label>
      <List relaxed="very">
          <List.Item>
              <List.Header>Item</List.Header>
              Description.
          </List.Item>
      </List>
      </Segment> */

export default function ChangeLog() {
  return (
    <div>
      <h1>Change Log</h1>
      <Segment color="blue">
      <h2>October 29, 2023</h2>
      <Label color="blue" basic>New Features</Label>
      <List divided relaxed="very">
        <List.Item>
            <List.Header>Preventing Duplicate Youth Entries</List.Header>
            Users will be prevented from adding potentionally duplicate youth. Youth are flagged as potentional duplicates when they have the same first name, last name, and date of birth within an organization. If you are unable to add a youth because of this, please contact EBA which can enter the youth for you.
        </List.Item>
        <List.Item>
          <List.Header>Staff are sorted by active status</List.Header>
          The Manage Staff page now has two tables to separate active staff from inactive staff. Also, only active staff will appear in the drop down lists for assignment to youth. If an expected staff is missing as an option ensure that they have an end-date and start-date on the Manage Staff page.
        </List.Item>
      </List>
      <Label color="blue" basic>Minor Changes</Label>
      <List divided relaxed="very">
          <List.Item>
              <List.Header>LOS Calculation on Edit Youth Page</List.Header>
              The Length of Stay Calculation now runs before clicking "save" when the start or end date is changed, as well as on page load. This clarifies the LOS calculations when users are viewing youth detail pages.
          </List.Item>
      </List>
      </Segment>
      <Segment color="blue">
      <h2>October 2, 2023</h2>
      <Label color="blue" basic>New Features</Label>
      <List divided relaxed="very">
        <List.Item>
            <List.Header>Referral Source Other</List.Header>
            Users will be prompted to enter additional information when selecting an "other" referral source.
        </List.Item>
        <List.Item>
          <List.Header>Discharge Admin Reasons</List.Header>
          Users will be prompted to enter additional information when selecting an admin discharge..
        </List.Item>
      </List>
      </Segment>
      <Segment color="blue">
      <h2>March 6, 2023</h2>
      <Label color="blue" basic>New Features</Label>
      <List divided relaxed="very">
        <List.Item>
            <List.Header>More staff data points available</List.Header>
            Users can now update staff start & end dates as well as their departure reason.
        </List.Item>
      </List>
      </Segment>
      <Segment color="blue">
      <h2>November 30, 2022</h2>
      <Label color="blue" basic>New Features</Label>
      <List divided relaxed="very">
        <List.Item>
            <List.Header>DECA Scoring Updates</List.Header>
            If you need to update an initial, midpoint, or discharge DECA measure then please click on the corresponding link within the youth's row on the Monthly Update page. This will take you to the youth's detail page for more user-friendly data entry since the DECA is a complex measure.
        </List.Item>
        <List.Item>
            <List.Header>DECA Scoring available for CPP youth</List.Header>
            When you have youth enrolled in CPP you can now enter DECA scores for them on their youth detail page.
        </List.Item>
      </List>
      </Segment>
      <Segment color='blue'>
        <h2>October 27, 2022</h2>
        <Label color='blue' basic>
          New Features
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>PCIT-Toddler</List.Header>
            The web portal is ready to receive DECA-T scores for the
            start/pre-treatment, mid, and end of treatments for the
            Attachment-Relationships, Initative, and Self Regulation sub-scales.
            Users will see these inputs when enrolling a youth into PCIT-T on
            the Add New Youth screen, when editing an existing youth, entering
            monthly updates, and within the csv export files.
          </List.Item>
        </List>
      </Segment>
      <Segment color='blue'>
        <h2>November 28, 2021</h2>
        <Label color='blue' basic>
          New Features
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>More options to classify staff</List.Header>
            On the Manage Staff page users can now indicate whether a staff is
            full-time vs part-time and an employee vs contractor. Please review
            your staff page during your next data submission. All current staff
            were classified as "employee" for the purposes of this datachange.
          </List.Item>
          <List.Item>
            <List.Header>Re-formatting of the CSV download</List.Header>
            Provider's CSV download (on the Monthly Review Tool page) now
            includes the service ID that is used in the database, the date an
            update was recorded, which user submitted the update, and several
            more columns. This should assist with troubleshooting coordination
            with EBA.
          </List.Item>
        </List>
      </Segment>
      <Segment color='blue'>
        <h2>July 31, 2021</h2>
        <Label color='blue' basic>
          Bug Fix
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>Unable able to edit some closed youth</List.Header>
            Corrected an issue that resulted in a blank screen when opening some
            closed youth records who were in rolled in CPP or PCIT.
          </List.Item>
        </List>
      </Segment>
      <Segment color='blue'>
        <h2>April 30, 2021</h2>
        <Label color='blue' basic>
          New Feature
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>Possible Duplicate Youth Warning</List.Header>
            On the add youth screen you will receive a warning if you already
            have a youth with the same name and DOB. This warning will not
            prevent you from entering the youth however.
          </List.Item>
        </List>
      </Segment>
      <Segment color='blue'>
        <h2>February 28, 2021</h2>
        <p>
          Changes focus on backend stability and performance including database
          re-structuring.
        </p>
        <Label color='blue' basic>
          New Features
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>Monthly Update Form</List.Header>
            <ul>
              <li>An icon will display while the table is loading.</li>
              <li>Saved submissions will have a green background.</li>
            </ul>
          </List.Item>
        </List>
        <Label color='blue' basic>
          Minor Changes
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>Messaging</List.Header>
            Improved error handling and messages.
          </List.Item>
        </List>
        <Label color='blue' basic>
          Bug Fixes
        </Label>
        <List relaxed='very'>
          <List.Item>
            <List.Header>Duplicate entries on Monthly Update Tool</List.Header>
            Corrected an issue in which some providers discovered duplicate
            youth on their update tool.
          </List.Item>
        </List>
      </Segment>
      <Segment color='blue'>
        <h2>December 31, 2020</h2>
        <Label color='blue' basic>
          Minor Changes
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>Backend stability changes</List.Header>
            Some minor maintainence and upkeep.
          </List.Item>
        </List>
      </Segment>
      <Segment color='blue'>
        <h2>October 28, 2020</h2>
        <Label color='blue' basic>
          Minor Changes
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>Developer Contact</List.Header>
            The developer&apos;s contact email has changed. Please report any
            bugs or issues to joelnwalkley@gmail.com
          </List.Item>
          <List.Item>
            <List.Header>
              Clarification regarding the Monthly Update CSV download
            </List.Header>
            The language on the Monthly Update page in the section regarding the
            download file has been made more clear. The file will include all
            submissions at the time of download. This means it will be blank if
            you have made no submissions yet, and could serve as a backup file
            if you have made all your submissions for the month.
          </List.Item>
          <List.Item>
            <List.Header>CSV Download Includes Total Sessions</List.Header>
            The CSV download includes a column for total sessions.
          </List.Item>
        </List>
        <Label color='blue' basic>
          Bug Fixes
        </Label>
        <List relaxed='very'>
          <List.Item>
            <List.Header>
              &quot;Null&quot; appeared in the CSV download
            </List.Header>
            This will now show as a blank cell.
          </List.Item>
        </List>
      </Segment>
      <Segment color='blue'>
        <h2>August 28, 2020</h2>
        <Label color='blue' basic>
          New Features
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>This Change Log!</List.Header>
            This will help you understand the changes to the app that occur
            inbetween your data submissions.
          </List.Item>
          <List.Item>
            <List.Header>ECBI Measures</List.Header>
            Providers who offer PCIT will see a change on the Monthly Review
            Tool and the Add/Edit Youth page. ECBI now includes both problem and
            intensity scores at initial, PDI, and the end of service. You will
            also see the difference which calculates between the initial score
            and the most recent secondary score (PDI or End). A negative
            difference indicates that the ECBI increased.
          </List.Item>
        </List>
        <Label color='blue' basic>
          Minor Changes
        </Label>
        <List divided relaxed='very'>
          <List.Item>
            <List.Header>Case count no longer manually entered</List.Header>
            You no longer need to enter or update your staff case counts on the
            Manage Staff Page. Case loads will be calculated at the time of
            EBA&apos;s export.
          </List.Item>
          <List.Item>
            <List.Header>
              Cannot enter a discharge later than the report month
            </List.Header>
            You will be prevented from entering an end date if it is later than
            the report month. For example, when you log in during September you
            are entering data for August. You may have a youth who does
            discharge in September, but do not enter this until the next month
            data submission. Entering a September discharge will prompt you to
            correct an error before submitting (just leave the end date blank
            until next month).
          </List.Item>
          <List.Item>
            <List.Header>Transitional Facilitator added</List.Header>
            Providers who offer TIP can change staff position name to
            &quot;Transitional Facilitator&quot; on the Manage Staff page.
          </List.Item>
        </List>
        <Label color='blue' basic>
          Bug Fixes
        </Label>
        <List relaxed='very'>
          <List.Item>
            <List.Header>Column sorting on the Monthly Review Tool</List.Header>
            Users reported that when sorting the Monthly Review Tool by service
            or staff the order would not be kept after submitting a change. This
            has been corrected. If you still experience this issue, please
            report it to{' '}
            <a href='mailto:joelnwalkley@gmail.com'>joelnwalkley@gmail.com</a>
          </List.Item>
        </List>
      </Segment>
    </div>
  )
}
