import React, { Component } from 'react';
import Router from './router/Router';

import './App.css';

import { Container, Menu, Label, Grid } from 'semantic-ui-react';

import Header from './components/Header';
import { DashboardMenu } from './components/EBAdmin/Dashboard/DashboardMenu';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import { db } from './config/fireInit';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      showDashboardMenu: false,
    };
  }

  componentDidMount() {
    this.authListener();
  }

  componentDidUpdate() {
    //console.log("App component did update");
  }

  authListener() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //if last login was more than x minutes, signout.
        const today = new Date();
        const lastLogin = new Date(user.metadata.lastSignInTime);
        let minsSinceLogin =
          (today.getTime() - lastLogin.getTime()) / 1000 / 60;
        minsSinceLogin = Math.abs(Math.round(minsSinceLogin));
        //console.log("minutes since login", minsSinceLogin);
        if (minsSinceLogin >= 100) {
          //user.reauthenticateWithPopup("microsoft.com");
          //firebase.auth().signOut();
        }
        let userRef = db.collection('users').doc(user.uid);
        userRef.onSnapshot((doc) => {
          this.setState({ user: doc.data() });
        });
      } else {
        this.setState({ user: null });
      }
    });
  }

  handleSignIn(provider) {
    var signInProvider =
      provider === 'Microsoft'
        ? new firebase.auth.OAuthProvider('microsoft.com')
        : new firebase.auth.GoogleAuthProvider();
    //var signInProvider = new firebase.auth.OAuthProvider("microsoft.com");
    //var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(signInProvider)
      .then(function (result) {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.
        // OAuth access token can also be retrieved:
        // result.credential.accessToken
        // OAuth ID token can also be retrieved:
        // result.credential.idToken

        //save to users collection.
        let userData = result.user;
        let userRef = db.collection('users').doc(userData.uid);
        userRef.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            //user registered, update from login info
            return userRef
              .set(
                {
                  uid: userData.uid,
                  displayName: userData.displayName,
                  email: userData.email,
                  lastLogin: userData.metadata.lastSignInTime,
                },
                { merge: true }
              )
              .catch((reason) => console.log('user save failed', reason));
          } else {
            //newly registered user
            userRef
              .set(
                {
                  uid: userData.uid,
                  displayName: userData.displayName,
                  email: userData.email,
                  //approved: false,
                  createdAt: new Date(),
                  providerName: 'None',
                  providerID: 'None',
                  roles: {
                    EBAdmin: false,
                    Provider: false,
                  },
                },
                { merge: true }
              )
              .catch((reason) => console.log('user save failed', reason));
          }
        });
      })
      .catch(function (error) {
        // Handle error.
        console.log('Error', error);
      });
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
  }

  handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
        this.setState({ user: null });
      })
      .catch(function (error) {
        console.log('Error', error);
      });
  };

  showDashboardMenu = () => {
    this.setState({ showDashboardMenu: true });
  };

  hideDashboardMenu = () => {
    this.setState({ showDashboardMenu: false });
  };

  render() {
    const { user } = this.state;
    return (
      <>
        <Header
          user={user}
          signIn={this.handleSignIn}
          signOut={this.handleSignOut}
          showDashboardMenu={this.showDashboardMenu}
          hideDashboardMenu={this.hideDashboardMenu}
        />
        <Grid columns={2} padded centered>
          <Grid.Row>
            {this.state.showDashboardMenu && (
              <Grid.Column width={2}>
                <DashboardMenu />
              </Grid.Column>
            )}
            <Grid.Column width={14}>
              <Container>
                <Router
                  user={user}
                  signIn={this.handleSignIn}
                  signOut={this.handleSignOut}
                  showDashboardMenu={this.showDashboardMenu}
                  hideDashboardMenu={this.hideDashboardMenu}
                />
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className='footer'></div>
      </>
    );
  }
}

export default App;
