// module.exports.lastMonth = () => {
//     let lastMonth = new Date();
//     lastMonth.setDate(1);
//     lastMonth.setMonth(lastMonth.getMonth() - 1);
//     console.log("last mont is ",lastMonth)
//     return lastMonth;
// }

const updateMonthYYYYMM = () => {
  let thisMonth = new Date();
  thisMonth.setDate(1);
  thisMonth.setMonth(thisMonth.getMonth() - 1);
  let month = ('0' + (thisMonth.getMonth() + 1)).slice(-2);
  let year = thisMonth.getFullYear();
  const YYYYMM = year + month;
  return YYYYMM;
};

const nowYYYYMM = () => {
  let thisMonth = new Date();
  thisMonth.setDate(1);
  let month = ('0' + (thisMonth.getMonth() + 1)).slice(-2);
  let year = thisMonth.getFullYear();
  const YYYYMM = year + month;
  return YYYYMM;
};

const firstDayOfLastMonth = () => {
  let thisMonth = new Date();
  thisMonth.setDate(1);
  thisMonth.setMonth(thisMonth.getMonth() - 1);
  let month = ('0' + (thisMonth.getMonth() + 1)).slice(-2);
  let year = thisMonth.getFullYear();
  return year + '-' + month + '-01';
};

const updateMonthLabel = () => {
  let updateMonth = new Date();
  updateMonth.setDate(1);
  updateMonth.setMonth(updateMonth.getMonth() - 1);
  let month = ('0' + (updateMonth.getMonth() + 1)).slice(-2);
  let year = updateMonth.getFullYear();
  month = updateMonth.toLocaleString('default', { month: 'long' });
  year = updateMonth.getFullYear().toString();
  return month + ' ' + year;
};

const calcLOS = (start, end) => {
  const today = new Date();
  let endDate = end ? new Date(end + 'T12:00:00Z') : today;
  let startDate = start ? new Date(start + 'T12:00:00Z') : today;
  const los = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
  return isNaN(los) ? '' : los;
};

const listMonths = (start, end) => {
  const today = new Date(); //
  const YYYYMMs = [];
  let endDate = end ? new Date(end + 'T12:00:00Z') : today;
  let startDate = start ? new Date(start + 'T12:00:00Z') : today;

  //backstop to prevent a long loop.
  const dateCheck = new Date('1999-12-31');

  //set date parts to ensure they are equal regardless of timezone.
  //important when a date control is empty to start and uses today
  startDate.setDate(1);
  startDate.setHours(0, 0, 0, 0);
  endDate.setDate(1);
  endDate.setHours(0, 0, 0, 0);

  while (startDate <= endDate && startDate > dateCheck) {
    const month = startDate.getMonth() + 1;
    const YYYY = startDate.getFullYear();
    let MM = '';
    if (month < 10) {
      MM = '0' + month;
    } else {
      MM += month;
    }
    YYYYMMs.push(YYYY + MM);
    startDate = new Date(startDate.setMonth(startDate.getMonth() + 1));
  }
  return YYYYMMs;
};

const createServiceMonths = (months, service) => {
  let serviceMonths = { ...service.months };
  months.map((month) => {
    if (!service.months[month] && month !== nowYYYYMM()) {
      serviceMonths = {
        ...serviceMonths,
        [month]: {
          exists: true,
          units: '',
          model: {
            cppFidelity: '',
            cppSupervision: '',
            ecbiCurrentProb: '',
            ecbiCurrentIntensity: '',
          },
          log: {
            updated: false,
            lastUpdated: '',
            lastUpdatedBy: '',
          },
        },
      };
    }
    return month;
  });
  return serviceMonths;
};

const deleteServiceMonths = (months = [], service) => {
  const YYYYMM = updateMonthYYYYMM();
  Object.keys(service.months).map((month) => {
    if (!months.includes(month) && month !== YYYYMM) {
      delete service.months[month];
    }
    return month;
  });
};

const monthShortLabel = (YYYYMM = '') => {
  const YYYY = YYYYMM.slice(0, 4);
  const MM = YYYYMM.slice(4, 6);
  const months = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'Aug',
    '09': 'Sept',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
  };
  return months[MM] + ' ' + YYYY;
};

const calcAge = (DOB) => {
  const today = new Date();
  const birthY = DOB.substr(0, 4);
  const birthM = DOB.substr(5, 2);
  const birthD = DOB.substr(8, 2);
  const todayY = today.getUTCFullYear();
  let todayM = today.getUTCMonth() >= 12 ? 1 : today.getUTCMonth() + 1;
  const todayD = today.getUTCDate();
  let age = todayY - Number(birthY);
  const m = todayM - Number(birthM);
  if (m < 0 || (m === 0 && todayD < Number(birthD))) {
    age--;
  }
  return age;
};

const totalUnits = (serviceObj) => {
  const months = serviceObj.months;
  let unitCount = 0;
  Object.keys(months).forEach((month) => {
    unitCount = unitCount + Number(serviceObj.months[month].units);
  });
  return unitCount;
};

const dischargeNoteText = (note, type) => {
    if (!type) {
        return ""
    }
    if (type === 'Admin') {
        return note
    }
    return "N/A";
}

export {
  updateMonthYYYYMM,
  updateMonthLabel,
  calcLOS,
  listMonths,
  createServiceMonths,
  deleteServiceMonths,
  dischargeNoteText,
  monthShortLabel,
  firstDayOfLastMonth,
  nowYYYYMM,
  calcAge,
  totalUnits,
};
