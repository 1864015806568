import React from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { db } from '../../config/fireInit';
import { saveAs } from "file-saver";

import { exportMonthAllFieldsCSV } from '../../util/exportCSV';

const BlankCSV = ({ updateMonthLabel, YYYYMM, user }) => {
  const getServices = () => {
    const monthsField = 'months.' + YYYYMM + '.exists';
    const csvFilename = user.providerName + "." + updateMonthLabel + ".csv";
    db.collection('services')
      .where('provider.uid', '==', user.providerID)
      .where(monthsField, '==', true)
      .get()
      .then((querySnapshot) => {
        exportMonthAllFieldsCSV(querySnapshot, csvFilename);
      })
      .catch((err) => console.log('error: ', err));
  };

  return (
    <Table>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <b>Optional: </b>If you need an offline version of this update tool
            (similar to the prior Excel trackers), you can download here.
            However, this file is only a reference and must be submited in the
            fields below within the app. The downloaded file will include any
            current submissions so it can also serve as a backup or receipt of
            your submission. This page is also printer friendly.
          </Table.Cell>
          <Table.Cell>
            <Button primary icon onClick={getServices}>
              <Icon name='table' />
              Download {updateMonthLabel} CSV File
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default BlankCSV;
