import React, { Component } from "react";
import { Table, Form, Button } from "semantic-ui-react";
import {db} from "../../config/fireInit";

const emptyUserObj = {
  uid: "",
  displayName: "",
  email: "",
  providerName: "",
  roles: {}
};

// const booleanSelect = [
//   { key: "0", text: "No", value: "false" },
//   { key: "1", text: "Yes", value: "true" }
// ];

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userObj: emptyUserObj
    };

    this.unsubscribe = [];
  }

  componentDidMount() {
    const pathArray = this.props.location.pathname.split("/");
    const userID = pathArray[pathArray.length - 1];
    let userRef = db.collection("users").doc(userID);
    this.unsubscribe = userRef.onSnapshot(doc => {
      this.setState({ userObj: doc.data() });
    });
  }

  componentWillUnmount(){
    if (this.unsubscribe){
      this.unsubscribe();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    //const { userObj } = this.state;
    //switch approval strings back to booleans
    //
    let userObj = { ...this.state.userObj };
    //userObj.approved = userObj.approved === "true" ? true : false;
    const userRef = db.collection("users").doc(userObj.uid);
    console.log("userObj is", userObj);
    userRef
      .set(userObj, { merge: true })
      .then(() => {
        this.setState({
          //successMessage: "User updated.",
          //successHeader: "Success"
        });
        console.log("success");
      })
      .catch(error => {
        //change state so error message displays
        //this.setState({ errorMessage: error.message, errorHeader: "Error" });
        console.log("error", error);
      });
  };

  handleInputChange = e => {
    const userObj = { ...this.state.userObj };
    userObj[e.target.name] = e.target.value;
    this.setState({ userObj });
  };

  handleSelectChange = (e, data) => {
    const userObj = { ...this.state.userObj };
    userObj[data.name] = data.value;
    this.setState({ userObj });
  };

  render() {
    const { userObj } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Display Name:</Table.Cell>
              <Table.Cell>{userObj.displayName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Email:</Table.Cell>
              <Table.Cell>{userObj.email}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Provider Name:</Table.Cell>
              <Table.Cell>
                <Form.Field>
                  <input
                    type='text'
                    name='providerName'
                    value={userObj.providerName}
                    onChange={e => this.handleInputChange(e)}
                  />
                </Form.Field>
              </Table.Cell>
            </Table.Row>
            <Table.Row verticalAlign='top'>
              <Table.Cell>Roles:</Table.Cell>
              <Table.Cell>
                {/*Object.keys(props.user?.roles).map((key, index) => (
                            <p key={index}>{props.user?.roles[key] ? key : ""}</p>
                        ))*/}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Button type='submit'>Edit User</Button>
      </Form>
    );
  }
}

export default EditUser;
