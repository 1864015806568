import React from "react";
import { Item, Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

const YouthItem = props => {
  return (
    <Item>
        <Item.Content>
            <Item.Header>{props.youth.firstName} {props.youth.lastName}</Item.Header>
            <Item.Meta>{props.youth.uid}</Item.Meta>
            <Item.Description>DOB: {props.youth.DOB}</Item.Description>
            <Item.Extra>
                <Button as={Link} to={"/youth/" + props.youth.uid} primary>
                    Edit
                    <Icon name="right chevron"/>
                </Button>
            </Item.Extra>
        </Item.Content>
    </Item>
  );
};

export default YouthItem;
