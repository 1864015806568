import React from 'react';
import {NavLink} from "react-router-dom";
import { Menu } from 'semantic-ui-react';

export const DashboardMenu = () => {
  return (
    <Menu vertical>
      <Menu.Item as={NavLink} to='/EBAdmin/dashboard/main' name='mainDashboard'>
        Main Dashboard
      </Menu.Item>
      <Menu.Item as={NavLink} to='/EBAdmin/dashboard/los' name='LOSReport'>
          Length of Stay Report
      </Menu.Item>
    </Menu>
  );
};
