import React, { Component, Fragment } from "react";
import { AddStaff } from "./AddStaff";
//import StaffRow from "./StaffRow";
import AllStaff from "./AllStaff";
import ProviderSelect from "../EBAdmin/ProviderSelect";
import { Header, Table, Form, Message, Icon } from "semantic-ui-react";
import { db } from "../../config/fireInit";
import { StaffRow } from "./StaffRow";

export default class ManageStaff extends Component {
  constructor(props) {
    super(props);
    //this.loadStaff = this.loadStaff.bind(this);
    this.state = {
      allStaff: [],
      activeStaff: [],
      inactiveStaff: [],
      providers: [],
      providerID: "",
    };
    this.unsubscribeStaff = () => {};
    this.unsubscribeProviders = () => {};
  }

  componentDidMount() {
    const { user } = this.props;
    this.setState({ providerID: user?.providerID });
    //console.log("user.providerID", user.providerID);
    if (user?.roles.EBAdmin) {
      this.unsubscribeProviders = db.collection("providers")
      .orderBy("providerName")
      .onSnapshot((querySnapshot) => {
        const providerData = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ providers: providerData });
        //console.log("providerData", providerData);
      });
    } else {
      //not an admin
      this.setState({
        providers: [
          {
            providerName: user?.providerName,
            uid: user?.providerID,
          },
        ],
      });
    }
    //this.loadStaff(user.providerID);
    if (user){
      this.unsubscribeStaff = db
      .collection("staff")
      .where("providerID", "==", user?.providerID)
      //.orderBy("lastName", "asc")
      .limit(250)
      .onSnapshot(
        (querySnapshot) => {
          const staffData = querySnapshot.docs.map((doc) => doc.data());
          const activeStaff = staffData.filter((staff) => staff.endDate === "")
            .sort((a, b) => a.lastName.localeCompare(b.lastName));
          const inactiveStaff = staffData.filter((staff) => staff.endDate !== "")
            .sort((a, b) => b.endDate?.localeCompare(a.endDate));
          this.setState({ 
            allStaff: staffData,
            activeStaff: activeStaff,
            inactiveStaff: inactiveStaff,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    this.unsubscribeStaff = db
      .collection("staff")
      .where("providerID", "==", user?.providerID)
      .orderBy("lastName")
      .onSnapshot((querySnapshot) => {
        const staffData = querySnapshot.docs.map((doc) => doc.data()); //was doc.data().staffName
        this.setState({ staff: staffData });
        //console.log("staffData", staffData);
      });
    }
  }

  handleProviderSelectChange(e, data) {
    this.unsubscribeStaff = db
      .collection("staff")
      .where("providerID", "==", data.value)
      //.orderBy("lastName", "asc")
      .limit(250)
      .onSnapshot((querySnapshot) => {
        const staffData = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ allStaff: staffData });
      });
  }

  componentWillUnmount() {
    this.unsubscribeProviders();
    this.unsubscribeStaff();
  }

  render() {
    const { providers } = this.state;
    const { user } = this.props;
    return (
      <Fragment>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          noValidate
          autoComplete="off"
        >
          {user?.roles.EBAdmin && (
            <Fragment>
              <Header as="h2" dividing>
                Provider:
              </Header>
              <ProviderSelect
                providerName={user?.providerName}
                providerID={user?.providerID}
                uid={user?.uid}
                providers={providers}
                //loadStaff={this.loadStaff}
              />
            </Fragment>
          )}
        </Form>
        <br />
        <AddStaff user={user} />
        <hr />
        <Header as="h2">Active Staff</Header>
        <Table sortable celled>
          <Table.Header>
            <Table.Row verticalAlign="top" textAlign="center">
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Employment Status</Table.HeaderCell>
              <Table.HeaderCell>Start & End</Table.HeaderCell>
              <Table.HeaderCell>Position</Table.HeaderCell>
              <Table.HeaderCell>Update</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {this.state.activeStaff.map((eachStaff) => (
            <StaffRow key={eachStaff.uid} displayStaff={eachStaff} />
          ))}
          </Table.Body>
        </Table>

        <hr />
        <Header as="h2">Inactive Staff</Header>
        <Message icon info>
            <Icon name="info circle" />
            <Message.Content>
                Staff are considered inactive if they have an end date or no start date. Please update staff below to make them active.
            </Message.Content>
        </Message>
        <Table sortable celled>
          <Table.Header>
            <Table.Row verticalAlign="top" textAlign="center">
              <Table.HeaderCell>Last Name</Table.HeaderCell>
              <Table.HeaderCell>First Name</Table.HeaderCell>
              <Table.HeaderCell>Employment Status</Table.HeaderCell>
              <Table.HeaderCell>Start & End</Table.HeaderCell>
              <Table.HeaderCell>Position</Table.HeaderCell>
              <Table.HeaderCell>Update</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {this.state.inactiveStaff.map((eachStaff) => (
            <StaffRow key={eachStaff.uid} displayStaff={eachStaff} />
          ))}
          </Table.Body>
        </Table>
      </Fragment>
    );
  }
}
