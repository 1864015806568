import React, { useState } from 'react';

import { Accordion, Message, Icon } from 'semantic-ui-react';

export const ManageUserDocs = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleClick = (e, titleProps) => {
    console.log("titleProps...",titleProps)
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <Message icon info>
        <Icon name='info circle' />
        <Message.Content>
          <Message.Header>Instructions For This Page</Message.Header>
          <Accordion>
            <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
            >
              <Icon name='dropdown' />
              Approving A New User
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <p>
                A new user self-registers by clicking one of the login buttons from the main page. They will appear here assigned to provider "none" and the provider role will be inactive (indicated by a white button). While in this state the user will see a message that their account is pending approval. To activate a user, associate them to the appropriate provider from the drop down list and click the "Provider" button until it turns blue. A user must both be approved to the Provider role and associated with a provider in order to use this web portal.
              </p>
            </Accordion.Content>

            <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={handleClick}
            >
              <Icon name='dropdown' />
              Deleting A User
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>
                Click the red "remove user" button on the corresponding row. A user may re-register and then they will show up here as if they are a new user, but will not be able to access the web portal until approved.
              </p>
            </Accordion.Content>
          </Accordion>
        </Message.Content>
      </Message>
    </>
  );
};
