export const firebaseEnvironments = {

  development: {
    apiKey: "AIzaSyA4SHoKcLnAnS11kxChcixMIWGie-2Wi-8",
    authDomain: "ebapp-dev.firebaseapp.com",
    databaseURL: "https://ebapp-dev.firebaseio.com",
    projectId: "ebapp-dev",
    storageBucket: "ebapp-dev.appspot.com",
    messagingSenderId: "470383210796",
    appId: "1:470383210796:web:1b1b6c2409a5a1b02e3c89",
    measurementId: "G-J9X8SJSCKC"
  },

  testing: {
    apiKey: "AIzaSyAoiJ-CzikP77IgUkqc_grrDECqu8IcmEg",
    authDomain: "ebapp-b931b.firebaseapp.com",
    databaseURL: "https://ebapp-b931b.firebaseio.com",
    projectId: "ebapp-b931b",
    storageBucket: "ebapp-b931b.appspot.com",
    messagingSenderId: "321567839750",
    appId: "1:321567839750:web:ef17627dd39e4b3c34d587",
    measurementId: "G-0GTRVR72KG"
  },
  
  production: {
    apiKey: "AIzaSyAZqztRE5M_qkk9YpWT4WVK4v8ifSmFWLM",
    authDomain: "ebapp-3b893.firebaseapp.com",
    databaseURL: "https://ebapp-3b893.firebaseio.com",
    projectId: "ebapp-3b893",
    storageBucket: "ebapp-3b893.appspot.com",
    messagingSenderId: "205192218369",
    appId: "1:205192218369:web:810c76ae09cd5f49ddfd00",
    measurementId: "G-3V0WHKFDL9"
  }
}

export default firebaseEnvironments;