import React, {useState} from 'react';

import { Button, Header, Image, Modal, Icon } from 'semantic-ui-react';

import {db} from '../../config/fireInit';

export const ConfirmDeleteUser = (props) => {

    const {displayUser, user} = props;

    const [modalOpen, setModalOpen] = useState(false);    

    const deleteUser = () => {
        const userRef = db.collection('users').doc(displayUser.uid);
        userRef.delete();
        setModalOpen(false);
    }

    return (
        <Modal
      onClose={() => setModalOpen(false)}
      onOpen={() => setModalOpen(true)}
      open={modalOpen}
      size='small'
      trigger={
        <Button basic color='red' type='button' disabled={displayUser.uid === user.uid}>
          <Icon name='user delete' />
        </Button>
      }
    >
      <Header icon>
        <Icon name='delete' color='red' />
        Are You Sure? This Cannot Be Undone.
      </Header>
      <Modal.Actions>
        <Button type='button' onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
        <Button
          type='button'
          content='Yes, Delete'
          labelPosition='right'
          icon='delete'
          onClick={() => deleteUser()}
          negative
        />
      </Modal.Actions>
    </Modal>
    )
}
