//TODO - write cloud function listeners to update the dashboard counts to a single document for that purpose.
//Can resync monthly
//For now, this will cost about 1K reads per loading, but at current usage should be okay.
import React, { useState, useEffect } from 'react';

import { Grid, Icon, Label, Message } from 'semantic-ui-react';
import { updateMonthYYYYMM, updateMonthLabel } from '../../../util/functions';

import { db } from '../../../config/fireInit';

export const Dashboard = (props) => {
  const [activeServices, setActiveServices] = useState(0);
  const [numProviders, setNumProviders] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [serviceUnits, setServiceUnits] = useState(0);
  const [queryDone, setQueryDone] = useState(false);
  const [queryError, setQueryError] = useState(false);

  const YYYYMM = updateMonthYYYYMM();

  //make sure dashboard menu is active
  useEffect(() => {
      props.showDashboardMenu();
      return () => {
          //
      }
  }, [])

  //query for quick facts
  //TODO - these stats should be in a "dashboard" document and updated onCreate/Change rather than querying the entire database every load.
  //TODO - rewrite as a listener so that the numbers can change as you view them
  useEffect(() => {
    let unitCount = 0;
    const servicesQuery = db
      .collection('services')
      .where('dates.end', '==', '')
      .get()
      .then((docs) => {
        setActiveServices(docs.size);
        docs.forEach((doc) => {
          unitCount += Number(doc.data().months[YYYYMM].units);
        });
        setServiceUnits(unitCount);
      });

    const providersQuery = db
      .collection('providers')
      .get()
      .then((docs) => {
        setNumProviders(docs.size);
      });

    const usersQuery = db
      .collection('users')
      .get()
      .then((docs) => {
        setActiveUsers(docs.size);
      });

    Promise.all([servicesQuery, providersQuery, usersQuery])
      .then(() => {
        setQueryDone(true);
      })
      .catch((err) => {
        setQueryError(err.message);
      });

    return () => {
        //no cleanup needed because queries are not active listeners.
    };
  }, []);

  return (
    <>
      <h1>EBAdmin Dashboard</h1>
      <p>
        In this section you will be able to see program-level data to help you
        gain insights and plan interventions.
      </p>
      <p>
        The first report considers Length of Stay and can be accessed by
        clicking the menu on the left.
      </p>
      <h2>Quick Facts:</h2>
      {queryError && (
        <Message icon negative>
          <Icon name='exclamation circle' />
          <Message.Content>
            <Message.Header>Error</Message.Header>
            {queryError}
          </Message.Content>
        </Message>
      )}
      {!queryDone ? (
        <Message icon>
          <Icon name='circle notched' loading />
          <Message.Content>
            <Message.Header>Loading</Message.Header>
            Loading quick dashboard...
          </Message.Content>
        </Message>
      ) : (
        <Grid columns={4} celled>
          <Grid.Row>
            <Grid.Column>Active Services</Grid.Column>
            <Grid.Column>Total Users</Grid.Column>
            <Grid.Column>Service Providers</Grid.Column>
            <Grid.Column>Units of Service Delivered in {updateMonthLabel()}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Label color='teal'>
                <Icon name='child' />
                {activeServices}
              </Label>
            </Grid.Column>
            <Grid.Column>
              <Label color='teal'>
                <Icon name='users' />
                {activeUsers}
              </Label>
            </Grid.Column>
            <Grid.Column>
              <Label color='teal'>
                <Icon name='building outline' />
                {numProviders}
              </Label>
            </Grid.Column>
            <Grid.Column>
              <Label color='teal'>
                <Icon name='clock outline' />
                {serviceUnits.toLocaleString()}
              </Label>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
};
