//TODO: Issue with async/await on getting those case counts.

import React, { useEffect } from 'react';

import { db } from '../../config/fireInit';
import {services} from '../../models/services';

import { updateMonthLabel } from '../../util/functions';

import { Button, Message } from 'semantic-ui-react';
import { firestore } from 'firebase';
import { saveAs } from 'file-saver';

const CopyServicesFromYouth = async () => {
  const youthSnapshot = await db.collection('youth').get();

  //get every youth
  let youthCount = 0;
  let serviceCount = 0;
  youthSnapshot.forEach(async (doc) => {
    youthCount++;
    const { services } = doc.data();
    const youthData = doc.data();
    let totalUnits = 0;
    Object.keys(services).forEach(async (key) => {
      serviceCount++;
      const newService = await db.collection('services').doc();
      console.log(`Attempting service ${newService.id} for youth ${youthData.uid}...`);
      //build months object
      const months = {};
      const youthServiceMonths = services[key].units;
      if (!youthServiceMonths){
        console.log(`youth ${youthData.uid} serviceMonths is undefined.`)
      }
      const monthsList = [];
      const monthsNeedUpdated = [];
      Object.keys(youthServiceMonths).forEach((monthIndex) => {
        totalUnits = totalUnits + Number(youthServiceMonths[monthIndex]);
        monthsList.push(monthIndex);
        const units = youthServiceMonths[monthIndex] ? youthServiceMonths[monthIndex] : '';
        if (!units) {
          monthsNeedUpdated.push(monthIndex);
        }
        months[monthIndex] = {
          exists: true,
          units:
            youthServiceMonths[monthIndex] === null
              ? ''
              : youthServiceMonths[monthIndex],
          model: {
            cppFidelity: '',
            cppSupervision: '',
            ecbiCurrentProb: '',
            ecbiCurrentIntensity: '',
          },
          log: {
            updated: units ? true : false,
            lastUpdated: new Date(),
            lastUpdatedBy: 'ADMIN TRANSFER',
          },
        };
      });
      //update 
      //copy services from youth document to services document in new data format
      db.collection('services')
        .doc(newService.id)
        .set({
          uid: newService.id,
          shortName: services[key].serviceName,
          project: youthData.project || '',
          createdAt: new Date(),
          createdBy: 'ADMIN TRANSFER',
          youth: {
            uid: youthData.uid,
            firstName: youthData.firstName,
            lastName: youthData.lastName,
            DOB: youthData.DOB,
            race: youthData.race,
            bioSex: youthData.bioSex,
            gender: youthData.gender,
            primaryStateID: youthData.primaryStateID || '',
            stateIDs: {
              dc: {
                eCura: youthData.stateIDs.dc.eCura || '',
                iCams: youthData.stateIDs.dc.iCams || '',
              },
            },
          },
          provider: {
            uid: youthData.currentProviderID,
            name: youthData.currentProviderName,
          },
          staff: {
            label: services[key].staffLabel,
            name: services[key].staffName,
          },
          dates: {
            start: services[key].startDate,
            end: services[key].endDate,
          },
          unitType: services[key].unitType,
          totalUnits: totalUnits,
          referralSource: services[key].referralSource,
          dischargeType: services[key].dischargeType,
          modelStatic: {
            ecbiProbStart: services[key].modelSpecific?.ecbiInitProb || '',
            ecbiIntensityStart: services[key].modelSpecific?.ecbiInit || '',
            ecbiProbEnd: services[key].modelSpecific?.ecbiEndProb || '',
            ecbiIntensityEnd: services[key].modelSpecific?.ecbiEnd || '',
          },
          monthsList: monthsList,
          monthsNeedUpdated: monthsNeedUpdated,
          months: months,
        }).then(() => {
          console.log(`service ${newService.id} written successfully.`);
        }).catch(err => {
          console.error(`error writting service ${newService.id} for youth ${youthData.uid}`);
          console.error('Error:',err)
        });
    });
  });
  console.log(`${youthCount} youth.`);
  console.log(`${serviceCount} services.`)
};

// const createEmptyServicesList = async () => {
//   const youthSnapshot = await db.collection('youth').get();
//   youthSnapshot.forEach(youth => {
//     console.log(`creating empty servcesList for ${youth.id}`)
//     youth.ref.update({servicesList: []})
//   })
// }

// const popServicesList = async () => {
//   const servicesSnapshot = await db.collection('services').get();
//   servicesSnapshot.forEach(async (service) => {
//     const youthRef = db.collection('youth').doc(service.data().youth.uid);
//     const doc = await youthRef.get();
//     console.log(`${service.id} belongs to youth ${doc.id}`);
//     const updateServiceList = doc.data().servicesList;
//     updateServiceList.push(service.id)
//     console.log(`updateServiceList is ${updateServiceList}`)
//     await doc.ref.update({
//       servicesList: updateServiceList
//     })
//   })
// }

// const createOldYouth = () => {
//   for (let i = 0; i < 5; i++) {
//     const uid = db.collection('youth').doc();
//     youthDataModel.uid = uid.id;
//     uid.set(youthDataModel);
//     console.log(`added youth ${uid.id}`);
//   }
// };

const deleteTestServices = async () => {
  const testServices = await db
    .collection('services')
    .where('createdAt', '>', new Date('2021-02-18'))
    .get();
  testServices.forEach((service) => {
    console.log(`deleting service ${service.id}`);
    service.ref.delete();
  });
  const testYouth = await db
    .collection('youth')
    .where('createdAt', '>', new Date('2021-02-18'))
    .get();
  testYouth.forEach((youth) => {
    console.log(`deleting youth ${youth.id}...`);
    youth.ref.delete();
  });
};

const AddBunchServices = () => {
  console.log('CLICK!');
  for (let i=0;i<500;i++){
    const serviceRef = db.collection('services').doc();
    serviceRef.set(services).then(() => {
      console.log(`added service ${serviceRef.id}`);
    })
  }
}

const addEmployeeToStaff = async () => {
  const allStaff = await db.collection('staff').get();
  allStaff.forEach(staff => {
    console.log(`adding employee status for ${staff.id}...`);
    staff.ref.update({
      employee: true,
    });
  })
}

const addStaffIdToServices = async () => {
  const staffList = {}
  const services = await db.collection('services')
    //.where('dates.end','==','')  
    .get();
  //check all the current services for staff names to lookup
  services.forEach(service => {
    const staffName = service.data().staff.name
    if (!staffList[staffName]) {  
      staffList[staffName] = {uid: '', staffName}
    } 
  })
  //look up the staff and update with ids
  const staffPromises = [];
  Object.keys(staffList).forEach(async staffPerson => {
    const [firstName = '', lastName = ''] = staffPerson.split(' ');
    const staffRef = db.collection('staff')
      .where('firstName','==',firstName)
      .where('lastName','==',lastName)
      .get()
      .then(snapshot => {
        snapshot.docs.forEach(doc => {
          staffList[staffPerson].uid = doc.id
          staffList[staffPerson].label = doc.label
        })
      })
      staffPromises.push(staffRef);
  })
  console.log("staffPromises",staffPromises)
  return Promise.all(staffPromises).then(() => {
    //now update the services with the staff ids
    services.forEach(async (service) => {
      const staffName = service.data().staff.name;
      const staffId = staffList[staffName].uid;
      const staffLabel = staffList[staffName].label || '';
      await service.ref.update({
        staff: {
          uid: staffId,
          name: staffName,
          label: staffLabel,
        },
      });
    });
  });
  
}

const staffServiceCount = async () => {
  const staff = await db.collection('staff').get();
  staff.forEach(async (eachStaff) => {
    await eachStaff.ref.update({caseLoad: {}});
  })
  
  const allOpenServices = await db
    .collection('services')
    .where('dates.end', '==', '')
    .get()

  const serviceCounts = []
  allOpenServices.forEach(async (service) => {
    const staffRef = db.collection('staff').doc(service.data().staff.uid);
    const serviceCountPath = `caseLoad.${[service.data().shortName]}`;
    serviceCounts.push( staffRef.update({ [serviceCountPath]: firestore.FieldValue.increment(1)}) );
  })
  return Promise.all(serviceCounts)  
};

const staffCSV = async () => {
  const staffHeaderRow = 'Staff Database ID, Provider, Staff Name, FTE, Employee, Service, Case Count\n';
  let staffCSV = '';
  staffCSV += staffHeaderRow;

  staffServiceCount().then(results => {
    console.log('staffCSV called staffServiceCount, results: ',results);
  }).then(() => {
    return db.collection('staff').get();
  }).then(allStaff => {
    allStaff.forEach((staff) => {
      console.log('staff', staff.data());
      const staffName = staff.data().firstName + ' ' + staff.data().lastName;
      const { uid, providerName, caseLoad = {}, fullTime = true, employee = true } = staff.data();
      const providerNameNoComma = providerName.replace(/,/g, '');
  
      if (Object.keys(caseLoad).length === 0) {
        staffCSV += `${uid},${providerNameNoComma},${staffName},${fullTime ? 'Full-Time' : 'Part-Time'},${employee ? 'Employee' : 'Contractor'},NONE,NONE\n`;
      } else {
        Object.keys(caseLoad).forEach((service) => {
          const serviceCount = caseLoad[service];
          staffCSV += `${uid},${providerNameNoComma},${staffName},${fullTime ? 'Full-Time' : 'Part-Time'},${employee ? 'Employee' : 'Contractor'},${service},${serviceCount}\n`;
        });
      }
    });
  }).then(() => {
    const staffCSVFileName = updateMonthLabel() + '-Staff.csv';
    var staffBlob = new Blob([staffCSV], {
      type: 'text/plain;charset=utf-8',
    });
    saveAs(staffBlob, staffCSVFileName);
  });
  
}

const fixServicesProviderUid = async () => {
    const servicesWithProviderId = await db.collection('services')
        .orderBy('provider.id')
        .get();
    
    servicesWithProviderId.forEach(async (service) => {
        const providerInfo = service.data().provider;
        service.ref.update({provider: {
            ...providerInfo,
            uid: service.data().provider.id,
        }}).then(() => {
            console.log(`service ${service.id} updated successfully.`);
        }).catch(err => {
            console.error(`error updating service ${service.id}`);
            console.error('Error:',err)
        })
    });
}

const SuperAdmin = () => {
  useEffect(() => {
    console.log('useEffect placeholder');
  }, []);

  return (
    <>
      <Message info header='Admin Functions' content='For super admin only!' />
      {/* <Button primary onClick={createOldYouth} disabled>
        Create 5 youth with old data model
      </Button> */}
      <h3>Run these setup maintainence items first, one time</h3>
      <br />
      <br />
      <Button primary onClick={addEmployeeToStaff}>
        1: Add Employee=true to all staff
      </Button>
      <br />
      <br />
      <Button primary onClick={addStaffIdToServices}>
        2: Add staff id to services
      </Button>
      <br />
      <h3>Repeatable report generation once one-time setup is complete</h3>
      <br />
      <br />
      <Button primary onClick={staffCSV}>
        3: Staff Report
      </Button>
      <br />
      <br />
      <Button primary onClick={fixServicesProviderUid}>
        Fix for Services provider.uid
      </Button>
    </>
  );
};

export default SuperAdmin;
