import React from 'react';
import { Form, Grid } from 'semantic-ui-react';
import { monthShortLabel } from '../../util/functions';
import {anyUnitError} from '../../util/validators';

const UnitInputs = ({
  YYYYMM,
  service,
  setServices,
  allServiceErrors,
  unitError,
  serviceErrors,
  setServiceErrors,
  user,
}) => {
  return (
    <Grid.Column>
      <Form.Input
        label={monthShortLabel(YYYYMM)}
        type='number'
        name='units'
        min='0'
        step='1'
        value={service.months[YYYYMM].units}
        error={unitError}
        onChange={(e) => {
          const removeCurrentMonthFromUpdatesNeeded = service.monthsNeedUpdated.filter(
            (month) => month !== YYYYMM
          );
          setServices({
            ...service,
            monthsNeedUpdated: removeCurrentMonthFromUpdatesNeeded,
            months: {
              ...service.months,
              [YYYYMM]: {
                ...service.months[YYYYMM],
                units: e.target.value,
                log: {
                  updated: true,
                  lastUpdated: new Date(),
                  lastUpdatedBy: user.email,
                },
              },
            },
          });
          const clearError = {
            ...allServiceErrors,
            [service.uid]: {...serviceErrors},
          };
          delete clearError[service.uid][YYYYMM];
          setServiceErrors(clearError);
        }}
        onBlur={(e) => {
          if(anyUnitError(e.target.value)){
            setServiceErrors({
              ...allServiceErrors,
              [service.uid]:{
                ...allServiceErrors[service.uid],
                [YYYYMM]: true,
              }
            })
          }
        }}
      />
    </Grid.Column>
  );
};

export default UnitInputs;
