import { calcAge } from './functions'

const MAX_DECA_SCORE = 144

const validateYouth = (youthObj) => {
  const errors = {}
  if (youthObj.firstName === '') {
    errors.firstName = true
  }
  if (youthObj.lastName === '') {
    errors.lastName = true
  }
  if (youthObj.DOB === '' && youthObj.age === '') {
    errors.DOB = true
  }
  if (youthObj.age > 31 || youthObj.age < 0) {
    errors.age = true
  }
  if (youthObj.bioSex === '') {
    errors.bioSex = true
  }
  if (youthObj.gender === '') {
    errors.gender = true
  }
  if (youthObj.race === '') {
    errors.race = true
  }
  return errors
}

const startDateError = (date) => {
  let anyError = false
  //not blank
  if (date === '') {
    anyError = true
  }
  //not current month or later
  const currentMonth = new Date(date + 'T12:00:00Z')
  const today = new Date()
  currentMonth.setDate(1)
  currentMonth.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)
  today.setDate(1)
  if (currentMonth >= today) {
    anyError = true
  }
  //match YYYY-MM-DD
  const dateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
  if (!dateRegex.test(date)) {
    anyError = true
  }
  return anyError
}

const endDateError = (date) => {
  let anyError = false
  //same as start date but can be blank

  //not current month or later
  const currentMonth = new Date(date + 'T12:00:00Z')
  const today = new Date()
  currentMonth.setDate(1)
  currentMonth.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)
  today.setDate(1)
  if (currentMonth >= today) {
    anyError = true
  }
  //match YYYY-MM-DD
  const dateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
  if (!dateRegex.test(date) && date !== '') {
    anyError = true
  }
  return anyError
}

const anyUnitError = (u) => {
  let anyError = false
  const units = Number(u)
  if (units < 0) {
    anyError = true
  }
  if (!Number.isInteger(units)) {
    anyError = true
  }
  return anyError
}

const anyDecaError = (u) => {
  let anyError = false
  const units = Number(u)
  if (units < 0) {
    anyError = true
  }
  if (!Number.isInteger(units)) {
    anyError = true
  }
  if (units > MAX_DECA_SCORE) return anyError
}

const anyReqUnitError = (u) => {
  let anyError = false
  if (u === '') {
    anyError = true
  }
  const units = Number(u)
  if (units < 0) {
    anyError = true
  }
  if (!Number.isInteger(units)) {
    anyError = true
  }
  return anyError
}

const validateServices = (servicesObj, YYYYMM) => {
  const errors = {}
  Object.keys(servicesObj).forEach((service) => {
    errors[service] = {}
    const s = servicesObj[service]
    const startDate = new Date(s.dates.start + 'T12:00:00Z')
    const endDate = new Date(s.dates.end + 'T12:00:00Z')
    if (s.shortName === '') {
      errors[service].shortName = true
    }
    if (s.staff.name === '') {
      errors[service].staff = true
    }
    if (s.referralSource === '') {
      errors[service].referralSource = true
    }
    if (s.referralSource === 'Other' && s.referralSourceCustom === '') {
      errors[service].referralSourceCustom = true 
    }
    if (startDateError(s.dates.start)) {
      errors[service].startDate = true
    }
    if (endDateError(s.dates.end)) {
      errors[service].endDate = true
    }
    if (endDate < startDate) {
      errors[service].endDate = true
    }
    if (s.dischargeType === '' && s.dates.end !== '') {
      errors[service].dischargeType = true
    }
    if (s.dischargeType === 'Admin' && !s.dischargeNote){
        errors[service].dischargeNote = true
    }
    //validate units
    Object.keys(servicesObj[service].months).forEach((month) => {
      //errors[service][month] = {};
      const u = servicesObj[service].months[month].units
      if (anyUnitError(u)) {
        errors[service][month] = true
      }
    })
    //validate model specific
    //model
    const { model } = servicesObj[service].months[YYYYMM] || {}
  

    const {
      ecbiCurrentProb = '',
      ecbiCurrentIntensity = '',
    } = model || {}

    const {
      ecbiProbStart,
      ecbiIntensityStart,
      ecbiProbEnd,
      ecbiIntensityEnd,
      decaInitialAR = '',
      decaInitialIN = '',
      decaInitialSR = '',
      decaInitialBC = '',
      decaInitialTPF = '',
      decaMidpointAR = '',
      decaMidpointIN = '',
      decaMidpointSR = '',
      decaMidpointBC = '',
      decaMidpointTPF = '',
      decaDischargeAR = '',
      decaDischargeIN = '',
      decaDischargeSR = '',
      decaDischargeBC = '',
      decaDischargeTPF = '',
      decaInitialType = '',
      decaMidpointType = '',
      decaDischargeType = '',
    } = servicesObj[service].modelStatic

    const ecbiCurrentProbNum = Number(ecbiCurrentProb)
    if (
      (!Number.isInteger(ecbiCurrentProbNum) ||
        ecbiCurrentProbNum < 0 ||
        ecbiCurrentProbNum > 36) &&
      ecbiCurrentProb !== ''
    ) {
      errors[service].ecbiCurrentProb = true
    }
    const ecbiProbStartNum = Number(ecbiProbStart)
    if (
      (!Number.isInteger(ecbiProbStartNum) ||
        ecbiProbStartNum < 0 ||
        ecbiProbStartNum > 36) &&
      ecbiProbStart !== ''
    ) {
      errors[service].ecbiProbStart = true
    }
    const ecbiProbEndNum = Number(ecbiProbEnd)
    if (
      !(
        Number.isInteger(ecbiProbEndNum) ||
        ecbiProbEndNum < 0 ||
        ecbiProbEndNum > 36
      ) &&
      ecbiProbEnd !== ''
    ) {
      errors[service].ecbiProbEnd = true
    }
    const ecbiIntensityStartNum = Number(ecbiIntensityStart)
    if (
      (!Number.isInteger(ecbiIntensityStartNum) ||
        ecbiIntensityStartNum < 36 ||
        ecbiIntensityStartNum > 252) &&
      ecbiIntensityStart !== ''
    ) {
      errors[service].ecbiIntensityStart = true
    }
    const ecbiIntensityEndNum = Number(ecbiIntensityEnd)
    if (
      (!Number.isInteger(ecbiIntensityEndNum) ||
        ecbiIntensityEndNum < 36 ||
        ecbiIntensityEndNum > 252) &&
      ecbiIntensityEnd !== ''
    ) {
      errors[service].ecbiIntensityEnd = true
    }
    const ecbiCurrentIntensityNum = Number(ecbiCurrentIntensity)
    if (
      (!Number.isInteger(ecbiCurrentIntensityNum) ||
        ecbiCurrentIntensityNum < 36 ||
        ecbiCurrentIntensityNum > 252) &&
      ecbiCurrentIntensity !== ''
    ) {
      errors[service].ecbiCurrentIntensity = true
    }

    const outOfRange = (value) => {
      const asNumber = Number(value)
      return (
        !Number.isInteger(asNumber) || asNumber < 0 || asNumber > MAX_DECA_SCORE
      )
    }

    if (outOfRange(decaInitialAR)){
      errors[service].decaInitialAR = true;
    }

    if (outOfRange(decaInitialIN)){
      errors[service].decaInitialIN = true;
    }

    if (outOfRange(decaInitialSR)){
      errors[service].decaInitialSR = true;
    }

    if (outOfRange(decaInitialBC)){
      errors[service].decaInitialBC = true;
    }

    if (outOfRange(decaInitialTPF)){
      errors[service].decaInitialTPF = true;
    }

    if (outOfRange(decaMidpointAR)){
      errors[service].decaMidpointAR = true;
    }

    if (outOfRange(decaMidpointIN)){
      errors[service].decaMidpointIN = true;
    }

    if (outOfRange(decaMidpointSR)){
      errors[service].decaMidpointSR = true;
    }

    if (outOfRange(decaMidpointBC)){
      errors[service].decaMidpointBC = true;
    }

    if (outOfRange(decaMidpointTPF)){
      errors[service].decaMidpointTPF = true;
    }

    if (outOfRange(decaDischargeAR)){
      errors[service].decaDischargeAR = true;
    }

    if (outOfRange(decaDischargeIN)){
      errors[service].decaDischargeIN = true;
    }

    if (outOfRange(decaDischargeSR)){
      errors[service].decaDischargeSR = true;
    }

    if (outOfRange(decaDischargeBC)){
      errors[service].decaDischargeBC = true;
    }

    if (outOfRange(decaDischargeTPF)){
      errors[service].decaDischargeTPF = true;
    }

    const anyInitialDeca = decaInitialAR || decaInitialIN || decaInitialSR || decaInitialTPF || decaInitialBC
    const anyMidpointDeca = decaMidpointAR || decaMidpointIN || decaMidpointSR || decaMidpointTPF || decaMidpointBC
    const anyDischargeDeca = decaDischargeAR || decaDischargeIN || decaDischargeSR || decaDischargeTPF || decaDischargeBC

    if (anyInitialDeca && decaInitialType === '') {
      errors[service].decaInitialType = true
    }

    if (anyMidpointDeca && decaMidpointType === '') {
      errors[service].decaMidpointType = true
    }

    if (anyDischargeDeca && decaDischargeType === '') {
      errors[service].decaDischargeType = true
    }
    
  })
  return errors
}

const validateUpdateRow = (service, YYYYMM) => {
  const errors = {}
  const { units } = service.months[YYYYMM]
  //model
  const {
    ecbiCurrentProb,
    ecbiCurrentIntensity,
    arMid = '',
    inMid = '',
    srMid = '',
    decaTime = '',
    decaType = '',
    decaAR = '',
    decaIN = '',
    decaSR = '',
    decaBC = '',
    decaTPF = '',
  } = service.months[YYYYMM].model
  //from model specific
  const {
    ecbiProbStart,
    ecbiIntensityStart,
    ecbiProbEnd,
    ecbiIntensityEnd,
    arStart = '',
    arEnd = '',
    inStart = '',
    inEnd = '',
    srStart = '',
    srEnd = '',
  } = service.modelStatic
  if (anyReqUnitError(units)) {
    errors.units = true
  }
  if (service.dates.end !== '' && service.dischargeType === '') {
    errors.endDate = true
    errors.dischargeType = true
  }
  if (service.dates.end === '' && service.dischargeType !== '') {
    errors.endDate = true
    errors.dischargeType = true
  }
  if (service.dischargeType === 'Admin' && !service.dischargeNote){
    errors.dischargeNote = true
  }
  //match YYYY-MM-DD
  const date = service.dates.end
  const dateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
  if (!dateRegex.test(date) && date !== '') {
    errors.endDate = true
  }
  //model specific
  const ecbiCurrentProbNum = Number(ecbiCurrentProb)
  if (
    (!Number.isInteger(ecbiCurrentProbNum) ||
      ecbiCurrentProbNum < 0 ||
      ecbiCurrentProbNum > 36) &&
    ecbiCurrentProb !== ''
  ) {
    errors.ecbiCurrentProb = true
  }
  const ecbiProbStartNum = Number(ecbiProbStart)
  if (
    (!Number.isInteger(ecbiProbStartNum) ||
      ecbiProbStartNum < 0 ||
      ecbiProbStartNum > 36) &&
    ecbiProbStart !== ''
  ) {
    errors.ecbiProbStart = true
  }
  const ecbiProbEndNum = Number(ecbiProbEnd)
  if (
    !(
      Number.isInteger(ecbiProbEndNum) ||
      ecbiProbEndNum < 0 ||
      ecbiProbEndNum > 36
    ) &&
    ecbiProbEnd !== ''
  ) {
    errors.ecbiProbEnd = true
  }
  const ecbiIntensityStartNum = Number(ecbiIntensityStart)
  if (
    (!Number.isInteger(ecbiIntensityStartNum) ||
      ecbiIntensityStartNum < 36 ||
      ecbiIntensityStartNum > 252) &&
    ecbiIntensityStart !== ''
  ) {
    errors.ecbiIntensityStart = true
  }
  const ecbiIntensityEndNum = Number(ecbiIntensityEnd)
  if (
    (!Number.isInteger(ecbiIntensityEndNum) ||
      ecbiIntensityEndNum < 36 ||
      ecbiIntensityEndNum > 252) &&
    ecbiIntensityEnd !== ''
  ) {
    errors.ecbiIntensityEnd = true
  }
  const ecbiCurrentIntensityNum = Number(ecbiCurrentIntensity)
  if (
    (!Number.isInteger(ecbiCurrentIntensityNum) ||
      ecbiCurrentIntensityNum < 36 ||
      ecbiCurrentIntensityNum > 252) &&
    ecbiCurrentIntensity !== ''
  ) {
    errors.ecbiCurrentIntensity = true
  }

  const outOfRange = (value) => {
    const asNumber = Number(value)
    return (
      !Number.isInteger(asNumber) || asNumber < 0 || asNumber > MAX_DECA_SCORE
    )
  }
  if (outOfRange(decaAR)) {
    errors.decaAR = true
  }
  if (outOfRange(decaIN)) {
    errors.decaIN = true
  }
  if (outOfRange(decaSR)) {
    errors.decaSR = true
  }
  if (outOfRange(decaBC)) {
    errors.decaBC = true
  }
  if (outOfRange(decaTPF)) {
    errors.decaTPF = true
  }
  if (outOfRange(srMid)) {
    errors.srMid = true
  }
  if (outOfRange(arEnd)) {
    errors.arEnd = true
  }
  if (outOfRange(inEnd)) {
    errors.inEnd = true
  }
  if (outOfRange(srEnd)) {
    errors.srEnd = true
  }
  const anyDeca = decaAR || decaIN || decaSR || decaTPF || decaBC
  if (anyDeca && decaTime === '') {
    errors.decaTime = true
  }
  if (anyDeca && decaType === '') {
    errors.decaType = true
  }

  return errors
}

export {
  validateYouth,
  validateServices,
  startDateError,
  endDateError,
  anyUnitError,
  anyReqUnitError,
  validateUpdateRow,
  anyDecaError,
  MAX_DECA_SCORE,
}
