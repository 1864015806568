import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Divider, Header, Icon, Label } from 'semantic-ui-react'
import CPP from '../ModelSpecificInputs/CPP'
import PCIT from '../ModelSpecificInputs/PCIT'
import DECA from '../ModelSpecificInputs/DECA'

const ModelSpecific = ({
  service,
  setService,
  serviceErrors,
  YYYYMM,
  linkToYouthDetail,
}) => {
  let modelComponent = null

  if (linkToYouthDetail) {
    const youthDetailLink = (
      <Button as={Link} to={'/youth/' + service.youth.uid} icon basic>
        <Icon name='edit outline' color='blue' />
        Enter DECA Measures on Youth Detail Page.
      </Button>
    )
    
    return service.shortName === 'CPP'
      ? [(
          <>
            <CPP service={service} setService={setService} YYYYMM={YYYYMM} />
            <br />
          </>
        ), youthDetailLink]
      : youthDetailLink
  }

  switch (service.shortName) {
    case 'CPP':
      modelComponent = (
        <>
          <Header as="h4" dividing>CPP Measures (last month):</Header>
          <CPP service={service} setService={setService} YYYYMM={YYYYMM} />
          <Divider/>
          <Header as="h4" dividing>DECA Measures:</Header>
          <DECA
          service={service}
          setService={setService}
          serviceErrors={serviceErrors}
          YYYYMM={YYYYMM}
          />
        </>
      )
      break
    case 'PCIT':
      modelComponent = (
        <PCIT
          service={service}
          setService={setService}
          serviceErrors={serviceErrors}
          YYYYMM={YYYYMM}
        />
      )
      break
    case 'PCIT-T':
      modelComponent = (
        <DECA
          service={service}
          setService={setService}
          serviceErrors={serviceErrors}
          YYYYMM={YYYYMM}
        />
      )
      break
    default:
      modelComponent = <Label size='large'>None</Label>
      break
  }

  return <>{modelComponent}</>
}

export default ModelSpecific
