//TODO: Error handling

import React, { useState } from 'react'
import { db } from '../../config/fireInit'
import { Button, Form, Header, Icon, Table } from 'semantic-ui-react'
import ModelSpecific from './ModelSpecific'

import { dischargeSelect } from '../../util/selects'
import { validateUpdateRow } from '../../util/validators'
import { Link } from 'react-router-dom'

const UpdateRow = ({
  service = {},
  YYYYMM,
  staffList,
  setshowReminders,
  user,
}) => {
  const monthFieldPath = 'months.' + YYYYMM
  const [highlight, setHighlight] = useState(false)
  const [serviceUpdate, setServiceUpdate] = useState({ ...service })
  const [serviceErrors, setServiceErrors] = useState({})
  if (!service.months[YYYYMM]) {
    console.log('service:', service)
    return null
  }
  const { updated } = service.months[YYYYMM].log

  const submitWithTimeout = () => {
    setHighlight(true)
    setTimeout(handleSubmit, 300)
  }

  const handleSubmit = () => {
    setHighlight(false)
    //clear errors
    setServiceErrors({})
    //validate
    const rowErrors = validateUpdateRow(serviceUpdate, YYYYMM)
    if (Object.keys(rowErrors).length > 0) {
      setServiceErrors({ ...rowErrors })
      setshowReminders(true)
      return
    }
    const removeCurrentMonthFromUpdatesNeeded = service.monthsNeedUpdated.filter(
      (month) => month !== YYYYMM
    )
    db.collection('services')
      .doc(service.uid)
      .update({
        'dates.end': serviceUpdate.dates.end,
        dischargeType: serviceUpdate.dischargeType,
        dischargeNote: serviceUpdate.dischargeNote,
        'staff.name': serviceUpdate.staff.name,
        'staff.uid': serviceUpdate.staff.uid,
        monthsNeedUpdated: removeCurrentMonthFromUpdatesNeeded,
        modelStatic: {
          ecbiIntensityStart: serviceUpdate.modelStatic.ecbiIntensityStart,
          ecbiProbStart: serviceUpdate.modelStatic.ecbiProbStart,
          ecbiProbEnd: serviceUpdate.modelStatic.ecbiProbEnd,
          ecbiIntensityEnd: serviceUpdate.modelStatic.ecbiIntensityEnd,
          arStart: serviceUpdate.modelStatic.arStart ?? '',
          arEnd: serviceUpdate.modelStatic.arEnd ?? '',
          inStart: serviceUpdate.modelStatic.inStart ?? '',
          inEnd: serviceUpdate.modelStatic.inEnd ?? '',
          srStart: serviceUpdate.modelStatic.srStart ?? '',
          srEnd: serviceUpdate.modelStatic.srEnd ?? '',
          decaInitialAR: serviceUpdate.modelStatic.decaInitialAR ?? '',
          decaInitialIN: serviceUpdate.modelStatic.decaInitialIN ?? '',
          decaInitialSR: serviceUpdate.modelStatic.decaInitialSR ?? '',
          decaInitialBC: serviceUpdate.modelStatic.decaInitialBC ?? '',
          decaInitialTPF: serviceUpdate.modelStatic.decaInitialTPF ?? '',
          decaMidpointAR: serviceUpdate.modelStatic.decaMidpointAR ?? '',
          decaMidpointIN: serviceUpdate.modelStatic.decaMidpointIN ?? '',
          decaMidpointSR: serviceUpdate.modelStatic.decaMidpointSR ?? '',
          decaMidpointBC: serviceUpdate.modelStatic.decaMidpointBC ?? '',
          decaMidpointTPF: serviceUpdate.modelStatic.decaMidpointTPF ?? '',
          decaDischargeAR: serviceUpdate.modelStatic.decaDischargeAR ?? '',
          decaDischargeIN: serviceUpdate.modelStatic.decaDischargeIN ?? '',
          decaDischargeSR: serviceUpdate.modelStatic.decaDischargeSR ?? '',
          decaDischargeBC: serviceUpdate.modelStatic.decaDischargeBC ?? '',
          decaDischargeTPF: serviceUpdate.modelStatic.decaDischargeTPF ?? '',
          decaInitialType: serviceUpdate.modelStatic.decaInitialType ?? '',
          decaMidpointType: serviceUpdate.modelStatic.decaMidpointType ?? '',
          decaDischargeType: serviceUpdate.modelStatic.decaDischargeType ?? '',
        },
        [monthFieldPath]: {
          ...serviceUpdate.months[YYYYMM],
          units: serviceUpdate.months[YYYYMM].units,
          model: serviceUpdate.months[YYYYMM].model,
          log: {
            updated: true,
            lastUpdated: new Date(),
            lastUpdatedBy: user.email,
          },
        },
      })
      .then(() => {
        //
      })
      .catch((err) => console.log('error:', err))
  }

  return (
    <>
      <Table.Cell
        warning={highlight}
        positive={updated}
        negative={Object.keys(serviceErrors).length > 0}
      >
        <Header sub>
          {service.youth.lastName + ', ' + service.youth.firstName}
        </Header>
        <span>{service.youth.primaryStateID}</span>
      </Table.Cell>
      <Table.Cell
        warning={highlight}
        positive={updated}
        negative={Object.keys(serviceErrors).length > 0}
      >
        {service.shortName}
      </Table.Cell>
      <Table.Cell
        warning={highlight}
        positive={updated}
        negative={Object.keys(serviceErrors).length > 0}
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Select
            label={service.staff.label}
            name='staffName'
            options={staffList.map((staff, index) => {
              return {
                key: index,
                text: staff.lastName + ', ' + staff.firstName,
                value: staff.uid,
              }
            })}
            value={serviceUpdate.staff.uid}
            required
            onChange={(e, data) => {
              const { uid, firstName, lastName, position } = staffList.find(
                (staff) => staff.uid === data.value
              )

              setServiceUpdate({
                ...serviceUpdate,
                staff: {
                  ...service.staff,
                  name: firstName + ' ' + lastName,
                  uid: uid,
                },
              })
            }}
          />
        </Form>
      </Table.Cell>
      <Table.Cell
        warning={highlight}
        positive={updated}
        negative={Object.keys(serviceErrors).length > 0}
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Input
            label={service.unitType}
            name='units'
            type='number'
            min='0'
            step='1'
            required
            value={serviceUpdate.months[YYYYMM].units}
            error={serviceErrors.units}
            onChange={(e) =>
              setServiceUpdate({
                ...serviceUpdate,
                months: {
                  ...serviceUpdate.months,
                  [YYYYMM]: {
                    ...serviceUpdate.months[YYYYMM],
                    units: e.target.value,
                  },
                },
              })
            }
          />
        </Form>
      </Table.Cell>
      <Table.Cell
        warning={highlight}
        positive={updated}
        negative={Object.keys(serviceErrors).length > 0}
      >
        <ModelSpecific
          service={serviceUpdate}
          setService={setServiceUpdate}
          serviceErrors={serviceErrors}
          YYYYMM={YYYYMM}
          linkToYouthDetail={['PCIT-T', 'CPP'].includes(serviceUpdate.shortName)}
        />
      </Table.Cell>
      <Table.Cell
        warning={highlight}
        positive={updated}
        negative={Object.keys(serviceErrors).length > 0}
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <Form.Input
            label='End Date'
            name='endDate'
            type='date'
            value={serviceUpdate.dates.end}
            error={serviceErrors.endDate}
            onChange={(e) =>
              setServiceUpdate({
                ...serviceUpdate,
                dates: {
                  ...serviceUpdate.dates,
                  end: e.target.value,
                },
              })
            }
          />
          <Form.Select
            label='Discharge Type'
            name='dischargeType'
            options={dischargeSelect}
            value={serviceUpdate.dischargeType}
            error={serviceErrors.dischargeType}
            onChange={(e, data) =>
              setServiceUpdate({ ...serviceUpdate, dischargeType: data.value })
            }
          />
          {serviceUpdate.dischargeType === 'Admin' && (
            <Form.TextArea
            label='Discharge Note'
            name='dischargeNote'
            type='text'
            value={serviceUpdate.dischargeNote}
            error={serviceErrors.dischargeNote}
            onChange={(e) =>
              setServiceUpdate({
                    ...serviceUpdate,
                    dischargeNote: e.target.value,
              })
            }
          />)}
        </Form>
      </Table.Cell>
      <Table.Cell
        warning={highlight}
        positive={updated}
        negative={Object.keys(serviceErrors).length > 0}
      >
        <p>
          <Button onClick={submitWithTimeout} icon basic color='blue'>
            <Icon name='check circle' color='blue' />
          </Button>
        </p>
        <p>
          <Button as={Link} to={'/youth/' + service.youth.uid} icon basic>
            <Icon name='edit outline' color='blue' />
          </Button>
        </p>
      </Table.Cell>
    </>
  )
}

export default UpdateRow
