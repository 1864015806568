import React, { Fragment } from "react";
import { Button, Table, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ApprovalButton from './ApprovalButton';
import ProviderSelect from '../EBAdmin/ProviderSelect';
import { ConfirmDeleteUser } from "./ConfirmDeleteUser";

const UserRow = props => {
  const { displayUser, user, providers } = props;
  return (
    <Fragment>
      <Table.Cell>{displayUser.displayName}</Table.Cell>
      <Table.Cell>{displayUser.email}</Table.Cell>
      <Table.Cell>{displayUser.createdAt?.toDate().toLocaleDateString()}</Table.Cell>
      <Table.Cell>{displayUser.uid}</Table.Cell>
      <Table.Cell>
        <ProviderSelect 
          providerName={displayUser.providerName}
          providerID={displayUser.providerID} 
          uid={displayUser.uid}
          providers={providers}/>
      </Table.Cell>
      <Table.Cell>
        <ApprovalButton displayUser={displayUser} user={user}/>
      </Table.Cell>
      <Table.Cell>
        <p>
        <Button as={Link} to={"/EBAdmin/users/" + displayUser.uid} basic disabled={displayUser.uid === user.uid}>
          <Icon name='right chevron' />
        </Button>
        </p>
        <p>
        <ConfirmDeleteUser displayUser={displayUser} user={user}/>
        </p>
      </Table.Cell>
    </Fragment>
  );
};

export default UserRow;
