//TODO:
//Consider removing services array from youthObj; no value in putting it on that document since it doesn't have any query-able info

import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Popup,
} from 'semantic-ui-react'
import { db } from '../../config/fireInit'
import Services from './Services'
import Service from '../../models/Service'
import ProviderSelect from '../EBAdmin/ProviderSelect'

import {
  updateMonthYYYYMM,
  firstDayOfLastMonth,
  calcAge,
  calcLOS,
} from '../../util/functions'

import { bioSexSelect, genderSelect, raceSelect } from '../../util/selects'

import { validateYouth, validateServices } from '../../util/validators'
import { ConfirmDeleteModal } from './ConfirmDeleteModal'

export const displayAge = (dob) => {
  const today = dayjs()
  return today.diff(dob, 'year')
}
const DuplicateWarningMsg = () => {
    return <Message icon error visible>
          <Icon name='warning sign' />
          <Message.Content>
            <Message.Header>Possible Duplicate Youth</Message.Header>A
            youth with the same name and date of birth has already been added in your organization.
            An EBA admin will need to add this youth for you.
          </Message.Content>
        </Message>
}

const CreateUpdateYouth = ({ user }) => {
  //~~~~~~~~~~~~~~
  //Declare Component Constants
  //~~~~~~~~~~~~~~
  const YYYYMM = updateMonthYYYYMM()
  const newYouthID = db.collection('services').doc().id

  //~~~~~~~~~~~~~~
  //State Management
  //~~~~~~~~~~~~~~
  const [formType, setFormType] = useState('')
  const [providerStaffList, setProviderStaffList] = useState([])
  const [submitMessages, setSubmitMessages] = useState({
    successMessage: '',
    errorMessage: '',
  })
  const [youthErrors, setYouthErrors] = useState({})
  const [serviceErrors, setServiceErrors] = useState({})
  const [services, setServices] = useState({})
  const [duplicateWarn, setDuplicateWarn] = useState(false)
  const [youthObj, setYouthInfo] = useState({
    firstName: '',
    lastName: '',
    DOB: '',
    age: '',
    bioSex: '',
    gender: '',
    race: '',
    stateIDs: {
      dc: {
        iCams: '',
        eCura: '',
      },
    },
    createdAt: new Date(),
    createdBy: user.email,
    lastUpdated: new Date(),
    lastUpdatedBy: user.email,
    currentProviderName: user.providerName,
    currentProviderID: user.providerID,
    uid: newYouthID,
  })

  //~~~~~~~~~~~~~~
  //Use Effect 1: get provider staff and generate first service.
  //~~~~~~~~~~~~~~
  useEffect(() => {
    //async data calls to be called later
    const getProviderStaff = async () => {
      const providerSnapshot = await db
        .collection('staff')
        .where('providerID', '==', user.providerID)
        .where('endDate', '==', '')
        .orderBy('lastName')
        .get()
      const staff = []
      providerSnapshot.forEach((doc) => {
        staff.push(doc.data())
      })
      setProviderStaffList(staff)
    }
    const getYouthAndServices = async (youthID) => {
      const youthRef = db.collection('youth').doc(youthID)
      const doc = await youthRef.get()
      setYouthInfo(doc.data())
      const servicesRef = db
        .collection('services')
        .where('youth.uid', '==', youthID)
        .where('provider.name', '==', user.providerName)
      const youthServices = await servicesRef.get()
      let servicesObj = {}
      let serviceErrorsObj = {}
      youthServices.forEach((service) => {
        const serviceData = service.data()
        servicesObj = {
          ...servicesObj,
          [serviceData.uid]: new Service(serviceData),
        }
        serviceErrorsObj = {
          ...serviceErrorsObj,
          [serviceData.uid]: {
            [YYYYMM]: false,
          },
        }
      })
      setServiceErrors({ ...serviceErrorsObj })
      setServices({ ...servicesObj })
    }

    //useEffect starts here.
    getProviderStaff()
    //new or edit form based on path
    const locPath = window.location.pathname.split('/')
    const youthID = locPath[locPath.length - 1]
    //if add new form...
    if (youthID === 'youth') {
      setFormType('ADD')
      addServiceToForm()
    } else {
      window.scrollTo(0, 0)
      setFormType('EDIT')
      getYouthAndServices(youthID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.providerID])

  //~~~~~~~~~~~~~~
  //Use Effect 2: check for duplicate youth
  //~~~~~~~~~~~~~~
  useEffect(() => {
    //async data call
    const checkForExistingYouth = async () => {
      const youthQuery = await db
        .collection('youth')
        .where('currentProviderID', '==', user.providerID)
        .where('firstName', '==', youthObj.firstName)
        .where('lastName', '==', youthObj.lastName)
        .where('DOB', '==', youthObj.DOB)
        .limit(1)
        .get()
      setDuplicateWarn(!youthQuery.empty)
    }
    if (
      youthObj.firstName &&
      youthObj.lastName &&
      youthObj.DOB &&
      formType === 'ADD'
    ) {
      checkForExistingYouth()
    }
  }, [
    youthObj.firstName,
    youthObj.lastName,
    youthObj.DOB,
    user.providerID,
    formType,
  ])

  //~~~~~~~~~~~~~~
  //From handlers
  //~~~~~~~~~~~~~~
  const handleSubmit = (e) => {
    e.preventDefault()
    //clear submit messages
    setSubmitMessages({
      successMessage: '',
      errorMessage: '',
    })

    //validate everything
    const youthErrorMessages = validateYouth(youthObj)
    if (duplicateWarn && !user.roles.EBAdmin) { 
        youthErrorMessages.duplicate = true
    }
    const youthErrorCount = Object.keys(youthErrorMessages).length
    const serviceErrorMessages = validateServices(services, YYYYMM)
    let serviceErrorCount = 0
    Object.keys(serviceErrorMessages).forEach((service) => {
      Object.keys(serviceErrorMessages[service]).forEach((err) => {
        serviceErrorCount++
      })
    })

    if (youthErrorCount > 0 || serviceErrorCount > 0) {
      console.log('errors:', youthErrorMessages)
      console.log('service errors', serviceErrorMessages)
      setYouthErrors(youthErrorMessages)
      setServiceErrors(serviceErrorMessages)
      setSubmitMessages({
        successMessage: '',
        errorMessage:
          'There is invalid or missing information on the form. Please check your entries and try again.',
      })
      return
    }

    //batch all db calls so reset from can follow.
    const batch = db.batch()

    //add youth
    const youthRef = db.collection('youth').doc(youthObj.uid)
    console.log(`youth ${youthObj.uid} set to batch...`)
    batch.set(youthRef, youthObj)

    //add services
    Object.keys(services).forEach((service) => {
      services[service].youth = { ...youthObj }
      console.log(`service ${service} set to batch...`)
      var serviceRef = db.collection('services').doc(service)
      batch.set(serviceRef, services[service])
    })

    //committ all.
    batch
      .commit()
      .then(() => {
        const successMessage =
          formType === 'ADD'
            ? 'Youth added. You may continue adding more youth on this form.'
            : 'Youth updated.'
        setSubmitMessages({
          successMessage: successMessage,
          errorMessage: '',
        })
        console.log('reseting form...')
        if (formType === 'ADD') {
          resetForm()
        }
      })
      .catch((err) => {
        setSubmitMessages({
          errorMessage: err.message,
          successMessage: '',
        })
      })
  }

  const resetForm = () => {
    const newYouthID = db.collection('services').doc().id
    const newServiceID = db.collection('services').doc().id
    setServiceErrors({
      ...serviceErrors, //will have old error state
      [newServiceID]: {
        [YYYYMM]: false,
      },
    })

    //const clearYouthObj =

    setYouthInfo({
      firstName: '',
      lastName: '',
      DOB: '',
      age: '',
      bioSex: '',
      gender: '',
      race: '',
      stateIDs: {
        dc: {
          iCams: '',
          eCura: '',
        },
      },
      createdAt: new Date(),
      createdBy: user.email,
      lastUpdated: new Date(),
      lastUpdatedBy: user.email,
      currentProviderName: user.providerName,
      currentProviderID: user.providerID,
      uid: newYouthID,
    })

    setServices({
      [newServiceID]: new Service({
        uid: newServiceID,
        createdBy: user.email,
        provider: {
          name: user.providerName,
          uid: user.providerID,
        }
      }),
    })
    setYouthErrors({})
  }

  const addServiceToForm = () => {
    console.log("addServiceToForm called...")
    const newServiceID = db.collection('services').doc().id
    const newService = new Service({
      uid: newServiceID,
      createdBy: user.email,
      provider: {
        name: user.providerName,
        uid: user.providerID,
      }
    })
    setServices({
      ...services,
      [newServiceID]: newService,
    })
    // setYouthInfo({
    //   ...youthObj,
    //   services: [...youthObj.services, newServiceID],
    // });
    setServiceErrors({
      ...serviceErrors,
      [newServiceID]: {
        [YYYYMM]: false,
      },
    })
  }

  const handleGenderSelect = (e, data) => {
    if (data.value !== 'Male' && data.value !== 'Female') {
      genderSelect.push({
        key: String(genderSelect.length + 1),
        text: data.value,
        value: data.value,
      })
    }
    setYouthInfo({ ...youthObj, gender: data.value })
    const clearError = { ...youthErrors }
    delete clearError.gender
    setYouthErrors(clearError)
  }

  //~~~~~~~~~~~~~~
  //UI
  //~~~~~~~~~~~~~~
  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
      <Header as='h1'>
        {formType === 'ADD' ? 'Add New Youth:' : 'Edit Youth:'}
      </Header>
      {user.roles.EBAdmin && (
        <>
          <Header as='h2' dividing>
            Provider Information
          </Header>
          <ProviderSelect
            providerName={user.providerName}
            providerID={user.providerID}
            uid={user.uid}
            //providers={providers}
          />
        </>
      )}
      <Header as='h2' dividing>
        Basic Information
      </Header>
      {duplicateWarn && <DuplicateWarningMsg />}
      <Form.Group>
        <Form.Field width='twelve' required error={youthErrors.firstName}>
          <label>First Name</label>
          <input
            name='firstName'
            type='text'
            placeholder='First name or initial'
            value={youthObj.firstName}
            autoComplete='off'
            onChange={(e) => {
              setYouthInfo({ ...youthObj, firstName: e.target.value })
              const clearError = { ...youthErrors }
              delete clearError.firstName
              setYouthErrors(clearError)
            }}
          />
        </Form.Field>
        <Form.Field width='twelve' required error={youthErrors.lastName}>
          <label>Last Name</label>
          <input
            name='lastName'
            type='text'
            placeholder='Last name or initial'
            value={youthObj.lastName}
            autoComplete='off'
            onChange={(e) => {
              setYouthInfo({ ...youthObj, lastName: e.target.value })
              const clearError = { ...youthErrors }
              delete clearError.lastName
              setYouthErrors(clearError)
            }}
          />
        </Form.Field>
      </Form.Group>
      {(youthErrors.firstName || youthErrors.lastName) && (
        <Message negative>
          <p>
            The first name and last name fields are required. Intials are
            acceptable.
          </p>
        </Message>
      )}
      <Form.Group>
        <Form.Field
          width='three'
          error={youthErrors.DOBAgeBlank || youthErrors.age}
        >
          <label>Date of Birth</label>
          <input
            name='DOB'
            type='date'
            placeholder='YYYY-MM-DD'
            maxLength='10'
            max='9999-12-31'
            value={youthObj.DOB}
            onChange={(e) => {
              setYouthInfo({ ...youthObj, DOB: e.target.value })
              const clearError = { ...youthErrors }
              delete clearError.DOB
              delete clearError.age
              setYouthErrors(clearError)
            }}
            onBlur={(e) => {
              const age = calcAge(e.target.value)
              setYouthInfo({ ...youthObj, age: age })
              if (age > 31 || age < 0) {
                setYouthErrors({
                  ...youthErrors,
                  age: true,
                })
              }
            }}
          />
        </Form.Field>
        <Form.Field
          width='five'
          error={youthErrors.DOBAgeBlank || youthErrors.age}
        >
          <label>
            Age{' '}
            <Popup
              content='Age will calculated based on date of birth. NOTE: If you change the age, it will clear the DOB.'
              trigger={
                <Icon circular inverted color='blue' size='small' name='info' />
              }
            />
          </label>
          <input
            type='number'
            name='age'
            placeholder='Age will calculate from DOB'
            maxLength='2'
            step='1'
            value={displayAge(youthObj.DOB) || youthObj.age}
            onChange={(e) => {
              setYouthInfo({ ...youthObj, age: e.target.value, DOB: '' })
              const clearError = { ...youthErrors }
              delete clearError.age
              delete clearError.DOB
              setYouthErrors(clearError)
            }}
            onBlur={(e) => {
              if (e.target.value > 31 || e.target.value < 0) {
                setYouthErrors({
                  ...youthErrors,
                  age: true,
                })
              }
            }}
          />
        </Form.Field>
      </Form.Group>
      {(youthErrors.DOBAgeBlank || youthErrors.age) && (
        <Message negative>
          {youthErrors.DOBAgeBlank && (
            <p>
              Either a date of birth or an age must be entered. They cannot both
              be blank.
            </p>
          )}
          {youthErrors.age && (
            <p>
              The birth year or the age appears to be incorrect (youth is too
              old or birth date is in the future).
            </p>
          )}
        </Message>
      )}
      <Form.Select
        label='Biological Sex'
        placeholder='Select...'
        name='bioSex'
        options={bioSexSelect}
        value={youthObj.bioSex}
        onChange={(e, data) => {
          setYouthInfo({ ...youthObj, bioSex: data.value })
          const clearError = { ...youthErrors }
          delete clearError.bioSex
          setYouthErrors(clearError)
        }}
        required
        error={youthErrors.bioSex}
      />
      <Form.Select
        label='Gender'
        placeholder='Select (or begin typing for other)'
        name='gender'
        options={genderSelect}
        allowAdditions
        search
        value={youthObj.gender}
        onChange={handleGenderSelect}
        required
        error={youthErrors.gender}
      />
      <Form.Select
        label='Race'
        name='race'
        placeholder='Select...'
        options={raceSelect}
        value={youthObj.race}
        onChange={(e, data) => {
          setYouthInfo({ ...youthObj, race: data.value })
          const clearError = { ...youthErrors }
          delete clearError.race
          setYouthErrors(clearError)
        }}
        required
        error={youthErrors.race}
      />
      <Header as='h2' dividing>
        <Header.Content>
          State Specific
          <Header.Subheader>District of Columbia</Header.Subheader>
        </Header.Content>
      </Header>
      <Header as='h3'>Identification Numbers</Header>
      <Form.Group>
        <Form.Field width='four'>
          <label>iCams</label>
          <input
            name='iCams'
            type='text'
            value={youthObj.stateIDs.dc.iCams}
            onChange={(e) =>
              setYouthInfo({
                ...youthObj,
                stateIDs: {
                  ...youthObj.stateIDs,
                  dc: {
                    ...youthObj.stateIDs.dc,
                    iCams: e.target.value,
                  },
                },
              })
            }
          />
        </Form.Field>
        <Form.Field width='four'>
          <label>eCura</label>
          <input
            name='eCura'
            type='text'
            value={youthObj.stateIDs.dc.eCura}
            onChange={(e) =>
              setYouthInfo({
                ...youthObj,
                stateIDs: {
                  ...youthObj.stateIDs,
                  dc: {
                    ...youthObj.stateIDs.dc,
                    eCura: e.target.value,
                  },
                },
              })
            }
          />
        </Form.Field>
      </Form.Group>
      <Header as='h2' dividing>
        Services
      </Header>
      {Object.keys(services).length > 0 &&
        Object.keys(services).map((serviceKey, i) => (
          <Services
            key={serviceKey}
            serviceCount={i} //for segment label
            allServices={services} //needed to copy existing state
            service={services[serviceKey]} //for this service infor only
            setServices={setServices} //update on this comp for db management
            allServiceErrors={serviceErrors} //to copy existing state during updates
            serviceErrors={serviceErrors[serviceKey]} //just this service
            setServiceErrors={setServiceErrors} //update on this comp for db management
            staffList={providerStaffList} //for dropdown
            user={user} //for data entries on units
          />
        ))}
      {submitMessages.successMessage && (
        <Message positive>
          <Message.Header>Success</Message.Header>
          {submitMessages.successMessage}
        </Message>
      )}
      {submitMessages.errorMessage && duplicateWarn && !user.roles.EBAdmin && <DuplicateWarningMsg />}
      {submitMessages.errorMessage && (
        <Message negative>
          <Message.Header>Error</Message.Header>
          {submitMessages.errorMessage}
        </Message>
      )}
      <Grid columns={2} padded>
        <Grid.Row>
          <Grid.Column width={8}>
            <Button type='button' onClick={addServiceToForm}>
              Add Another Service For This Youth
            </Button>
          </Grid.Column>
          <Grid.Column width={8}>
            <Button primary floated='right' type='submit'>
              {formType === 'ADD' ? 'Add Youth' : 'Save Edits'}
            </Button>
          </Grid.Column>
        </Grid.Row>
        {formType === 'EDIT' && user.roles.EBAdmin && (
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <ConfirmDeleteModal
                youthobj={youthObj}
                services={services}
                resetForm={resetForm}
                setSubmitMessages={setSubmitMessages}
                setFormType={setFormType}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Form>
  )
}

export default CreateUpdateYouth
