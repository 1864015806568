import React from 'react';
import { Form, Label, Message, Table } from 'semantic-ui-react';

const ecbiDiff = (start, current, end) => {
  if (end !== '') {
    return Number(start) - Number(end);
  } else if (current !== '') {
    return Number(start) - Number(current);
  } else {
    return 'N/A';
  }
};

const PCIT = ({ service, setService, serviceErrors, YYYYMM }) => {
  //if month exists, use it, otherwise set to blank
  const { model } = service.months[YYYYMM] || {};

  const ecbiCurrentProb = model?.ecbiCurrentProb ? model.ecbiCurrentProb : '';
  const ecbiCurrentIntensity = model?.ecbiCurrentIntensity ? model.ecbiCurrentIntensity : '';
  const ecbiProbStart = model?.ecbiProbStart ? model.ecbiProbStart : '';
  const ecbiIntensityStart = model?.ecbiIntensityStart ? model.ecbiIntensityStart : '';
  const ecbiProbEnd = model?.ecbiProbEnd ? model.ecbiProbEnd : '';
  const ecbiIntensityEnd = model?.ecbiIntensityEnd ? model.ecbiIntensityEnd : '';

  const ecbiFields = [
    'ecbiCurrentProb',
    'ecbiCurrentIntensity',
    'ecbiProbStart',
    'ecbiIntensityStart',
    'ecbiProbEnd',
    'ecbiIntensityEnd',
  ];
  const ecbiError = Object.keys(serviceErrors).some(
    (i) => ecbiFields.indexOf(i) > 0
  );

  return (
    <>
      <Label color='blue' basic>
        ECBI Youth RAW Scores
      </Label>
      {ecbiError && (
        <Message negative>
          <p>Problem Scores must be between 0 and 36.</p>
          <p>Intensity Scores must be between 36 and 252.</p>
        </Message>
      )}
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Problem</Table.HeaderCell>
            <Table.HeaderCell>Intensity</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Form.Input
                label='Initial'
                name='ecbiProbStart'
                type='number'
                min='0'
                max='36'
                step='1'
                value={service.modelStatic.ecbiProbStart}
                error={serviceErrors.ecbiProbStart}
                onChange={(e) =>
                  setService({
                    ...service,
                    modelStatic: {
                      ...service.modelStatic,
                      ecbiProbStart: e.target.value,
                    },
                  })
                }
              />
            </Table.Cell>
            <Table.Cell>
              <Form.Input
                label='Initial'
                name='ecbiIntesnityStart'
                type='number'
                min='36'
                max='252'
                step='1'
                value={service.modelStatic.ecbiIntensityStart}
                error={serviceErrors.ecbiIntensityStart}
                onChange={(e) =>
                  setService({
                    ...service,
                    modelStatic: {
                      ...service.modelStatic,
                      ecbiIntensityStart: e.target.value,
                    },
                  })
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Form.Input
                label='PDI'
                name='ecbiCurrentProb'
                type='number'
                min='0'
                max='36'
                step='1'
                value={ecbiCurrentProb}
                error={serviceErrors.ecbiCurrentProb}
                onChange={(e) =>
                  setService({
                    ...service,
                    months: {
                      ...service.months,
                      [YYYYMM]: {
                        ...service.months[YYYYMM],
                        model: {
                          ...model,
                          ecbiCurrentProb: e.target.value,
                        },
                      },
                    },
                  })
                }
              />
            </Table.Cell>
            <Table.Cell>
              <Form.Input
                label='PDI'
                name='ecbiCurrentIntensity'
                type='number'
                min='36'
                max='252'
                step='1'
                value={ecbiCurrentIntensity}
                error={serviceErrors.ecbiCurrentIntensity}
                onChange={(e) =>
                  setService({
                    ...service,
                    months: {
                      ...service.months,
                      [YYYYMM]: {
                        ...service.months[YYYYMM],
                        model: {
                          ...model,
                          ecbiCurrentIntensity: e.target.value,
                        },
                      },
                    },
                  })
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Form.Input
                label='Discharge'
                name='ecbiProbEnd'
                type='number'
                min='0'
                max='36'
                step='1'
                value={service.modelStatic.ecbiProbEnd}
                error={serviceErrors.ecbiProbEnd}
                onChange={(e) =>
                  setService({
                    ...service,
                    modelStatic: {
                      ...service.modelStatic,
                      ecbiProbEnd: e.target.value,
                    },
                  })
                }
              />
            </Table.Cell>
            <Table.Cell>
              <Form.Input
                label='Discharge'
                name='ecbiIntensityEnd'
                type='number'
                min='36'
                max='252'
                step='1'
                value={service.modelStatic.ecbiIntensityEnd}
                error={serviceErrors.ecbiIntensityEnd}
                onChange={(e) =>
                  setService({
                    ...service,
                    modelStatic: {
                      ...service.modelStatic,
                      ecbiIntensityEnd: e.target.value,
                    },
                  })
                }
              />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Problem Difference:</b>{' '}
              {ecbiDiff(
                service.modelStatic.ecbiProbStart,
                ecbiCurrentProb,
                service.modelStatic.ecbiProbEnd
              )}
            </Table.Cell>
            <Table.Cell>
              <b>Intensity Difference:</b>{' '}
              {ecbiDiff(
                service.modelStatic.ecbiIntensityStart,
                ecbiCurrentIntensity,
                service.modelStatic.ecbiIntensityEnd
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
};

export default PCIT;
