import { saveAs } from 'file-saver'

import {
  dischargeNoteText,
  updateMonthLabel,
  updateMonthYYYYMM,
  calcLOS,
  totalUnits,
} from './functions'

export const decaValue = (name, value) => {
  if (!['PCIT-T', 'CPP'].includes(name)) {
    return 'N/A'
  }
  return value === '' ? 'not entered' : value
}

const exportMonthAllFieldsCSV = (data, filename) => {
  const YYYYMM = updateMonthYYYYMM()

  const csvHeaderRow =
    'Service ID, Monthly Update, Monthly Update By, Project, Provider, Service, First Name, Last Name, Date of Birth (DOB), Race, Sex, Gender, eCuraID, iCams, Therapist, Start Date, End Date, Length of Stay (As of Export), Discharge Type, Discharge Note, Referral Source, Referral Source Other, Total Number of Sessions that Occurred this Month, Total Number of Sessions for this Youth, CPP Supervision Provided in Current Month, Completed CPP Fidelity Tools in Current Month,ECBI Problem Start, ECBI Problem 6 Month, ECBI Problem End, ECBI Intensity Start, ECBI Intensity Current, ECBI Intensity End, DECA AR INITIAL, DECA AR MIDPOINT, DECA AR DISCHARGE, DECA IN INITIAL, DECA IN MIDPOINT, DECA IN DISCHARGE, DECA SR INITIAL, DECA SR MIDPOINT, DECA SR DISCHARGE, DECA BC INITIAL, DECA BC MIDPOINT, DECA BC DISCHARGE, DECA TPF INITIAL, DECA TPF MIDPOINT, DECA TPF DISCHARGE, DECA TYPE INITIAL, DECA TYPE MIDPOINT, DECA TYPE DISCHARGE\n'
  let csvStr = ''
  csvStr += csvHeaderRow

  data.forEach((doc) => {
    const {
      uid,
      project,
      provider,
      youth,
      shortName: serviceName,
      staff: staffInfo,
      dates,
      dischargeType,
      dischargeNote,
      referralSource,
      referralSourceCustom = '',
      months,
      modelStatic,
    } = doc.data()
    const { name: providerName } = provider
    const { firstName, lastName, DOB, race, bioSex, gender, stateIDs } = youth

    const { name: staffName } = staffInfo

    const { start, end } = dates
    const {
      ecbiProbStart,
      ecbiIntensityStart,
      ecbiProbEnd,
      ecbiIntensityEnd,
      decaInitialAR = '',
      decaInitialIN = '',
      decaInitialSR = '',
      decaInitialBC = '',
      decaInitialTPF = '',
      decaMidpointAR = '',
      decaMidpointIN = '',
      decaMidpointSR = '',
      decaMidpointBC = '',
      decaMidpointTPF = '',
      decaDischargeAR = '',
      decaDischargeIN = '',
      decaDischargeSR = '',
      decaDischargeBC = '',
      decaDischargeTPF = '',
      decaInitialType = '',
      decaMidpointType = '',
      decaDischargeType = '',
    } = modelStatic

    const units = months[YYYYMM].units
    const lastUpdated = months[YYYYMM].log.lastUpdated
    const lastUpdatedBy = months[YYYYMM].log.lastUpdatedBy
      ? months[YYYYMM].log.lastUpdatedBy
      : 'not updated'

    const lastUpdatedDate = lastUpdated
      ? lastUpdated.toDate().toLocaleDateString()
      : 'not updated'

    const {
      cppFidelity,
      cppSupervision,
      ecbiCurrentProb,
      ecbiCurrentIntensity,
    } = months[YYYYMM].model

    if (providerName === 'Bobert, Incorporated') {
      return
    }
    const providerNameNoComma = providerName.split(',')
    totalUnits(doc.data())

    csvStr +=
      uid +
      ',' +
      lastUpdatedDate +
      ',' +
      lastUpdatedBy +
      ',' +
      project +
      ',' +
      providerNameNoComma[0] +
      ',' +
      serviceName +
      ',' +
      firstName +
      ',' +
      lastName +
      ',' +
      DOB +
      ',' +
      race +
      ',' +
      bioSex +
      ',' +
      gender +
      ',' +
      stateIDs.dc.eCura +
      ',' +
      stateIDs.dc.iCams +
      ',' +
      staffName +
      ',' +
      start +
      ',' +
      end +
      ',' +
      calcLOS(start, end) +
      ',' +
      dischargeType +
      ',' +
      dischargeNoteText(dischargeNote, dischargeType) +
      ',' +
      referralSource +
      ',' +
      referralSourceCustom.replace(/,/g, '') +
      ',' +
      (units ? units : 'not entered') +
      ',' +
      totalUnits(doc.data()) +
      ',' +
      (serviceName === 'CPP'
        ? cppSupervision
          ? cppSupervision
          : 'not entered'
        : 'N/A') +
      ',' +
      (serviceName === 'CPP'
        ? cppFidelity
          ? cppFidelity
          : 'not entered'
        : 'N/A') +
      ',' +
      (serviceName === 'PCIT'
        ? ecbiProbStart
          ? ecbiProbStart
          : 'not entered'
        : 'N/A') +
      ',' +
      (serviceName === 'PCIT'
        ? ecbiCurrentProb
          ? ecbiCurrentProb
          : 'not entered'
        : 'N/A') +
      ',' +
      (serviceName === 'PCIT'
        ? ecbiProbEnd
          ? ecbiProbEnd
          : 'not entered'
        : 'N/A') +
      ',' +
      (serviceName === 'PCIT'
        ? ecbiIntensityStart
          ? ecbiIntensityStart
          : 'not entered'
        : 'N/A') +
      ',' +
      (serviceName === 'PCIT'
        ? ecbiCurrentIntensity
          ? ecbiCurrentIntensity
          : 'not entered'
        : 'N/A') +
      ',' +
      (serviceName === 'PCIT'
        ? ecbiIntensityEnd
          ? ecbiIntensityEnd
          : 'not entered'
        : 'N/A') +
      `,${decaValue(serviceName, decaInitialAR)},${decaValue(
        serviceName,
        decaMidpointAR
      )},${decaValue(serviceName, decaDischargeAR)},${decaValue(
        serviceName,
        decaInitialIN
      )},${decaValue(serviceName, decaMidpointIN)},${decaValue(
        serviceName,
        decaDischargeIN
      )},${decaValue(serviceName, decaInitialSR)},${decaValue(
        serviceName,
        decaMidpointSR
      )},${decaValue(serviceName, decaDischargeSR)},${decaValue(
        serviceName,
        decaInitialBC
      )},${decaValue(serviceName, decaMidpointBC)},${decaValue(
        serviceName,
        decaDischargeBC
      )},${decaValue(serviceName, decaInitialTPF)},${decaValue(
        serviceName,
        decaMidpointTPF
      )},${decaValue(serviceName, decaDischargeTPF)},${decaValue(
        serviceName,
        decaInitialType
      )},${decaValue(serviceName, decaMidpointType)},${decaValue(
        serviceName,
        decaDischargeType
      )}
      `
  })

  var blob = new Blob([csvStr], { type: 'text/plain;charset=utf-8' })
  return saveAs(blob, filename)
}

export { exportMonthAllFieldsCSV }
