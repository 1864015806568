//TODO: how to handle the service set state form services
//need uid here; but not at monthly update

import React, { useState, useEffect, useRef } from 'react';

import {
  calcLOS,
  listMonths,
  createServiceMonths,
  deleteServiceMonths,
  updateMonthYYYYMM,
  updateMonthLabel,
} from '../../util/functions';

import { modelSpecificServices } from '../../util/constants';

import {
  projectSelect,
  serviceSelect,
  referralSources,
  dischargeSelect,
} from '../../util/selects';

import { startDateError, endDateError } from '../../util/validators';

import { Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import UnitInputs from './UnitInputs';
import ModelSpecific from '../MonthlyUpdate/ModelSpecific';

const Services = ({
  serviceCount,
  allServices,
  service,
  setServices,
  allServiceErrors,
  serviceErrors,
  setServiceErrors,
  staffList,
  user,
}) => {
  const [serviceUpdate, setServiceUpdate] = useState({ ...service });

  const firstUpdate = useRef(true);
  //to update services state on top component
  useEffect(() => {
    //do not run on first render otherwise adding new services will overwrite
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    //update state on the entire create/edit form
    setServices({
      ...allServices,
      [service.uid]: { ...serviceUpdate },
    });
    //ignoring error; only want to fire when internal state is updated to send to upper form state.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceUpdate]);

  useEffect(() => {
    const los = calcLOS(
        serviceUpdate.dates.start,
        serviceUpdate.dates.end
    );
    setServiceUpdate({
        ...serviceUpdate,
        dates: {
            ...serviceUpdate.dates,
            los: los
        }
    });
  }, [serviceUpdate.dates.start, serviceUpdate.dates.end]);

  const handleStartDateOnChange = (e) => {
    const los = calcLOS(e.target.value, service.dates.end);

    //clear errors
    setServiceErrors({
      ...allServiceErrors,
      [service.uid]: {
        ...serviceErrors,
        startDate: false,
      },
    });

    //validate on change
    if (startDateError(e.target.value)) {
      setServiceErrors({
        ...allServiceErrors,
        [service.uid]: {
          ...serviceErrors,
          startDate: true,
        },
      });
    }

    setServiceUpdate({
      ...serviceUpdate,
      dates: {
        ...serviceUpdate.dates,
        start: e.target.value,
        los: los,
      },
    });
  };

  const handleEndDateOnChange = (e) => {
    const los = calcLOS(service.dates.start, e.target.value);

    //clear errors
    setServiceErrors({
      ...allServiceErrors,
      [service.uid]: {
        ...serviceErrors,
        endDate: false,
        dischargeType: false,
      },
    });

    //validate on change
    if (endDateError(e.target.value)) {
      setServiceErrors({
        ...allServiceErrors,
        [service.uid]: {
          ...serviceErrors,
          endDate: true,
        },
      });
    }

    setServiceUpdate({
      ...serviceUpdate,
      dates: {
        ...serviceUpdate.dates,
        end: e.target.value,
        los: los,
      },
    });
  };

  const manageMonthsObj = () => {
    //generate new list of months that should be in this service
    const months = listMonths(service.dates.start, service.dates.end);
    //delete any existing month units before start date
    deleteServiceMonths(months, service);
    //create needed unit months
    const serviceMonths = createServiceMonths(months, service);

    setServiceUpdate({
      ...serviceUpdate,
      monthsList: months,
      monthsNeedUpdated: months,
      months: {
        ...service.months,
        ...serviceMonths,
      },
    });
  };

  return (
    <Segment padded color='blue'>
      <Header as='h3'>Service {Number(serviceCount) + 1}:</Header>
      <Form.Select
        label='Project'
        name='Project'
        options={projectSelect}
        required
        value={serviceUpdate.project}
        onChange={(e, data) =>
          setServiceUpdate({
            ...serviceUpdate,
            project: data.value,
          })
        }
      />
      <Form.Select
        label='Service Name'
        name='shortName'
        placeholder='Select...'
        required
        error={serviceErrors.shortName}
        options={serviceSelect}
        value={serviceUpdate.shortName}
        onChange={(e, data) => {
          setServiceUpdate({
            ...serviceUpdate,
            shortName: data.value,
          })
          const clearError = {
            ...allServiceErrors,
            [service.uid]: { ...serviceErrors },
          }
          delete clearError[service.uid].shortName
          setServiceErrors(clearError)
        }}
      />
      <Form.Select
        label='Staff'
        name='staff.name'
        placeholder='Select...'
        required
        error={serviceErrors.staff}
        options={staffList.map((staff, index) => {
          return {
            key: index,
            text: staff.lastName + ', ' + staff.firstName,
            value: staff.uid,
          }
        })}
        value={serviceUpdate.staff.uid}
        onChange={(e, data) => {
          const { uid, firstName, lastName, position } = staffList.find(
            (staff) => staff.uid === data.value
          )
          setServiceUpdate({
            ...serviceUpdate,
            staff: {
              ...service.staff,
              name: firstName + ' ' + lastName,
              uid: uid,
            },
          })
          const clearError = {
            ...allServiceErrors,
            [service.uid]: { ...serviceErrors },
          }
          delete clearError[service.uid].staff
          setServiceErrors(clearError)
        }}
      />
      <Form.Select
        label='Referral Source'
        name='referralSource'
        placeholder='Select...'
        options={referralSources}
        value={serviceUpdate.referralSource}
        required
        error={serviceErrors.referralSource}
        onChange={(e, data) => {
          setServiceUpdate({
            ...serviceUpdate,
            referralSource: data.value,
          })
          const clearError = {
            ...allServiceErrors,
            [service.uid]: { ...serviceErrors },
          }
          delete clearError[service.uid].referralSource
          setServiceErrors(clearError)
        }}
      />
      {serviceUpdate.referralSource === 'Other' && (
        <>
          <Form.Input
            label='Please Explain:'
            name='referralSourceCustom'
            placeholder='Please explain...'
            value={serviceUpdate.referralSourceCustom}
            error={serviceErrors.referralSourceCustom}
            onChange={(e, data) => {
              setServiceUpdate({
                ...serviceUpdate,
                referralSourceCustom: data.value,
              })
              const clearError = {
                ...allServiceErrors,
                [service.uid]: { ...serviceErrors },
              }
              delete clearError[service.uid].referralSourceCustom
              setServiceErrors(clearError)
            }}
          />
          {serviceErrors.referralSourceCustom && (
             <Message negative>
             <p>
               An explaination is required when selecting Other as the referral
               source.
             </p>
           </Message>
          )}
        </>
      )}
      <Header as='h3' dividing>
        Service Delivery
      </Header>
      <Form.Group>
        <Form.Input
          width='three'
          label='Start Date'
          placeholder='YYYY-MM-DD'
          name='startDate'
          type='date'
          min='2015-01-01'
          max='9999-12-31'
          maxLength='10'
          value={serviceUpdate.dates.start}
          required
          error={serviceErrors.startDate}
          onChange={handleStartDateOnChange}
          onBlur={manageMonthsObj}
        />
        <Form.Input
          width='three'
          label='End Date (if closed)'
          name='endDate'
          placeholder='YYYY-MM-DD'
          type='date'
          min='2015-01-01'
          max='9999-12-31'
          maxLength='10'
          value={serviceUpdate.dates.end}
          error={serviceErrors.endDate}
          onChange={handleEndDateOnChange}
          onBlur={manageMonthsObj}
        />
        <Form.Field width='three'>
          <label>Length of Stay</label>
          <input
            label='Length of Stay'
            name='lengthOfStay'
            type='text'
            placeholder='auto-calculated'
            disabled
            value={
              serviceUpdate.dates.los &&
              (serviceUpdate.dates.los === 1
                ? serviceUpdate.dates.los + ' Day'
                : serviceUpdate.dates.los + ' Days')
            }
          />
        </Form.Field>
        <Form.Select
          width='seven'
          label='Discharge Type'
          name='dischargeType'
          placeholder='Select...'
          options={dischargeSelect}
          value={serviceUpdate.dischargeType}
          error={serviceErrors.dischargeType}
          onChange={(e, data) => {
            setServiceUpdate({
              ...serviceUpdate,
              dischargeType: data.value,
            })
            const clearError = {
              ...allServiceErrors,
              [service.uid]: { ...serviceErrors },
            }
            delete clearError[service.uid].dischargeType
            setServiceErrors(clearError)
          }}
        />
      </Form.Group>
      {serviceUpdate.dischargeType === 'Admin' && (
        <Form.Group>
        <Form.TextArea
          width='sixteen'
          label='Discharge Note'
          name='dischargeNote'
          type='text'
          value={serviceUpdate.dischargeNote}
          error={serviceErrors.dischargeNote}
          onChange={(e) => {
              setServiceUpdate({
                  ...serviceUpdate,
                  dischargeNote: e.target.value,
              })
              const clearError = {
              ...allServiceErrors,
              [service.uid]: { ...serviceErrors },
              }
              delete clearError[service.uid].dischargeNote
              setServiceErrors(clearError)
          }}
        />
        </Form.Group>
      )}
      {serviceErrors.dischargeNote && (
        <Message negative>
            <p>A note is required for admin discharges.</p>
        </Message>
      )}
      
      {(serviceErrors.startDate || serviceErrors.endDate) && (
        <Message negative>
          <Message.Header>Please check the start and end dates.</Message.Header>
          <p>
            This form is intended for reporting activity in the prior month and
            before.
          </p>
          <p>
            Start and end dates cannot be in the current month or later. Please
            wait until next month to report that activity, using the Monthly
            Update Tool.
          </p>
          <p>End date must be after start date.</p>
          <p>
            If you are not using the Chrome browser, please be sure to enter the
            date in the text box with a four digit year, two digit month
            (including leading zero if neede), and two digit day (including
            leading zero if needed): YYYY-MM-DD. Chrome provides a date picker
            tool that formats the date for you.
          </p>
        </Message>
      )}
      {serviceErrors.dischargeType && (
        <Message negative>
          <p>A discharge type is required if an end date is entered.</p>
        </Message>
      )}
      <Header as='h4' dividing sub>
        Monthly Sessions
      </Header>
      <span>
        Set the start and end dates above. Months will update below after you
        click away from each date field.
      </span>
      {/*any units error */}
      <Message>
        <p>Units must be non-negative whole numbers.</p>
        <p>Enter 0 if no services occured.</p>
      </Message>
      <Segment padded>
        {Object.keys(serviceUpdate.months).length === 0 ? (
          <Header as='h5'>No months to list.</Header>
        ) : (
          <Grid columns='eleven'>
            {Object.keys(serviceUpdate.months).map((month) => (
              <UnitInputs
                key={month}
                YYYYMM={month}
                service={serviceUpdate}
                setServices={setServiceUpdate}
                allServiceErrors={allServiceErrors}
                unitError={serviceErrors[month]}
                serviceErrors={serviceErrors}
                setServiceErrors={setServiceErrors}
                user={user}
              />
            ))}
          </Grid>
        )}
      </Segment>
      {modelSpecificServices.includes(service.shortName) &&
        Object.keys(serviceUpdate.months).includes(updateMonthYYYYMM()) && (
          <>
            <Header as='h4' dividing sub>
              Model Specific
            </Header>
            <Segment padded>
              <ModelSpecific
                service={serviceUpdate}
                setService={setServiceUpdate}
                serviceErrors={serviceErrors}
                YYYYMM={updateMonthYYYYMM()}
              />
            </Segment>
          </>
        )}
    </Segment>
  )
};

export default Services;
