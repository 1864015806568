import React, { useState } from 'react';

import { Button, Header, Image, Modal, Icon } from 'semantic-ui-react';

import { db } from '../../config/fireInit';

export const ConfirmDeleteModal = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { youthobj, services, resetForm, setSubmitMessages, setFormType } = props;
  const youthUID = youthobj.uid;
  const serviceIDs = Object.keys(services);

  const deleteYouth = () => {
    const batch = db.batch();
    batch.delete(db.collection('youth').doc(youthUID));
    serviceIDs.forEach((service) => {
      batch.delete(db.collection('services').doc(service));
    });
    batch
      .commit()
      .then(() => {
        //SUCCESS
        setFormType('ADD');
        setSubmitMessages({
          successMessage: `Successfully deleted youth. Unique ID: ${youthUID}, with service ID(s) ${serviceIDs}.`,
        });
        resetForm();
        setModalOpen(false);
      })
      .catch((err) => {
        setSubmitMessages({
            errorMessage: err.message,
          });
        setModalOpen(false);
      });
  };

  return (
    <Modal
      onClose={() => setModalOpen(false)}
      onOpen={() => setModalOpen(true)}
      open={modalOpen}
      size='small'
      trigger={
        <Button basic color='red' type='button'>
          <Icon name='user delete' />
          Delete Youth
        </Button>
      }
    >
      <Header icon>
        <Icon name='delete' color='red' />
        Are You Sure? This Cannot Be Undone.
      </Header>
      <Modal.Actions>
        <Button type='button' onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
        <Button
          type='button'
          content='Yes, Delete'
          labelPosition='right'
          icon='delete'
          onClick={() => deleteYouth()}
          negative
        />
      </Modal.Actions>
    </Modal>
  );
};
