import React, { useEffect, useState } from 'react';

import { Form, Header } from 'semantic-ui-react';

import ProviderSelect from '../ProviderSelect';

import { db } from '../../../config/fireInit';
import { calcLOS } from '../../../util/functions';

export const LOSReport = (props) => {
  const { user } = props;
  const [startDate, setStartDate] = useState('');
  const [LOS, setLOS] = useState(0);
  const [servicesList, setServicesList] = useState([]);

  //make sure menu is available
  useEffect(() => {
    props.showDashboardMenu();
    return () => {
      //
    };
  }, []);

  useEffect(() => {
    db.collection('services')
      .where('provider.uid', '==', user?.providerID)
      .where('dates.end', '==', '')
      .where('dates.start', '<=', startDate)
      .orderBy('dates.start')
      .get()
      .then((docs) => {
        const queriedServices = [];
        docs.forEach((doc) => {
          queriedServices.push(doc.data());
        });
        setServicesList(queriedServices);
      });
    return () => {
      //
    };
  }, [startDate, user.providerID]);

  return (
    <>
      <h1>Length of Stay Report</h1>
      <p>
        Show services where an active youth was enrolled prior to a certain
        date.
      </p>
      <hr />
      <Form>
        <Header as='h4'>Select A Provider</Header>
          <ProviderSelect
            providerName={user?.providerName}
            providerID={user?.providerID}
            uid={user?.uid}
          />
        <br/>
        <Form.Group>
          <Form.Input
            label='Enrolled Before Start Date'
            name='startDate'
            placeholder='YYY-MM-DD'
            type='date'
            min='2015-01-01'
            max='9999-12-31'
            maxLength='10'
            value={startDate}
            onChange={(e) => {
              const los = calcLOS(e.target.value);
              setStartDate(e.target.value);
              setLOS(los);
            }}
          />
          <Form.Field width='three'>
            <label>Length of Stay</label>
            <input
              label='Length of Stay'
              name='lengthOfStay'
              type='text'
              placeholder='auto-calculated'
              disabled
              value={LOS}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      {servicesList && startDate && (
        <>
          <Header as='h3'>
            All Currently Active Youth who were Enrolled BEFORE {startDate} with{' '}
            {user.providerName}:
          </Header>
          {servicesList.length <= 0 ? (
            <p>NONE</p>
          ) : (
            <>
              <h3>Count: {servicesList.length}</h3>
              <ul>
                {servicesList.map((service) => (
                  <li key={service.uid}>
                    {service.youth.firstName} {service.youth.lastName} | Start
                    Date: {service.dates.start}
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </>
  );
};
