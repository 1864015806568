import React, { Component } from "react";
import { StaffRow } from "./StaffRow";
import { Table } from "semantic-ui-react";
import { db } from "../../config/fireInit";

export default class AllStaff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      staff: [],
    };
    this.unsubscribeStaff = () => {};
  }

  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.unsubscribeStaff = db
      .collection("staff")
      .where("providerID", "==", user?.providerID)
      .orderBy("lastName")
      .onSnapshot((querySnapshot) => {
        const staffData = querySnapshot.docs.map((doc) => doc.data()); //was doc.data().staffName
        this.setState({ staff: staffData });
        //console.log("staffData", staffData);
      });
    }
  }

  componentWillUnmount(){
    this.unsubscribeStaff();
  }

  componentDidUpdate(prevProps) {
    const {user} = this.props;
    if (user !== prevProps.user) {
        console.log("props.user",this.props.user);
        console.log("prevProps.user",prevProps.user);
        console.log("Allstaff update, props.user different")
        this.unsubscribeStaff = db
        .collection("staff")
        .where("providerID", "==", user?.providerID)
        .orderBy("lastName")
        .onSnapshot((querySnapshot) => {
          const staffData = querySnapshot.docs.map((doc) => doc.data()); //was doc.data().staffName
          this.setState({ staff: staffData });
          //console.log("staffData", staffData);
        });
    }
  }

  render() {
    const { staff } = this.state;
    return (
      <Table.Body>
        {staff.map((eachStaff) => (
          <StaffRow key={`${eachStaff.uid}FUNC`} displayStaff={eachStaff} />
        ))}
      </Table.Body>
    );
  }
}
