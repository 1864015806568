import React, { useEffect } from 'react'
import { saveAs } from 'file-saver'

import { Button, Header, Icon } from 'semantic-ui-react'

import {
  updateMonthLabel,
} from '../../../util/functions'

import { firestore } from 'firebase'
import { db } from '../../../config/fireInit'

import { EndReason } from '../../../models/Staff'

import { ServiceReport } from '../../../util/Reports/ServiceReport'

const staff = {}

const createServiceReport = async () => {
  await new ServiceReport().csv()
}

const staffServiceCount = async () => {
  const staff = await db.collection('staff').get()
  staff.forEach(async (eachStaff) => {
    await eachStaff.ref.update({ caseLoad: {} })
  })

  const allOpenServices = await db
    .collection('services')
    .where('dates.end', '==', '')
    .get()

  const serviceCounts = []
  allOpenServices.forEach(async (service) => {
    if (!service.data().staff.uid) {
      return
    }
    const staffRef = db.collection('staff').doc(service.data().staff.uid)
    const serviceCountPath = `caseLoad.${[service.data().shortName]}`
    serviceCounts.push(
      staffRef.update({ [serviceCountPath]: firestore.FieldValue.increment(1) })
    )
  })
  return Promise.all(serviceCounts)
}

const staffCSV = async () => {
  const staffHeaderRow =
    'Staff Database ID, Provider, Staff Name, FTE, Employee, Service, Case Count, Start, End, Reason, Reason (if other)\n'
  let staffCSV = ''
  staffCSV += staffHeaderRow

  staffServiceCount()
    .then((results) => {
      console.log('staffCSV called staffServiceCount, results: ', results)
    })
    .then(() => {
      return db.collection('staff').get()
    })
    .then((allStaff) => {
      allStaff.forEach((staff) => {
        console.log('staff', staff.data())
        const staffName = staff.data().firstName + ' ' + staff.data().lastName
        const {
          uid,
          providerName,
          caseLoad = {},
          fullTime = true,
          employee = true,
          startDate = '',
          endDate = '',
          endReasonChoice = '',
          endReasonCustom = '',
        } = staff.data()
        const providerNameNoComma = providerName.replace(/,/g, '')
        const endReasonCustomNoComma = endReasonCustom.replace(/,/g, '')

        if (providerNameNoComma === 'Bobert Incorporated') {
          return
        }

        if (Object.keys(caseLoad).length === 0) {
          staffCSV += `${uid},${providerNameNoComma},${staffName},${
            fullTime ? 'Full-Time' : 'Part-Time'
          },${
            employee ? 'Employee' : 'Contractor'
          },NONE,NONE,${startDate},${endDate},${
            EndReason[endReasonChoice] ?? ''
          },${endReasonCustomNoComma}\n`
        } else {
          Object.keys(caseLoad).forEach((service) => {
            const serviceCount = caseLoad[service]
            staffCSV += `${uid},${providerNameNoComma},${staffName},${
              fullTime ? 'Full-Time' : 'Part-Time'
            },${
              employee ? 'Employee' : 'Contractor'
            },${service},${serviceCount},${startDate},${endDate},${
              EndReason[endReasonChoice] ?? ''
            },${endReasonCustomNoComma}\n`
          })
        }
      })
    })
    .then(() => {
      const staffCSVFileName = updateMonthLabel() + '-Staff.csv'
      var staffBlob = new Blob([staffCSV], {
        type: 'text/plain;charset=utf-8',
      })
      saveAs(staffBlob, staffCSVFileName)
    })
}

const AdminMonthlyExport = () => {
  useEffect(() => {
    const getStaff = async () => {
      const staffQuery = await db.collection('staff').get()
      staffQuery.forEach((doc) => {
        staff[`${doc.data().firstName} ${doc.data().lastName}`] = {
          cases: 0,
          provider: doc.data().providerName,
          service: 'none',
        }
      })
    }
    getStaff()
    return () => {
      //
    }
  }, [])

  return (
    <>
      <Header as='h1'>Admin Export for {updateMonthLabel()}</Header>
      <Button
        primary
        icon
        onClick={createServiceReport}
      >
        <Icon name='table' />
        Create {updateMonthLabel()} CSV Export
      </Button>
      <Button onClick={staffCSV}>Create Staffing Report</Button>
    </>
  )
}

export default AdminMonthlyExport
