const staffInputs = ['firstName', 'lastName'] as const
export type StaffInput = typeof staffInputs[number]

const staffSelects = ['position', 'endReasonChoice'] as const
export type StaffSelect = typeof staffSelects[number]

const staffToggles = ['fullTime', 'employee'] as const
export type staffToggle = typeof staffToggles[number]

export enum StaffPosition {
  Clinician = 'Clinician',
  TransitionalFacilitator = 'Transitional Facilitator',
  SeniorLeader = 'Senior Leader',
  SeniorLeaderSupervisor = 'Senior Leader/Supervisor',
  Supervisor = 'Supervisor',
}

export enum EndReason {
  NewAssignment = 'New Assignment (within agency)',
  Promotion = 'Promotion (within agency)',
  NewCareer = 'New Career Opportunity',
  PrivatePractice = 'Private Practice',
  Resigned = 'Retired/Resigned',
  Released = 'Released/Terminated',
  Parent = 'Maternity/Paternity Leave',
  Caregiver = 'Child Care/Elder Care',
  Leave = 'Leave of Absence/Sabbatical',
  Medical = 'Medical/Health Reasons',
  Military = 'Military Assignment',
  Other = 'Other',
}

export interface Errors {
  endDate?: string
  endReasonChoice?: string
  endReasonCustom?: string
  firstName?: string
  lastName?: string
  position?: string
  startDate?: string
}

const emptySelect = { key: '0', text: 'Select...', value: '' }
const endReasonSelects = Object.keys(EndReason).map((reason, index) => ({
  key: index + 1,
  text: EndReason[reason as keyof typeof EndReason],
  value: reason,
}))

export const endReasonSelect = [emptySelect, ...endReasonSelects]

export const positionSelect = [
  { key: '0', text: 'Select...', value: '' },
  { key: '1', text: StaffPosition.Clinician, value: StaffPosition.Clinician },
  {
    key: '2',
    text: StaffPosition.TransitionalFacilitator,
    value: StaffPosition.TransitionalFacilitator,
  },
  {
    key: '3',
    text: StaffPosition.SeniorLeader,
    value: StaffPosition.SeniorLeader,
  },
  {
    key: '4',
    text: StaffPosition.SeniorLeaderSupervisor,
    value: StaffPosition.SeniorLeaderSupervisor,
  },
  { key: '5', text: StaffPosition.Supervisor, value: StaffPosition.Supervisor },
]

interface StaffInterface {
  uid: string
  caseLoad: number
  createdAt: Date
  createdBy: string
  employee: boolean
  endReasonChoice?: EndReason | ''
  endReasonCustom?: string
  endDate?: string
  firstName: string
  fullTime: boolean
  lastName: string
  lastUpdated: Date
  position: StaffPosition | ''
  providerID: string
  providerName: string
  supervisor: boolean
  startDate?: string
}
export class Staff {
  uid: string
  caseLoad: number
  createdAt: Date
  createdBy: string
  employee: boolean
  endDate: string
  endReasonChoice: EndReason | ''
  endReasonCustom: string
  firstName: string
  fullTime: boolean
  lastName: string
  lastUpdated: Date
  position: StaffPosition | ''
  providerID: string
  providerName: string
  supervisor: boolean
  startDate: string

  constructor(staff?: StaffInterface) {
    if (staff) {
      this.lastUpdated = new Date()
      this.uid = staff.uid || ''
      this.caseLoad = staff.caseLoad || 0
      this.createdBy = staff.createdBy || ''
      this.createdAt = staff.createdAt || new Date()
      this.employee = staff.employee ?? true
      this.endReasonChoice = staff.endReasonChoice || ''
      this.endReasonCustom = staff.endReasonCustom || ''
      this.endDate = staff.endDate || ''
      this.firstName = staff.firstName || ''
      this.fullTime = staff.fullTime ?? true
      this.lastName = staff.lastName || ''
      this.position = staff.position || ''
      this.providerID = staff.providerID || ''
      this.providerName = staff.providerName || ''
      this.startDate = staff.startDate || ''
      this.supervisor = staff.supervisor || false
    } else {
      this.uid = ''
      this.caseLoad = 0
      this.createdBy = ''
      this.createdAt = new Date()
      this.employee = true
      this.endReasonChoice = ''
      this.endReasonCustom = ''
      this.endDate = ''
      this.firstName = ''
      this.fullTime = true
      this.lastName = ''
      this.lastUpdated = new Date()
      this.position = ''
      this.providerID = ''
      this.providerName = ''
      this.startDate = ''
      this.supervisor = false
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  toDatabase = () => {
    return {
      uid: this.uid,
      caseLoad: this.caseLoad,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      employee: this.employee,
      endReasonChoice: this.endReasonChoice,
      endReasonCustom: this.endReasonCustom,
      endDate: this.endDate,
      firstName: this.firstName,
      fullTime: this.fullTime,
      lastName: this.lastName,
      lastUpdated: this.lastUpdated,
      position: this.position,
      providerID: this.providerID,
      providerName: this.providerName,
      startDate: this.startDate,
      supervisor: this.supervisor,
    }
  }

  validate = () => {
    let errors: Errors = {}

    if (!this.firstName) {
      errors.firstName = 'First name is required.'
    }
    if (!this.lastName) {
      errors.lastName = 'Last name is required.'
    }
    if (!this.position) {
      errors.position = 'Position is required.'
    }
    if (!this.startDate) {
      errors.startDate = 'Start date is required.'
    }
    if (!this.startDate && this.endDate) {
      errors.startDate = 'Start date is required if there is an end date.'
    }
    if (this.endDate && !this.endReasonChoice) {
      errors.endReasonChoice =
        'A reason for leaving is required when there is an end date.'
    }

    const otherIndex = Object.keys(EndReason).indexOf("Other")
    if (this.endReasonChoice === Object.keys(EndReason)[otherIndex] && !this.endReasonCustom) {
      errors.endReasonCustom = 'Please explain the reason for leaving.'
    }
    return errors
  }
}

export default Staff
