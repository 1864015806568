import React, {
  FormEvent,
  KeyboardEvent,
  SyntheticEvent,
  useState,
} from 'react'
import { Form, Header, Button, Message, DropdownProps } from 'semantic-ui-react'
import { db } from '../../config/fireInit'
import {
  EndReason,
  Errors as StaffErrors,
  Staff,
  StaffInput,
  StaffSelect,
  staffToggle,
  StaffPosition,
  positionSelect,
  endReasonSelect,
} from '../../models/Staff'

interface User {
  displayName: string
  providerID: string
  providerName: string
}

export const AddStaff = (props: { user: User }) => {
  const [staff, setStaff] = React.useState({ ...new Staff() })

  const {
    firstName,
    lastName,
    position,
    startDate,
    endDate,
    endReasonChoice,
    endReasonCustom,
  } = staff

  const [formErrors, setFormErrors] = useState<StaffErrors>({})

  const [successMessage, setSuccessMessage] = React.useState('')
  const [successHeader, setSuccessHeader] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [errorHeader, setErrorHeader] = React.useState('')

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    const staffSubmission = new Staff({ ...staff })
    const errors = staffSubmission.validate()

    setFormErrors({ ...errors })

    const staffRef = db.collection('staff').doc()
    staffSubmission.uid = staffRef.id
    staffSubmission.createdAt = new Date()
    staffSubmission.lastUpdated = new Date()
    staffSubmission.createdBy = props.user.displayName
    staffSubmission.providerID = props.user.providerID
    staffSubmission.providerName = props.user.providerName

    if (Object.keys(errors).length === 0) {
      staffRef.set(staffSubmission.toDatabase()).then(() => {
        setSuccessMessage(
          'Staff added. You may continue adding more youth on this form.'
        )
        setSuccessHeader('Success')
        setStaff({ ...new Staff() })
      })
    } else {
      setErrorHeader('Error.')
      setErrorMessage('Check form fields for errors and re-submit.')
    }
  }

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    //clear form success or error messages
    setSuccessMessage('')
    setSuccessHeader('')
    setErrorMessage('')
    setErrorHeader('')

    staff[e.currentTarget.name as StaffInput] = e.currentTarget.value
    //clear the required error while makeing a change
    formErrors[e.currentTarget.name as StaffInput] = undefined
    //validdate

    setStaff({ ...staff })
    setFormErrors({ ...formErrors })
  }

  const validateInputOnBlur = (e: FormEvent<HTMLInputElement>) => {
    if (staff[e.currentTarget.name as StaffInput] === '') {
      formErrors[
        e.currentTarget.name as StaffInput
      ] = `${e.currentTarget.placeholder} is required`
    }
    setFormErrors({ ...formErrors })
  }

  const handleSelectChange = (
    e: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    // do I need data?
    //clear form success or error messages
    setSuccessMessage('')
    setSuccessHeader('')
    setErrorMessage('')
    setErrorHeader('')

    formErrors[data.name as StaffSelect] = undefined
    ;(staff as Record<typeof data.name, typeof data.name>)[
      data.name as StaffSelect
    ] = data.value as StaffPosition

    setStaff({ ...staff })
    setFormErrors({ ...formErrors })
  }

  const validateSelectOnBlur = (
    e: KeyboardEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    if (staff[data.name as StaffSelect] === '') {
      formErrors[data.name as StaffSelect] = `${data.placeholder} is required`
    }
    setFormErrors({ ...formErrors })
  }

  const handleToggle = (e: FormEvent<HTMLButtonElement>) => {
    staff[e.currentTarget.name as staffToggle] = !staff[
      e.currentTarget.name as staffToggle
    ]
    setStaff({ ...staff })
  }

  const isCurrentEndReason = (reason: string) => {
    const reasonIndex = Object.keys(EndReason).indexOf(reason)
    return endReasonChoice === Object.keys(EndReason)[reasonIndex]
  }

  return (
    <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
      <Header as='h2'>Add New Staff:</Header>
      <p>
        Adding a staff person will make them available from dropdown lists
        throughout the app.
      </p>
      <Form.Group>
        <Form.Field width='eight' error={formErrors.firstName} required>
          <label>First Name</label>
          <input
            type='text'
            name='firstName'
            placeholder='First Name'
            value={staff.firstName}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => validateInputOnBlur(e)}
          />
        </Form.Field>
        <Form.Field width='eight' error={formErrors.lastName} required>
          <label>Last Name</label>
          <input
            type='text'
            name='lastName'
            placeholder='Last Name'
            value={staff.lastName}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => validateInputOnBlur(e)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label>Click to Toggle Employment Statuses</label>
        <Button
          type='button'
          name='fullTime'
          toggle
          active={staff.fullTime}
          onClick={handleToggle}
        >
          {staff.fullTime ? 'Full Time' : 'Part Time'}
        </Button>
        <Button
          type='button'
          name='employee'
          toggle
          active={staff.employee}
          onClick={handleToggle}
        >
          {staff.employee ? 'Employee' : 'Contractor'}
        </Button>
      </Form.Field>
      <Form.Group>
        <Form.Input
          label='Start Date'
          name='startDate'
          type='date'
          value={startDate}
          onChange={(e) => handleInputChange(e)}
          error={formErrors.startDate}
        />
        <Form.Input
          label='End Date'
          name='endDate'
          type='date'
          value={endDate}
          onChange={(e) => handleInputChange(e)}
          error={formErrors.endDate}
        />
      </Form.Group>
      {endDate && (
        <Form.Select
          label='End Reason'
          name='endReasonChoice'
          placeholder='End Reason'
          options={endReasonSelect}
          value={endReasonChoice}
          error={formErrors.endReasonChoice}
          onChange={handleSelectChange}
        />
      )}
      {isCurrentEndReason('Other') && (
        <Form.Input
          type='text'
          label='Other:'
          name='endReasonCustom'
          placeholder='Please explain...'
          value={endReasonCustom}
          error={formErrors.endReasonCustom}
          onChange={(e) => handleInputChange(e)}
        />
      )}
      <Form.Select
        label='Position Type'
        placeholder='Select...'
        name='position'
        options={positionSelect}
        value={staff.position}
        onChange={handleSelectChange}
        onBlur={validateSelectOnBlur}
        required
        error={formErrors.position}
      />
      {successMessage && (
        <Message positive header={successHeader} content={successMessage} />
      )}
      {errorMessage && (
        <Message negative header={errorHeader} content={errorMessage} />
      )}
      <Button type='submit' primary>
        Add Staff
      </Button>
    </Form>
  )
}
