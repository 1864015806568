/*
Used only by EBAdmins
On the manage user page to assign or change a user's association with a provider.
When EBAdmin is performing functions on behalf of a provider (manage staff and youth) it will change the current providerName and providerID on that EBAdmin's user document.
*/

import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import {db} from "../../config/fireInit";

export default class ProviderSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      providerSelect: [],
      providerName: "",
      providerID: "",
      uid: ""
    };
  }

  componentDidMount() {
    const { providerName, providerID, uid } = this.props;
    const getProviders = async () => {
      const providerQuery = await db.collection('providers').orderBy('providerName').get();
      const providers = [];
      providerQuery.forEach(doc => {
        providers.push(doc.data());
      });
      this.setState({ providerName, uid, providerID, providers });
    }
    getProviders();
  }

  handleSelectChange = (e, data) => {
    const { uid } = this.state;
    //const {loadStaff} = this.props;
    this.setState({providerName: e.target.textContent, providerID: data.value});
    db
      .collection("users")
      .doc(uid)
      .update({providerName: e.target.textContent, providerID: data.value})
      .then(function() {
        //console.log("Document successfully updated!");
        //console.log("data.value",data.value);
        //loadStaff(data.value);
    })
    .catch(function(error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
    });
  };

  // options={providers.map((provider, index) => {
  //   return { key: index, text: provider.providerName, value: provider.uid };
  // })}
  render() {
    const { providerID, providers } = this.state;
    //const { providers } = this.props;
    //console.log("providers",providers)
    return (
      <div>
        {providers && (
          <Form.Select
          name='providerID'
          options={providers.map((provider, index) => {
              return { key: index, text: provider.providerName, value: provider.uid };
            })}
          //options={providerChoices}//was providerChoices
          value={providerID}
          onChange={this.handleSelectChange}
        />
        )}
      </div>
    );
  }
}
