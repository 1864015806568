const decaTimeSelect = [
  { key: "1", text: "Initial", value: "Initial" },
  { key: "2", text: "Mid-point", value: "Mid-point" },
  { key: "3", text: "Discharge", value: "Discharge" },
];

const decaTypeSelect = [
  { key: "1", text: "Infant", value: "Infant" },
  { key: "2", text: "Toddler", value: "Toddler" },
  { key: "3", text: "Pre-school (PS)", value: "Pre-school (PS)" },
];

const bioSexSelect = [
  { key: "1", text: "Male", value: "Male" },
  { key: "2", text: "Female", value: "Female" },
  { key: "3", text: "Neither Male nor Female", value: "Neither" },
];

const genderSelect = [
  { key: "1", text: "Male", value: "Male" },
  { key: "2", text: "Female", value: "Female" },
];

const raceSelect = [
  { key: "1", text: "White (Not Hispanic)", value: "White (Not Hispanic)" },
  { key: "2", text: "Black (Not Hispanic)", value: "Black (Not Hispanic)" },
  {
    key: "3",
    text: "Hispanic",
    value: "Hispanic",
  },
  {
    key: "4",
    text: "Asian or Asian-American or Pacific Islander",
    value: "Asian or Asian-American or Pacific Islander",
  },
  {
    key: "5",
    text: "North American Indian or Alaskan Native",
    value: "North American Indian or Alaskan Native",
  },
];

const projectSelect = [
  { key: "1", text: "Families First", value: "Families First" },
  { key: "2", text: "DC Seed", value: "DC Seed", disabled: true },
];

const serviceSelect = [
  {
    key: "1",
    text: "Child Parent Psychotherapy (CPP)",
    value: "CPP",
  },
  { key: "2", text: "Parent Child Interaction Thearpy (PCIT)", value: "PCIT" },
  { key: "3", text: "Transition to Independence Process (TIP)", value: "TIP" },
  {
    key: "4",
    text: "Trauma-Focused Cognitive Behavioral Therapy (TF-CBT)",
    value: "TFCBT",
  },
  {
    key: "5",
    text: "Trauma Systems Therapy (TST)",
    value: "TST",
  },
  {
    key: "6",
    text: "Parent Child Interaction Thearpy - Toddler (PCIT-T)",
    value: "PCIT-T",
  },
];

const referralSources = [
  { key: "1", text: "CFSA", value: "CFSA" },
  { key: "2", text: "CSS", value: "CSS" },
  { key: "3", text: "CSOSA", value: "CSOSA" },
  { key: "4", text: "DBH", value: "DBH" },
  { key: "5", text: "DYRS", value: "DYRS" },
  { key: "6", text: "School", value: "School" },
  { key: "7", text: "Hospital", value: "Hospital" },
  { key: "8", text: "Internal", value: "Internal" },
  { key: "9", text: "Housing", value: "Housing" },
  { key: "10", text: "Foster Care", value: "Foster Care" },
  { key: "11", text: "ACE Diversion", value: "ACE Diversion" },
  { key: "12", text: "Access Help Line", value: "Access Help Line" },
  { key: "13", text: "Help Me Grow", value: "Help Me Grow" },
  { key: "14", text: "JBDP", value: "JBDP" },
  { key: "15", text: "Self/Family", value: "Self/Family" },
  { key: "16", text: "CSA/Provider", value: "CSA/Provider" },
  { key: "17", text: "Collaborative", value: "Collaborative" },
  { key: "18", text: "Other", value: "Other" },
  { key: "19", text: "DHS", value: "DHS" },
];

const dischargeSelect = [
  { key: "0", text: "", value: "" },
  { key: "1", text: "Admin", value: "Admin" },
  { key: "2", text: "Successful", value: "Successful" },
  { key: "3", text: "Unsuccessful", value: "Unsuccessful" },
];

referralSources.sort((a, b) => (a.text > b.text ? 1 : -1));
serviceSelect.sort((a, b) => (a.text > b.text ? 1 : -1));

export {
  bioSexSelect,
  genderSelect,
  raceSelect,
  projectSelect,
  serviceSelect,
  referralSources,
  dischargeSelect,
  decaTimeSelect,
  decaTypeSelect,
};
