import React from 'react'

export const EULA = () => {
    return (
        <div>
            <h1>End User License Agreement</h1>
            <p>Placeholder.</p>
        </div>
    )
}
