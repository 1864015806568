import React, {
  FormEvent,
  KeyboardEvent,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react'
import { Button, DropdownProps, Form, Icon, Table } from 'semantic-ui-react'
import { db } from '../../config/fireInit'
import {
  EndReason,
  Errors as StaffErrors,
  Staff,
  StaffInput,
  StaffSelect,
  staffToggle,
  positionSelect,
  endReasonSelect,
} from '../../models/Staff'

export const StaffRow = (props: { displayStaff: Staff }) => {
  const [staff, setStaff] = useState({ ...new Staff() })

  const {
    endDate,
    endReasonChoice,
    endReasonCustom,
    uid,
    firstName,
    lastName,
    fullTime,
    startDate,
    employee,
    position,
  } = staff

  const [formErrors, setFormErrors] = useState<StaffErrors>({})

  const [highlight, setHighlight] = useState(false)
  const [errorRow, setErrorRow] = useState(false)

  useEffect(() => {
    setStaff({ ...new Staff(props.displayStaff) })
  }, [])

  const handleInputChange = (e: FormEvent<HTMLInputElement>) => {
    staff[e.currentTarget.name as StaffInput] = e.currentTarget.value
    //clear the required error while makeing a change
    formErrors[e.currentTarget.name as StaffInput] = undefined
    //validdate

    setStaff({ ...staff })
    setFormErrors({ ...formErrors })
  }

  const validateInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (staff[e.currentTarget.name as StaffInput] === '') {
      formErrors[
        e.currentTarget.name as StaffInput
      ] = `${e.currentTarget.placeholder} is required`
    }
    setFormErrors({ ...formErrors })
  }

  const handleToggle = (e: FormEvent<HTMLButtonElement>) => {
    staff[e.currentTarget.name as staffToggle] = !staff[
      e.currentTarget.name as staffToggle
    ]
    setStaff({ ...staff })
  }

  const handleSelectChange = (
    e: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    formErrors[data.name as StaffSelect] = undefined
    ;(staff as Record<typeof data.name, typeof data.name>)[
      data.name as StaffSelect
    ] = data.value

    setStaff({ ...staff })
    setFormErrors({ ...formErrors })
  }

  const validateSelectOnBlur = (
    e: KeyboardEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    if (staff[data.name as StaffSelect] === '') {
      formErrors[data.name as StaffSelect] = `${data.placeholder} is required`
    }
    setFormErrors({ ...formErrors })
  }

  const submitWithTimeout = () => {
    setHighlight(true)
    setTimeout(() => handleEditStaff(), 300)
  }

  const handleEditStaff = () => {
    const staffSubmission = new Staff({ ...staff })
    const errors = staffSubmission.validate()

    setFormErrors({ ...errors })

    if (Object.keys(errors).length === 0) {
      const unsubscribe = db
        .collection('staff')
        .doc(uid)
        .update(staffSubmission.toDatabase())
        .then(() => {
          setHighlight(false)
        })
    } else {
      setHighlight(false)
      setErrorRow(true)
    }
  }

  const isCurrentEndReason = (reason: string) => {
    const reasonIndex = Object.keys(EndReason).indexOf(reason)
    return endReasonChoice === Object.keys(EndReason)[reasonIndex]
  }

  return (
    <Table.Row verticalAlign='top'>
      <Table.Cell warning={highlight} error={errorRow}>
        <Form noValidate autoComplete='off'>
          <Form.Input
            required
            error={formErrors.lastName}
            type='text'
            name='lastName'
            placeholder='Last Name'
            value={lastName}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              validateInputOnBlur(e)
            }
          />
        </Form>
      </Table.Cell>
      <Table.Cell warning={highlight} error={errorRow}>
        <Form noValidate autoComplete='off'>
          <Form.Input
            required
            error={formErrors.firstName}
            type='text'
            name='firstName'
            placeholder='First Name'
            value={firstName}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              validateInputOnBlur(e)
            }
          />
        </Form>
      </Table.Cell>
      <Table.Cell warning={highlight} error={errorRow}>
        <Form>
          <Form.Group>
            <Button
              name='fullTime'
              toggle
              active={fullTime}
              onClick={handleToggle}
            >
              {fullTime ? 'Full Time' : 'Part Time'}
            </Button>
            <Button
              name='employee'
              toggle
              active={employee}
              onClick={handleToggle}
            >
              {employee ? 'Employee' : 'Contractor'}
            </Button>
          </Form.Group>
        </Form>
      </Table.Cell>
      <Table.Cell>
        <Form noValidate autoComplete='off'>
          <Form.Group>
            <Form.Input
              label='Start Date'
              name='startDate'
              type='date'
              value={startDate}
              onChange={(e) => handleInputChange(e)}
              error={formErrors.startDate}
            />
            <Form.Input
              label='End Date'
              name='endDate'
              type='date'
              value={endDate}
              onChange={(e) => handleInputChange(e)}
              error={formErrors.endDate}
            />
          </Form.Group>
          {endDate && (
            <Form.Select
              label='End Reason'
              name='endReasonChoice'
              placeholder='End Reason'
              options={endReasonSelect}
              value={endReasonChoice}
              error={formErrors.endReasonChoice}
              onChange={handleSelectChange}
            />
          )}
          {isCurrentEndReason('Other') && (
            <Form.Input
              type='text'
              label='Please Explain:'
              name='endReasonCustom'
              placeholder='Please explain...'
              value={endReasonCustom}
              error={formErrors.endReasonCustom}
              onChange={(e) => handleInputChange(e)}
            />
          )}
        </Form>
      </Table.Cell>
      <Table.Cell warning={highlight} error={errorRow}>
        <Form>
          <Form.Select
            placeholder='Select...'
            name='position'
            options={positionSelect}
            value={position}
            onChange={handleSelectChange}
            required
            error={formErrors.position}
            onBlur={validateSelectOnBlur}
          />
        </Form>
      </Table.Cell>
      <Table.Cell warning={highlight} error={errorRow}>
        <Button icon basic color='blue' onClick={() => submitWithTimeout()}>
          <Icon name='check circle' color='blue' />
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}
