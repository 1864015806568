/*
USE THIS FILE TO SWITCH BETWEEN PRODUCTION & TESTING & DEVELOPMENT
1) Select the appropriate import: {production, development, testing} from './fireConfig';
2) Change the firebase.initializeApp() command to match.
3) Change EBAppEnv to the string for the appropriate environment. This will change UI.

Run in terminal for development app:    firebase use ebapp-dev
... for testing app:                    firebase use ebapp-b931b
... for production app:                 firebase use ebapp-3b893

npm run build
firebase deploy
---
As a visual reminder make Header.jsx menu inverted for Dev
*/

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage'
import { firebaseEnvironments } from './fireConfig';

const EBAppEnv = process.env.REACT_APP_ENV || "development";

firebase.initializeApp(firebaseEnvironments[EBAppEnv]);
const db = firebase.firestore();
const storage = firebase.storage().ref();

export  {db, storage, EBAppEnv};
