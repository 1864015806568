import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import {db} from "../../config/fireInit";

class ApprovalButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayUser: {}
    };
  }

  componentDidMount() {
    const { displayUser } = this.props;
    this.setState({ displayUser });
  }

  handleToggleApproval() {
    let displayUser = {...this.state.displayUser};
    displayUser.roles.Provider = !displayUser.roles.Provider;
    const userRef = db.collection("users").doc(displayUser.uid);
    userRef
      .update({"roles.Provider": displayUser.roles.Provider})
      .then(() => {
        //success
        // this.setState({
        //   successMessage: "Youth updated.",
        //   successHeader: "Success"
        // });
        //this.setState({displayUser});
      })
      .catch(error => {
        //change state so error message displays
        //this.setState({ errorMessage: error.message, errorHeader: "Error" });
        console.error("Error",error);
      });
  }

  render() {
    const { displayUser } = this.state;
    //const {user} = this.props;
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.handleToggleApproval()}
          primary={displayUser.roles?.Provider}
          basic={!displayUser.roles?.Provider}
          //disabled={displayUser.uid === user.uid}
        >
          Provider{/*displayUser.roles?.Provider ? "Provider" : "Not Provider"*/}
        </Button>
      </div>
    );
  }
}

export default ApprovalButton;
