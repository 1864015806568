import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
//import ScrollToTop from "./ScrollToTop";
import HomePage from "../components/HomePage";
import CreateUpdateYouth from "../components/CreateUpdateYouth/CreateUpdateYouth";
import AllYouth from "../components/AllYouth/AllYouth";
import MonthlyUpdate from "../components/MonthlyUpdate/MonthlyUpdate";
import Profile from "../components/Profile";
import AllUsers from "../components/AllUsers/AllUsers";
import EditUser from "../components/AllUsers/EditUser";
import ManageStaff from "../components/Staff/ManageStaff";
import SuperAdmin from "../components/EBAdmin/SuperAdmin";
import { Dashboard } from "../components/EBAdmin/Dashboard/Dashboard";

import PublicPage from "../components/PublicPage";

import * as firebase from "firebase/app";
import AdminMonthlyExport from "../components/EBAdmin/MonthlyCSVExport/AdminMonthlyExport";
import { PrivacyStatement } from "../components/PrivacyStatement";
import { EULA } from "../components/EULA";
import ChangeLog from "../components/ChangeLog";
import { LOSReport } from "../components/EBAdmin/Dashboard/LOSReport";

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user, showDashboardMenu } = this.props;
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          firebase.auth().currentUser ? (
            <Component {...props} user={user} />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          )
        }
      />
    );
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <HomePage {...props} signIn={this.props.signIn} user={user} />
          )}
        />
        <Route
          path="/public"
          render={(props) => <PublicPage {...props} user={user} />}
        />
        <Route path="/changes/" component={ChangeLog}/>
        <Route path="/privacy/" component={PrivacyStatement} />
        <Route path="/eula/" component={EULA} />

        {/*must be logged in to access these routes*/}
        <Route
          path="/allYouth"
          render={(props) => <AllYouth {...props} user={user} />}
        />

        <Route
          path="/staff"
          render={(props) => <ManageStaff {...props} user={user} />}
        />
        <PrivateRoute
          exact path="/EBAdmin/admin"
          component={SuperAdmin}
        />

        {/* <PrivateRoute exact path="/youth" component={CreateUpdateYouth} />
        <PrivateRoute path="/youth/:id" component={CreateUpdateYouth} /> */}
        <PrivateRoute exact path="/youth" component={CreateUpdateYouth} />
        <PrivateRoute path="/youth/:id" component={CreateUpdateYouth} />
        {/*<PrivateRoute path='/all-youth' component={AllYouth} />*/}
        <PrivateRoute path="/update" component={MonthlyUpdate} />
        <PrivateRoute path="/profile" component={Profile} />

        {/*TODO write EBAdmin route guard */}
        <PrivateRoute exact path="/EBAdmin/users" component={AllUsers} />
        <PrivateRoute path="/EBAdmin/users/:id" component={EditUser} />
        <PrivateRoute path="/EBAdmin/export" component={AdminMonthlyExport} />

        {/* Switch back to private route after testing */}
        <Route 
          path="/EBAdmin/dashboard/main" 
          render={(props) => <Dashboard {...props} showDashboardMenu={showDashboardMenu} />}
        />
        <Route 
          path="/EBAdmin/dashboard/los" 
          render={(props) => <LOSReport {...props} showDashboardMenu={showDashboardMenu} user={user}/>}
          />
      </Switch>
    );
  }
}

export default Router;
