//TODO: Continue building this from now that cron func is developed
//Remove all the stuff about rebuilding the form every time; should be ONLY a simple query now.

//NOTES FOR IMPLEMENTING IN PROD:
//Use SUPER ADMIN button for copying services from exisiting youth
//BE SURE EXPORT OCCURED!
//Run the cloud function to generate the YYYYMM
//Then test this new component
//update security settings on new Services collection (pretty much match youth collection)

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Header, Icon, Message } from 'semantic-ui-react';
import { db } from '../../config/fireInit';

import { updateMonthYYYYMM, updateMonthLabel } from '../../util/functions';
import ProviderSelect from '../EBAdmin/ProviderSelect';
import BlankCSV from './BlankCSV';
import UpdateTable from './UpdateTable';

const MonthlyUpdate = ({ user }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [servicesForTable, setServicesForTable] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [showAllServices, setShowAllServices] = useState(false);
  const [sortedColumn, setSortedColumn] = useState('youth.lastName');
  const [sortedDirection, setSortedDirection] = useState('ascending');

  const YYYYMM = updateMonthYYYYMM();
  const monthLabel = updateMonthLabel();

  const handleColumnClick = (clickedColumn) => {
    if (clickedColumn === sortedColumn) {
      setSortedDirection(
        sortedDirection === 'ascending' ? 'descending' : 'ascending'
      );
      return;
    }
    setSortedColumn(clickedColumn);
    setSortedDirection('ascending');
  };

  useEffect(() => {
    const getServices = () => {
      //Queries
      const servicesRef = db.collection('services');
      const onlyUpdatesNeedQ = servicesRef
        .where('provider.uid', '==', user.providerID)
        .where('monthsNeedUpdated', 'array-contains', YYYYMM)
        .orderBy(
          sortedColumn,
          sortedDirection === 'ascending' ? 'asc' : 'desc'
        );

      const allServicesQ = servicesRef
        .where('provider.uid', '==', user.providerID)
        .where('monthsList', 'array-contains', YYYYMM)
        .orderBy(
          sortedColumn,
          sortedDirection === 'ascending' ? 'asc' : 'desc'
        );

      const querySelect = showAllServices ? allServicesQ : onlyUpdatesNeedQ;

      //with snapshot observer
      const observer = querySelect.onSnapshot(
        (querySnapshot) => {
          const servicesToUpdate = querySnapshot.docs.map((doc) => doc.data());
          servicesToUpdate.forEach((service) => {
            //console.log(`service ${service.uid}`);
          });
          setServicesForTable(servicesToUpdate);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setLoadError(error.message);
        }
      );
      return observer;
    };

    const getProviderStaff = async () => {
      const providerSnapshot = await db
        .collection('staff')
        .where('providerID', '==', user.providerID)
        .where('endDate', '==', '')
        .orderBy('lastName')
        .get();
      const staff = [];
      providerSnapshot.forEach((doc) => {
        staff.push(doc.data());
      });
      setStaffList(staff);
    };
    const subscribe = getServices();
    getProviderStaff();
    return () => subscribe();
  }, [YYYYMM, showAllServices, user.providerID, sortedColumn, sortedDirection]);

  return (
    <div>
      {user.roles.EBAdmin && (
        <Form>
          <Header as='h2' dividing>
            Provider Information
          </Header>
          <ProviderSelect
            providerName={user.providerName}
            providerID={user.providerID}
            uid={user.uid}
            //providers={providers}
          />
        </Form>
      )}
      <h1>Monthly Update for {monthLabel}</h1>
      <p>
        <b>Notes: </b>This tool only displays youth with a <i>currently</i>{' '}
        active service in the focus month. To make changes to service delivery
        for a closed youth, go to that youth&apos;s record from the{' '}
        <Link to='/all-youth'>&quot;all youth&quot;</Link> page. You can sort
        the listing here by clicking on each column. Click the check mark to
        submit your entry. Use the edit button to go to that youth&apos;s detail
        page. As you submit, the youth will be removed from the &quot;Updates
        Needed&quot; table. When you are finished, you can verify your entries
        by clicking &quot;Show All Youth&quot;.
      </p>
      <BlankCSV
        updateMonthLabel={monthLabel}
        YYYYMM={YYYYMM}
        user={user}
      />
      <Button
        primary={!showAllServices}
        basic={showAllServices}
        onClick={() => {
          setShowAllServices(false);
          setIsLoaded(false);
        }}
      >
        {showAllServices
          ? 'Filter Only Updates Needed'
          : 'Filtered By Only Updates Needed'}
      </Button>
      <Button
        primary={showAllServices}
        basic={!showAllServices}
        onClick={() => {
          setShowAllServices(true);
          setIsLoaded(false);
        }}
      >
        {showAllServices ? 'Showing All Services' : 'Show All Services'}
      </Button>
      {loadError && (
        <Message icon negative>
          <Icon name='exclamation circle' />
          <Message.Content>
            <Message.Header>Error</Message.Header>
            {loadError}
          </Message.Content>
        </Message>
      )}
      {!isLoaded && (
        <Message icon>
          <Icon name='circle notched' loading />
          <Message.Content>
            <Message.Header>Loading</Message.Header>
            Building your monthly update table...
          </Message.Content>
        </Message>
      )}
      {isLoaded && showAllServices && (
        <Message positive>
          <p>
            A row with a green background indicates that the submission has been
            recorded. Be sure to click the blue check mark in the far right
            column to save your entires.
          </p>
        </Message>
      )}
      {isLoaded && servicesForTable.length > 0 ? (
        <UpdateTable
          monthLabel={monthLabel}
          services={servicesForTable}
          YYYYMM={YYYYMM}
          staffList={staffList}
          user={user}
          sortedColumn={sortedColumn}
          sortedDirection={sortedDirection}
          handleColumnClick={handleColumnClick}
        />
      ) : (
        !loadError && (
          <Message info>
            <Message.Header>No Services Need Updated</Message.Header>
            <p>All expected service updates have been entered and submitted.</p>
            <p>
              Click on &quot;Show All Services&quot; to verify all entries for
              this month.
            </p>
          </Message>
        )
      )}
    </div>
  );
};

export default MonthlyUpdate;
