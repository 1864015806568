import React, { Fragment } from "react";
import { Menu, Icon, Dropdown, Container, Message } from "semantic-ui-react";
import { NavLink, Link } from "react-router-dom";
import SignedInMenu from "./SignedInMenu";
import SignedOutMenu from "./SignedOutMenu";
import * as firebase from "firebase/app";
import AccountPending from "./AccountPending";
import { EBAppEnv } from "../config/fireInit";

const Header = (props) => {
  const { user } = props;
  let headerColor = "";
  if (EBAppEnv === "development") {
    headerColor = "purple";
  } else if (EBAppEnv === "testing") {
    headerColor = "teal";
  }
  return (
    <Fragment>
      <Menu
        borderless
        stackable
        {...(headerColor ? { color: headerColor } : {})}
        inverted={EBAppEnv !== 'production'}
      >
        <Menu.Item as={Link} to='/' header onClick={props.hideDashboardMenu}>
          <Icon name='arrows alternate' color='blue' />
          EBApp {EBAppEnv !== 'production' && ' -' + EBAppEnv}
        </Menu.Item>
        {firebase.auth().currentUser && ( //hide menus if not signed in.
          <Fragment>
            {props.user?.roles.EBAdmin && ( //show EBAdmin dropdwon
              <>
                <Dropdown item text='EBAdmin'>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to='/EBAdmin/dashboard/main' onClick={props.showDashboardMenu}>
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to='/EBAdmin/users' onClick={props.hideDashboardMenu}>
                      Manage Users
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to='/EBAdmin/export' onClick={props.hideDashboardMenu}>
                      Monthly Export
                    </Dropdown.Item>
                    {user.email === 'joelnwalkley@gmail.com' && (
                      <Dropdown.Item as={NavLink} to='/EBAdmin/admin' onClick={props.hideDashboardMenu}>
                        admin testing (for joel)
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown item text='Manage for Providers'>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to='/staff' onClick={props.hideDashboardMenu}>
                      Manage Staff
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to='/youth' onClick={props.hideDashboardMenu}>
                      Add New Youth
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to='/allYouth' onClick={props.hideDashboardMenu}>
                      List All Youth
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to='/update' onClick={props.hideDashboardMenu}>
                      Monthly Review Tool
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
            {props.user?.roles.Provider &&
            !props.user?.roles.EBAdmin && ( //&& !props.user?.roles.EBAdmin
                <Dropdown item text={props.user?.providerName}>
                  <Dropdown.Menu>
                    <Dropdown.Item as={NavLink} to='/staff'>
                      Manage Staff
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to='/youth'>
                      Add New Youth
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to='/allYouth'>
                      List All Youth
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to='/update'>
                      Monthly Review Tool
                      {/*Build a count label later
                    <Label color='teal' circular>
                      3
                    </Label>
                    */}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            <Dropdown item text={props.user?.displayName || props.user?.email}>
              <Dropdown.Menu>
                <Dropdown.Item as={NavLink} to='/profile' onClick={props.hideDashboardMenu}>
                  Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={props.signOut}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/*
            <Dropdown item text='More'>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to='/privacy'>Privacy Statement</Dropdown.Item>
                <Dropdown.Item as={Link} to='/eula'>EULA</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
             */}
          </Fragment>
        )}
        {props.user ? (
          <SignedInMenu signOut={props.signOut} />
        ) : (
          <SignedOutMenu signIn={props.signIn} />
        )}
      </Menu>
      <Container>
        {user &&
          user.roles?.Provider === false &&
          user.roles?.EBAdmin === false && <AccountPending />}
      </Container>
      <Container>
        {EBAppEnv === 'testing' && (
          <Message
            info
            icon='flask'
            header='TESTING SITE'
            content='You are on the testing site of the EBApp. Report any issues, bugs, or errors to Joel.'
          />
        )}
      </Container>
    </Fragment>
  );
};

export default Header;
