import React from 'react'
import { Message } from 'semantic-ui-react';

const AccountPending = () => {
    return (
        <Message info
            icon="info circle"
            header="Account Pending Approval"
            content="Your account has been registered but is awaiting approval by EBA."    
        >
        </Message>
    )
}

export default AccountPending;
