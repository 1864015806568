import React, { Fragment } from "react";
import SignedOutMenu from "./SignedOutMenu";
import WelcomeUser from "./WelcomeUser";
import { Image } from "semantic-ui-react";

const HomePage = (props) => {
  //const date = new Date();
  //console.log(date.toLocaleDateString());
  //
  return (
    <div>
      {!props.user && (
        <Fragment>
          <Image src="cropped-EBA_logo-300.png"/>
          <h1>Welcome</h1>
          <p>
            This is the home of the EBA web portal. Please login to get started.
          </p>
          <h2>Login Instructions</h2>
          <p>EBA <b>cannot</b> view or change your password within this app. If you need a password reset you will need to do so through Microsoft or Google.</p>
          <p>
            Please use your individual work email address which is known to EBA. If your agency uses Microsoft 365, please click &quot;Login with Microsoft&quot;, if your agency uses GSuite please click &quot;Login with Google&quot;. If you are unsure which email provider your agency uses, please contact your agency IT administrator.
          </p>
          <p>
            If your agency uses another email service it is recommended that you <a href="https://accounts.google.com/signup">create a Google account</a> using your same work email address in order to use this app. Select the option &quot;Use my current email address instead&quot;.
          </p>
          <p>Please note that shared email accounts are prohibited.</p>
          <SignedOutMenu signIn={props.signIn} />
        </Fragment>
      )}
      {props.user?.roles.Provider && <WelcomeUser />}
    </div>
  );
};

export default HomePage;
