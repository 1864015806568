import React, { Component, Fragment } from "react";

import { Item, Form, Button, Header } from "semantic-ui-react";
import YouthItem from "./YouthItem";
import ProviderSelect from "../EBAdmin/ProviderSelect";

import { db } from "../../config/fireInit";

class AllYouth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allYouth: [],
      lastNameSearch: "",
      queryLimit: 250,
    };

    this.unsubscribe = () => {};
  }

  componentDidMount() {
    //const db = firebase.firestore();
    const { user } = this.props;
    // if (user.roles.EBAdmin){//query all if EBAdmin
    //   this.unsubscribe = db.collection("youth").onSnapshot(querySnapshot => {
    //     const youthData = querySnapshot.docs.map(doc => doc.data());
    //     this.setState({ allYouth: youthData });
    //   });
    // } else  {//if (user.roles.Provider) {//query only own if Provider
    this.setState({ providerID: user.providerID });
    //console.log("user.providerID", user.providerID);
    if (user.roles.EBAdmin) {
      db.collection("providers")
      .orderBy("providerName")
      .onSnapshot((querySnapshot) => {
        const providerData = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ providers: providerData });
        //console.log("providerData", providerData);
      });
    } else {
      //not an admin
      this.setState({
        providers: [
          {
            providerName: user.providerName,
            uid: user.providerID,
          },
        ],
      });
    }

    //
    this.unsubscribe = db
      .collection("youth")
      .where("currentProviderName", "==", user.providerName)
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        const youthData = querySnapshot.docs.map((doc) => doc.data());
        this.setState({ allYouth: youthData });
      });
    //}
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (user !== prevProps.user) {
      this.unsubscribe = db
        .collection("youth")
        .where("currentProviderName", "==", user.providerName)
        .orderBy("createdAt", "desc")
        .onSnapshot((querySnapshot) => {
          const youthData = querySnapshot.docs.map((doc) => doc.data());
          this.setState({ allYouth: youthData });
        });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleReset = () => {
    const { user } = this.props;
    const { queryLimit } = this.state;
    this.unsubscribe();
    this.setState({
      emailSearch: "",
      lastNameSearch: "",
      disableNextBtn: false,
      disablePrevBtn: true,
      column: null,
      direction: null,
    });
    this.unsubscribe = db
      .collection("youth")
      .where("currentProviderName", "==", user.providerName)
      .orderBy("createdAt", "desc")
      .limit(queryLimit)
      .onSnapshot((querySnapshot) => {
        const youthData = querySnapshot.docs.map((doc) => doc.data());
        let lastItem = querySnapshot.docs[querySnapshot.docs.length - 1];
        this.setState({
          allYouth: youthData,
          lastItem,
          disableNextBtn: youthData.length < 5 ? true : false,
        });
      });
  };

  handleLastNameSearch = (e) => {
    const { user } = this.props;
    const { queryLimit } = this.state;
    this.unsubscribe();
    this.setState({
      activePage: 1,
      disablePrevBtn: true,
      lastNameSearch: e.target.value,
    });
    this.unsubscribe = db
      .collection("youth")
      .where("currentProviderName", "==", user.providerName)
      .where("lastName", "==", e.target.value)
      .limit(queryLimit)
      .onSnapshot((querySnapshot) => {
        const youthData = querySnapshot.docs.map((doc) => doc.data());
        this.setState({
          allYouth: youthData,
          disabledNextBtn: youthData.length < 5 ? true : false,
        });
      });
  };

  render() {
    const { user } = this.props;
    const { lastNameSearch, providers } = this.state;
    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          {user.roles.EBAdmin && (
            <Fragment>
            <Header as="h2" dividing>
            Provider:
          </Header>
            <ProviderSelect
            providerName={user.providerName}
            providerID={user.providerID}
            uid={user.uid}
            providers={providers}
            //loadStaff={this.loadStaff}
          />
          </Fragment>
          )}
          <h1 className="center">All Youth for {user?.providerName}</h1>
          <Form.Input
            label="Search by Last Name:"
            value={lastNameSearch}
            name="lastNameSearch"
            type="text"
            autoComplete="off"
            onChange={(e) => this.handleLastNameSearch(e)}
          />
          <Button basic onClick={this.handleReset}>
            Reset
          </Button>
          <p>
            <b>Note: </b>search inputs must be exact to show results.
          </p>
        </Form>
        <Item.Group divided>
          {this.state.allYouth.map((youth) => (
            <YouthItem key={youth.uid} youth={youth} />
          ))}
        </Item.Group>
      </Fragment>
    );
  }
}

export default AllYouth;
