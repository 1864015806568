import React from 'react'
import { Button, Menu, Divider } from 'semantic-ui-react';

const SignedOutMenu = ({signIn}) => {
    return (
        <Menu.Menu position="right">
            <Menu.Item>
                <Button onClick={() => signIn("Microsoft")}>
                    Login with Microsoft
                </Button>
            </Menu.Item>
            <Divider horizontal/>
            <Menu.Item>
                <Button onClick={() => signIn("Google")}>
                    Login with Google
                </Button>
            </Menu.Item>
        </Menu.Menu>
    )
}

export default SignedOutMenu;
