import { get } from 'lodash'
import { saveAs } from 'file-saver'
import { db } from '../../config/fireInit'
import { updateMonthYYYYMM, updateMonthLabel } from '../functions'
import { ServiceInterface } from '../../models/Service'
import { Field, makeFieldsMap } from './reportFieldsConfig'

const FAKE_PROVIDER_NAME = "Bobert, Incorporated"

// TODO: count staff in a different method

export class ServiceReport {
  private month: string = updateMonthYYYYMM()
  private fields: Field[] = makeFieldsMap(this.month)

  constructor() {}

  // TODO: move db operations to a separate class
  private getMonthServices = async () => {
    return await db
      .collection('services')
      .where(`months.${this.month}.exists`, '==', true)
      .get()
  }

  private getHeaderRow() {
    return (
      this.fields
        .map((field) => field.label)
        .join(',') + '\n'
    )
  }

  private makeRows = async () => {
    const services = await this.getMonthServices()
    let rows = ''
    services.docs.forEach((doc) => {
      const service = doc.data() as ServiceInterface
      if (service.provider.name === FAKE_PROVIDER_NAME) return
      rows += this.getServiceFields(service) + '\n'
    })
    return rows
  }

  private getServiceFields = (service: ServiceInterface) => {
    return this.fields
      .map((field) => {
        const fieldValue = get(service, field.path)
        if (field.compute) {
          return field.compute(service)
        }
        if (field.serviceValue) {
          return field.serviceValue(service.shortName, fieldValue)
        }
        if (field.format) {
          return field.format(fieldValue)
        }
        return fieldValue
      })
      .join(',')
  }

  public csv = async () => {
    const csvFileName = `${updateMonthLabel()}.csv`
    const headerRow = this.getHeaderRow()
    const rows = await this.makeRows()
    const csvString = headerRow + rows;
    const blob = new Blob([csvString], { type: 'text/plain;charset=utf-8' })
    return saveAs(blob, csvFileName)
  }
}
