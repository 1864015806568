import React, {useState} from 'react';
import { Icon, Label, Message, Table, Transition } from 'semantic-ui-react';
import UpdateRow from './UpdateRow';
import Service from '../../models/Service';

const UpdateTable = ({
  monthLabel,
  services,
  YYYYMM,
  staffList,
  user,
  sortedColumn,
  sortedDirection,
  handleColumnClick,
}) => {
  const [showReminders, setshowReminders] = useState(false)

  return (
    <>
    {showReminders && (
      <Message icon warning>
      <Icon name="info circle" />
      <Message.Content>
        <Message.Header>Reminders:</Message.Header>
        A row with any input error will be in{" "}
        <Label color="red">RED</Label>
        <hr/>
        <p>A staff person must be selected.</p>
        <p>Units must be filled in and a postive whole number.</p>
        <p>
          If an end date is provided, a discharge type must also be given.
        </p>
        <p>
            Admin discharges must include a note explaining the reason.
        </p>
        <p>
          Only enter an end-date if it occurred in {monthLabel}.
          Current calendar month discharges should be submitted during the
          next reporting cycle.
        </p>
        <p>
          ECBI Youth Problem Raw Scores must be whole numbers between 0
          and 36.
        </p>
        <p>
          ECBI Youth Intensity Raw Scores must be whole numbers between 36
          and 252.
        </p>
        <p>
            If you are not using the Chrome browser, please be sure to enter the
            date in the text box with a four digit year, two digit month
            (including leading zero if needed), and two digit day (including
            leading zero if needed): YYYY-MM-DD. Chrome provides a date picker
            tool that formats the date for you.
          </p>
      </Message.Content>
    </Message>
    )}
    <Table sortable celled className='stickyHeader'>
      <Table.Header>
        <Table.Row verticalAlign='top'>
          <Table.HeaderCell
            width='two'
            sorted={sortedColumn === 'youth.lastName' ? sortedDirection : null}
            onClick={() => handleColumnClick('youth.lastName')}
          >
            Youth Name
          </Table.HeaderCell>
          <Table.HeaderCell
            width='two'
            sorted={sortedColumn === 'shortName' ? sortedDirection : null}
            onClick={() => handleColumnClick('shortName')}
          >
            Service
          </Table.HeaderCell>
          <Table.HeaderCell
            width='three'
            sorted={sortedColumn === 'staff.name' ? sortedDirection : null}
            onClick={() => handleColumnClick('staff.name')}
          >
            Staff
          </Table.HeaderCell>
          <Table.HeaderCell
            width='two'
            //sorting won't be possible with this field since a new composite would be required every month
            //sorted={sortedColumn === `months.${YYYYMM}.units` ? sortedDirection : null}
            //onClick={() => handleColumnClick(`months.${YYYYMM}.units`)}
          >
            Sessions
            <br />
            <i>in {monthLabel}</i>
          </Table.HeaderCell>
          <Table.HeaderCell width='two'>
            Model
            <br />
            Specific
          </Table.HeaderCell>
          <Table.HeaderCell width='three'>
            Discharge
            <br />
            <i>if applicable in {monthLabel}</i>
          </Table.HeaderCell>
          <Table.HeaderCell width='one'>
            Submit
            <br />
            or Edit
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Transition.Group as={Table.Body} duration={200} animation='fade'>
        {services.map((service) => (
          //when filtering on updates only; hide upated services
          //(!showAllServices && !service.months[YYYYMM].log.updated)
          <Table.Row key={service.uid} verticalAlign='top'>
            <UpdateRow
              service={new Service(service)}
              YYYYMM={YYYYMM}
              staffList={staffList}
              user={user}
              setshowReminders={setshowReminders}
            />
          </Table.Row>
        ))}
      </Transition.Group>
    </Table>
    </>
  );
};

export default UpdateTable;
