import React from 'react'
import { Form, Label } from 'semantic-ui-react'
import { MAX_DECA_SCORE, anyDecaError } from '../../util/validators'

const scaleCategoty = (tScore) => {
  const tScoreNum = Number(tScore)
  if (tScore === '') {
    return 'Not Rated'
  } else if (tScoreNum >= 1 && tScoreNum <= 40) {
    return 'Area of Need'
  } else if (tScoreNum <= 59) {
    return 'Typical'
  } else if (tScoreNum <= 100) {
    return 'Strength'
  }
  return 'Not Rated'
}

export const DecaInput = ({
  value,
  measure,
  label,
  shortLabel,
  timePoint,
  service,
  model,
  setService,
  serviceError,
}) => {
  return (
    <>
      <Form.Input
        fluid
        label={label}
        type='number'
        name='units'
        min='0'
        step='1'
        max={MAX_DECA_SCORE}
        value={value}
        error={serviceError}
        onChange={(e) => {
          console.log('measure', measure)
          setService({
            ...service,
            modelStatic: {
              ...service.modelStatic,
              [model]: e.target.value,
            },
          })
        }}
      />
      <Label color='grey' basic>
        {shortLabel} Category = {scaleCategoty(value)}
      </Label>
    </>
  )
}
