import React from 'react'
import { Menu, Button } from 'semantic-ui-react'

const SignedInMenu = ({signOut}) => {
    return (
        <Menu.Menu position="right">
            <Menu.Item>
                <Button onClick={signOut} basic>
                    Logout
                </Button>
            </Menu.Item>
        </Menu.Menu>
    )
}

export default SignedInMenu;
