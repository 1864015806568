export interface ServiceInterface {
  uid: string
  shortName: string
  project: string
  createdAt: Date
  createdBy: string
  provider: {
    uid?: string
    name?: string
  }
  staff: {
    label: string
    name: string
    uid: string
  }
  dates: {
    start: string
    end: string
    los: number
  }
  unitType: string
  totalUnits: number
  referralSource: string
  referralSourceCustom: string
  dischargeType: string
  dischargeNote: string
  modelStatic: {
    ecbiProbStart: string
    ecbiIntensityStart: string
    ecbiProbEnd: string
    ecbiIntensityEnd: string
    deca: {
      initial: {
        type: string
        ar: string
        in: string
        sr: string
        bc: string
        tpf: string
      }
      midpoint: {
        type: string
        ar: string
        in: string
        sr: string
        bc: string
        tpf: string
      }
      discharge: {
        type: string
        ar: string
        in: string
        sr: string
        bc: string
        tpf: string
      }
    }
  }
  monthsList: string[]
  monthsNeedUpdated: string[]
  months: {
    [key: string]: {
      exists: boolean
      units: string
      model: {
        cppFidelity: string
        cppSupervision: string
        ecbiCurrentProb: string
        ecbiCurrentIntensity: string
        decaTime: string
        decaType: string
        decaAR: string
        decaIN: string
        decaSR: string
        decaBC: string
        decaTPF: string
      }
      log: {
        updated: boolean
        lastUpdated: string
        lastUpdatedBy: string
      }
    }
  }
  youth: {
    uid: string
    firstName: string
    lastName: string
    DOB: string
    race: string
    bioSex: string
    gender: string
    primaryStateID?: string
    stateIDs?: {
      dc?: {
        eCura?: string
        iCams?: string
      }
    }
    createdAt: Date
    createdBy: string
    lastUpdated: Date
    lastUpdatedBy: string
    currentProviderName: string
    currentProviderID: string
  }
  lastUpdated: Date
}

const defaultModelStatic = () => {
  return {
    ecbiProbStart: '',
    ecbiIntensityStart: '',
    ecbiProbEnd: '',
    ecbiIntensityEnd: '',
    deca: {
      initial: {
        type: '',
        ar: '',
        in: '',
        sr: '',
        bc: '',
        tpf: '',
      },
      midpoint: {
        type: '',
        ar: '',
        in: '',
        sr: '',
        bc: '',
        tpf: '',
      },
      discharge: {
        type: '',
        ar: '',
        in: '',
        sr: '',
        bc: '',
        tpf: '',
      },
    },
  }
}

const defaultYouth = () => {
  return {
    uid: '',
    firstName: '',
    lastName: '',
    DOB: '',
    race: '',
    bioSex: '',
    gender: '',
    primaryStateID: '',
    stateIDs: {
      dc: {
        eCura: '',
        iCams: '',
      },
    },
    createdAt: new Date(),
    createdBy: '',
    lastUpdated: new Date(),
    lastUpdatedBy: '',
    currentProviderName: '',
    currentProviderID: '',
  }
}

export class Service implements ServiceInterface {
  uid: string = ''
  shortName: string = ''
  project: string = 'Families First'
  createdAt: Date = new Date()
  createdBy: string = ''
  provider: {
    uid?: string
    name?: string
  } = {
    uid: '',
    name: '',
  }
  staff: {
    label: string
    name: string
    uid: string
  } = {
    label: '',
    name: '',
    uid: '',
  }
  dates: {
    start: string
    end: string
    los: number
  } = {
    start: new Date().toISOString().split('T')[0],
    end: '',
    los: 0,
  }
  unitType: string = 'Sessions'
  totalUnits: number = 0
  referralSource: string = ''
  referralSourceCustom: string = ''
  dischargeType: string = ''
  dischargeNote: string = ''
  modelStatic: {
    ecbiProbStart: string
    ecbiIntensityStart: string
    ecbiProbEnd: string
    ecbiIntensityEnd: string
    deca: {
      initial: {
        type: string
        ar: string
        in: string
        sr: string
        bc: string
        tpf: string
      }
      midpoint: {
        type: string
        ar: string
        in: string
        sr: string
        bc: string
        tpf: string
      }
      discharge: {
        type: string
        ar: string
        in: string
        sr: string
        bc: string
        tpf: string
      }
    }
  } = defaultModelStatic()
  monthsList: string[] = []
  monthsNeedUpdated: string[] = []
  months: {
    [key: string]: {
      exists: boolean
      units: string
      model: {
        cppFidelity: string
        cppSupervision: string
        ecbiCurrentProb: string
        ecbiCurrentIntensity: string
        decaTime: string
        decaType: string
        decaAR: string
        decaIN: string
        decaSR: string
        decaBC: string
        decaTPF: string
      }
      log: {
        updated: boolean
        lastUpdated: string
        lastUpdatedBy: string
      }
    }
  } = {}
  youth: {
    uid: string
    firstName: string
    lastName: string
    DOB: string
    race: string
    bioSex: string
    gender: string
    primaryStateID?: string
    stateIDs?: {
      dc?: {
        eCura?: string
        iCams?: string
      }
    }
    createdAt: Date
    createdBy: string
    lastUpdated: Date
    lastUpdatedBy: string
    currentProviderName: string
    currentProviderID: string
  } = defaultYouth()
  lastUpdated: Date = new Date()

  constructor()
  constructor(service?: ServiceInterface) {
    if (service) {
      Object.assign(this, service);
      this.lastUpdated = new Date()
    }
  }
}

export default Service
