import React from 'react';
import { Form, Label } from 'semantic-ui-react';

const yesnoSelect = [
  { key: '1', text: 'Yes', value: 'Yes' },
  { key: '2', text: 'No', value: 'No' },
];

const CPP = ({ service, setService, YYYYMM }) => {
  //if month exists, use it, otherwise set to blank
  const cppSupervision = service.months[YYYYMM]?.model?.cppSupervision ? service.months[YYYYMM].model.cppSupervision : '';
  const cppFidelity = service.months[YYYYMM]?.model?.cppFidelity ? service.months[YYYYMM].model.cppFidelity : '';

  return (
    <>
      <Label basic color='blue'>
        Supervision & Fidelity
      </Label>
      <Form.Select
        label='Supervision Provided'
        placeholder='Select Yes/No...'
        name='cppSupervison'
        options={yesnoSelect}
        value={cppSupervision}
        onChange={(e, data) => {
          setService({
            ...service,
            months: {
              ...service.months,
              [YYYYMM]: {
                ...service.months[YYYYMM],
                model: {
                  ...service.months[YYYYMM].model,
                  cppSupervision: data.value,
                },
              },
            },
          })
        }}
      />
      <Form.Select
        label='Fidelity Tools Completed'
        placeholder='Select Yes/No...'
        name='cppFidelity'
        options={yesnoSelect}
        value={cppFidelity}
        onChange={(e, data) =>
          setService({
            ...service,
            months: {
              ...service.months,
              [YYYYMM]: {
                ...service.months[YYYYMM],
                model: {
                  ...service.months[YYYYMM].model,
                  cppFidelity: data.value,
                },
              },
            },
          })
        }
      />
    </>
  );
};

export default CPP;
